import { Space, Table, Input, Typography, message, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DeviceWidth from "context/MobileResizeContext";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./builders.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import axios from "config/axios";
import { navigate } from "@reach/router";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";
const { Text } = Typography;

export function AllBuilders(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [builders, setBuilders] = useState<BuilderObject[]>([]);

  const [loading, setLoading] = useState<boolean>();
  // const [searchQuery, setSearchQuery] = useState<string>("");
  // const [isSearching, setIsSearching] = useState<boolean>(false);
  // const [searchResult, setSearchResult] = useState<BuilderObject[]>([]);

  const { handleSearch, isSearching, searchQuery } = useSearch();
  const [totalPages, setTotalPages] = useState<number>(0);

  const getAllBuilders = useCallback((page: number = 0, size: number = 10) => {
    setLoading(true);
    axios
      .get(`builder/getallbuilders?page=${page}&size=${size}`)
      .then(({ data }) => {
        setTotalPages(data.data.totalPages);
        setBuilders(
          data.data.allBuilders
            .filter(function (builder: any) {
              return builder.builder[0] !== undefined;
            })
            .map((builder: any) => {
              let tableObject = builder.builder[0];
              const user = builder.user[0] as User;
              tableObject.phoneNumber = user?.phoneNumber;
              tableObject.admin = `${user?.firstName} ${user?.lastName}`;
              return tableObject;
            })
        );
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    getAllBuilders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let result = builders.filter((builder) => {
    return (
      builder.businessName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      builder.admin.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      builder.adminEmail.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      builder.businessAddress.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      builder.phoneNumber.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      builder.status.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const columns = [
    {
      title: "BUSINESS NAME",
      dataIndex: "businessName",
      key: "businessName",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "ADMIN",
      dataIndex: "admin",
      key: "admin",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "adminEmail",
      key: "adminEmail",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "ADDRESS",
      dataIndex: "businessAddress",
      key: "businessAddress",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text: OrganizationStatus) => (
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          {text === "pending_confirmation" && (
            <Text style={{ color: "#F2994A" }}>Not confirmed</Text>
          )}
          {text === "pending_approval" && (
            <Text style={{ color: "#F2994A" }}>Pending</Text>
          )}
          {text === "suspended" && (
            <Text style={{ color: "#F2994A" }}>Suspended</Text>
          )}
          {text === "approved" && (
            <Text style={{ color: "#00962C" }}>Approved</Text>
          )}
          {/* <Button style={{ transform: "rotateZ(90deg)" }} type="text">
            <MoreOutlined />
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "..", name: "New Requests" },
          { to: "", name: "All Builders" },
          { to: "../active", name: "Active Builders" },
          { to: "../credit", name: "Builder Credit" },
        ]}
      />
      <div className={classNames([styles.topbar, "c-end"])}>
        <Input
          prefix={<SearchOutlined />}
          size="middle"
          onChange={(e) => handleSearch(e)}
          allowClear
          style={{ width: isMobile ? "100%" : "250px", paddingRight: "1rem" }}
        />
      </div>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table
          rowClassName={styles["table-row"]}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`details/${record.organizationId}`);
              },
            };
          }}
          columns={columns}
          pagination={{
            pageSize: 10,
            total: totalPages * 10,
            onChange: (page) => getAllBuilders(page - 1),
          }}
          dataSource={isSearching ? result : builders}
          className={styles.table}
          loading={loading}
          size="middle"
          style={{
            textTransform: "capitalize",
            fontSize: "0.2rem",
            minWidth: "600px",
          }}
        />
      </div>
    </div>
  );
}
