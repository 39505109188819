import { Space, Table, Input, Typography, message, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DeviceWidth from "context/MobileResizeContext";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./transporters.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import axios from "config/axios";
import { navigate } from "@reach/router";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";
const { Text } = Typography;

export function ActiveTransporters(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [loading, setLoading] = useState<boolean>();
  const [transporters, setTransporters] = useState<BuilderObject[]>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();
  const [totalPages, setTotalPages] = useState<number>(0);

  let searchResult = transporters.filter((transporter) => {
    return (
      transporter.businessName
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transporter.admin.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transporter.adminEmail.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      transporter.businessAddress
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transporter.phoneNumber.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      transporter.status.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const getActiveDsp = useCallback((page: number = 0, size: number = 10) => {
    setLoading(true);
    axios
      .get(`logistics/getactivepartners?page=${page}&size=${size}`)
      .then(({ data }) => {
        setTotalPages(data.data.totalPages);
        setTransporters(
          data.data.allActivePartners.map((logistics: any) => {
            const tableObject = logistics.logisticsPartner[0];
            const user = logistics.user[0] as User;
            tableObject.phoneNumber = user.phoneNumber;
            tableObject.admin = `${user.firstName} ${user.lastName}`;
            return tableObject;
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getActiveDsp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: "BUSINESS NAME",
      dataIndex: "businessName",
      key: "businessName",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "ADMIN",
      dataIndex: "admin",
      key: "admin",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "adminEmail",
      key: "adminEmail",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "ADDRESS",
      dataIndex: "businessAddress",
      key: "businessAddress",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text: OrganizationStatus) => (
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          {text === "pending_confirmation" && (
            <Text style={{ color: "#F2994A" }}>Not confirmed</Text>
          )}
          {text === "pending_approval" && (
            <Text style={{ color: "#F2994A" }}>Pending</Text>
          )}
          {text === "suspended" && (
            <Text style={{ color: "#F2994A" }}>Suspended</Text>
          )}
          {text === "approved" && (
            <Text style={{ color: "#00962C" }}>Approved</Text>
          )}
          {/* <Button style={{ transform: "rotateZ(90deg)" }} type="text">
            <MoreOutlined />
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "..", name: "New Requests" },
          { to: "../all", name: "All DSPs" },
          { to: "", name: "Active DSPs" },
          { to: "../sales", name: "Sales" },
        ]}
      />
      <div className={classNames([styles.topbar, "c-end"])}>
        <Input
          prefix={<SearchOutlined />}
          size="middle"
          onChange={(e) => handleSearch(e)}
          allowClear
          style={{ width: isMobile ? "100%" : "250px", paddingRight: "1rem" }}
        />
      </div>
      <div>
        <div className={styles.table} style={{ overflowX: "auto" }}>
          {isSearching ? (
            <Table
              onRow={(record) => {
                return {
                  onClick: () => {
                    navigate(`details/${record.organizationId}`);
                  },
                };
              }}
              columns={columns}
              dataSource={searchResult}
              className={styles.table}
              loading={loading}
              rowClassName={styles["table-row"]}
              size="small"
              style={{
                fontSize: "0.2rem",
                minWidth: "600px",
                textTransform: "capitalize",
              }}
            />
          ) : (
            <Table
              onRow={(record) => {
                return {
                  onClick: () => {
                    navigate(`details/${record.organizationId}`);
                  },
                };
              }}
              columns={columns}
              dataSource={transporters}
              className={styles.table}
              loading={loading}
              rowClassName={styles["table-row"]}
              pagination={{
                pageSize: 10,
                total: totalPages * 10,
                onChange: (page) => getActiveDsp(page - 1),
              }}
              size="middle"
              style={{
                fontSize: "0.2rem",
                minWidth: "600px",
                textTransform: "capitalize",
              }}
            />
          )}
        </div>

        {/* {isMobile && (
          <Table
            columns={columns}
            dataSource={transporters}
            className={styles.table}
            size="small"
            style={{ fontSize: "0.2rem", overflowX: "auto" }}
          />
        )} */}
      </div>
    </div>
  );
}
