import { createContext, ReactNode, useEffect, useState } from "react";

const DeviceWidth = createContext<boolean | undefined>(undefined);

export const MobileResizeContext = ({ children }: { children: ReactNode }) => {
  const [isMobile, setIsMobile] = useState<boolean>();
  const checkDeviceWidth = () => {
    if (window.innerWidth < 600) setIsMobile(true);
    else setIsMobile(false);
  };
  useEffect(() => {
    checkDeviceWidth();
    window.addEventListener("resize", checkDeviceWidth);
    return () => {
      window.removeEventListener("resize", checkDeviceWidth);
    };
  }, []);

  return (
    <DeviceWidth.Provider value={isMobile}>{children}</DeviceWidth.Provider>
  );
};

export default DeviceWidth;
