import React, { useCallback, useEffect, useState, Suspense } from "react";
import DashboardShared from "components/DashboardShared/DashboardShared";
import styles from "./buy.module.css";
import { Badge, Button, Modal } from "antd";
import { useContext } from "react";
import DeviceWidth from "context/MobileResizeContext";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Router, navigate } from "@reach/router";
import Cart from "components/Cart";
import PageSpinner from "components/PageSpinner";
import axiosConfig from "config/axios";
import NotFound from "pages/404";
import { useSelector } from "react-redux";
import { RootState } from "store";
// import DeliveryTest from "./delivery-test";
// import Checkout from "./checkout";
const Categories = React.lazy(() => import("./categories"));
const Materials = React.lazy(() => import("./material"));
const Checkout = React.lazy(() => import("./delivery"));
const Billing = React.lazy(() => import("./checkout"));

export default function Buy(props: ReachPathProps) {
  const [cartModalOpen, setCartModalOpen] = useState(false);
  const isMobile = useContext(DeviceWidth);
  const { builder } = useSelector((state: RootState) => state.builder);
  // const match = useMatch("*");
  const [exceedQty, setExceedQty] = useState<number>();
  const [, setOrderInfo] = useState<OrderInfo>({
    builderId: "",
    userId: "",
    variant: "",
  });

  // const BillingPage = (props: ReachPathProps) => (
  //   <>
  //     <Billing loading={isLoading} cartItem={pending} />
  //   </>
  // );
  const _builder = JSON.parse(localStorage.getItem("builder")!);

  const builderId = builder?.id ?? _builder.id;

  const [cart, setCart] = useState<[] | any>([]);
  const [isUpdatingCart, setIsUpdatingCart] = useState<boolean>(false);

  const [isLoadingCart, setIsLoadingCart] = useState<boolean>(false);
  const getCart = useCallback(async () => {
    setIsLoadingCart(true);
    await axiosConfig
      .get(`cart/${builderId}/order`)
      .then(({ data }) => {
        setCart(data);
        setIsLoadingCart(false);
      })
      .catch((error) => {
        setIsLoadingCart(false);
      });
  }, [builderId]);

  useEffect(() => {
    getCart();
    // getPendingOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideCart =
    window.location.pathname.includes("checkout") ||
    window.location.pathname.includes("billing");

  return (
    <DashboardShared
      // cart={cart?.cart}
      // callback={() => setCartModalOpen(true)}
      title="Buy Building Materials"
      right={
        <Button
          size="middle"
          onClick={() => {
            setCartModalOpen(true);
          }}
          icon={<ShoppingCartOutlined />}
          className={styles["cart-button"]}
        >
          {" "}
          Cart {"  "} <Badge count={`${cart?.cart?.length ?? 0} items`} />
        </Button>
      }
    >
      <div className={styles.main}>
        <Suspense fallback={<PageSpinner />}>
          <Router>
            <Categories path="/" />
            {/* <PlayAround path="play" /> */}
            <Materials
              path="variant/:id"
              callback={() => {
                navigate(-1);
                setCartModalOpen(true);
                getCart();
              }}
            />
            {/* <Delivery
              cart={cart?.cart}
              callback={() => navigate("/builders/buy/checkout")}
              path="delivery"
            /> */}
            <Checkout
              cart={cart?.cart}
              callback={() => {
                navigate(`./billing`);
              }}
              setExceedQty={setExceedQty}
              path="checkout"
              setOrderInfo={setOrderInfo}
              builder={builder!}
            />
            <Billing
              callback={() => {
                navigate(`/`);
              }}
              path="billing"
              builderId={builderId!}
            />
            <NotFound path="*" />
          </Router>
        </Suspense>

        <div className={styles["cart-section"]}>
          {!isMobile && !hideCart && (
            <Cart
              title="Order summary"
              isLoadingCart={isLoadingCart}
              cart={cart?.cart}
              subTotal={cart?.totalPrice}
              getCart={getCart}
              exceedQty={exceedQty}
              setIsUpdatingCart={setIsUpdatingCart}
              isUpdatingCart={isUpdatingCart}
              footer={
                <Button
                  type="primary"
                  block
                  size="large"
                  disabled={
                    window.location.pathname.includes("delivery") ||
                    isUpdatingCart
                  }
                  onClick={() => {
                    setCartModalOpen(false);
                    navigate("/admin/builders/buy/checkout");
                  }}
                >
                  Checkout
                </Button>
              }
            />
          )}
        </div>
      </div>

      {isMobile && (
        <Modal
          title=""
          open={cartModalOpen}
          footer={null}
          style={{
            top: 20,
          }}
          onCancel={() => {
            setCartModalOpen(false);
          }}
          bodyStyle={{
            padding: "10px",
            backgroundColor: "transparent",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "35px",
            }}
          >
            {isMobile && (
              <Cart
                getCart={getCart}
                isLoadingCart={isLoadingCart}
                title="Order summary"
                cart={cart?.cart}
                subTotal={cart?.totalPrice}
                exceedQty={exceedQty}
                setIsUpdatingCart={setIsUpdatingCart}
                isUpdatingCart={isUpdatingCart}
                footer={
                  <Button
                    type="primary"
                    block
                    size="large"
                    disabled={
                      window.location.pathname.includes("delivery") ||
                      isUpdatingCart
                    }
                    onClick={() => {
                      navigate("/admin/builders/buy/checkout");
                      setCartModalOpen(false);
                    }}
                  >
                    Checkout
                  </Button>
                }
              />
            )}
          </div>
        </Modal>
      )}
    </DashboardShared>
  );
}
