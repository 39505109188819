import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type AuthState = {
  builder: null | BuilderObject
}

const initialState: AuthState = {
  builder: null,
}

const slice = createSlice({
  initialState,
  name: "builder",
  reducers: {
    updateBuilder(state, action: PayloadAction<BuilderObject>) {
      state.builder = action.payload
    },
   
  },
})

export default slice.reducer
export const { updateBuilder } = slice.actions
