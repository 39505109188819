import { Table, Input, Tooltip, Switch, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DeviceWidth from "context/MobileResizeContext";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./builders.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import axios from "config/axios";
import { navigate } from "@reach/router";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";
import axiosConfig from "config/axios";

export function AllHomeowners(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [homeowners, setHomeowners] = useState<HomeownerObject[]>([]);

  const [loading, setLoading] = useState<boolean>();
  const [isChanging, setIsChanging] = useState<boolean>(false);
  const [masterToggle, setMasterToggle] = useState<boolean>(false);

  const { handleSearch, isSearching, searchQuery } = useSearch();

  const getAllHomeowners = useCallback(
    (page: number = 0, size: number = 10) => {
      setLoading(true);
      axios
        .get(`homeowner/home-owners`)
        .then(({ data }) => {
          setHomeowners(data.HomeOwners);
          setLoading(false);
        })
        .catch((error) => {
          requestErrorHandler(error);
        })
        .finally(() => setLoading(false));
    },
    []
  );
  useEffect(() => {
    getAllHomeowners();
    getMasterToggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let result = homeowners.filter((homeowner) => {
    return (
      homeowner.firstName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      homeowner.lastName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      homeowner.email.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      homeowner.accountType.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1
    );
  });

  const handleShowPrompt = async (e: any, homeOwnerId: string) => {
    try {
      const { data } = await axiosConfig.put(`homeowner/toggle-feedback`, {
        homeOwnerId,
      });
      getAllHomeowners();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsChanging(false);
    }
  };

  const handleMasterToggle = async () => {
    try {
      const { data } = await axiosConfig.post(`homeowner/master-toggle`, {
        toggleState: !masterToggle,
      });
      setMasterToggle(!masterToggle);
      getAllHomeowners();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsChanging(false);
    }
  };
  const getMasterToggle = async () => {
    try {
      const { data } = await axiosConfig.get(`homeowner/master-state`);
      setMasterToggle(data.state);
      getAllHomeowners();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsChanging(false);
    }
  };

  const columns = [
    {
      title: "Full NAME",
      dataIndex: "firstName",
      key: "firstName",
      width: '30%',
      ellipsis: true,
      render: (_: any, record: HomeownerObject) => (
        <Tooltip title={record.firstName + " " + record.lastName}>
          {record.firstName + " " + record.lastName}
        </Tooltip>
      ),
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
      key: "email",
      width: '30%',
      // ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
      key: "accountType",
      width: '30%',
      render: (text: string,) =><Space>
        <Tooltip title={text}>{text}</Tooltip>
      </Space>
    },
    {
      title: "",
      dataIndex: "feedback",
      key: "feedback",
      width:"5%",
      render: (text: boolean, record: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Tooltip title={"Turn on User Feedback prompt"}>
            {" "}
            <Switch
              size="small"
              loading={isChanging}
              checked={text}
              onChange={(e) => {
                handleShowPrompt(e, record?.id);
              }}
            />{" "}
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "", name: "All Home Owners" },
          { to: "feedback", name: "User Feedbacks" },
        ]}
      />
      <div className={classNames([styles.topbar])}>
        <Input
          prefix={<SearchOutlined />}
          size="middle"
          onChange={(e) => handleSearch(e)}
          allowClear
          style={{ width: isMobile ? "100%" : "250px", paddingRight: "1.5rem" }}
        />
        <div className={styles.feedback}>
          <small>Show User Feedback prompt</small>
          <Switch
            size="default"
            loading={isChanging}
            checked={masterToggle}
            onChange={(e) => handleMasterToggle()}
          />{" "}
        </div>
      </div>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table
          rowClassName={styles["table-row"]}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`homeowners/details/${record.id}`);
              },
            };
          }}
          columns={columns}
          dataSource={isSearching ? result : homeowners}
          className={styles.table}
          loading={loading}
          size="middle"
          style={{
            textTransform: "capitalize",
            fontSize: "0.2rem",
            minWidth: "600px",
          }}
        />
      </div>
    </div>
  );
}
