import {
  Input,
  Button,
  Form,
  Table,
  Dropdown,
  Menu,
  Modal,
  message,
  Tooltip,
} from "antd";
import DashboardShared from "components/DashboardShared/DashboardShared";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "../payments/request.module.css";
import classNames from "classnames";
import { Home, Package, Truck } from "react-feather";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";
import TopTab from "components/TopTab/TopTab";
import { Router } from "@reach/router";
import Admins from "../settings/Admins";

const columns = [
  {
    title: "FIRST NAME",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "LAST NAME",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "EMAIL ADDRESS",
    dataIndex: "email",
    key: "email",
    ellipsis: true,
    render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
  },
  {
    title: "PHONE NUMBER",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "USER ROLE",
    dataIndex: "role",
    key: "role",
    render: () => "Super Admin",
  },
];

function Users(props: ReachPathProps) {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [orgType, setOrgType] = useState("");
  const menu = (
    <Menu
      onClick={(e) => {
        setOrgType(e.key);
      }}
    >
      <Menu.Item key="builder" icon={<Home />}>
        Builder
      </Menu.Item>
      <Menu.Item key="supplier" icon={<Package />}>
        Supplier
      </Menu.Item>
      <Menu.Item key="logistics" icon={<Truck />}>
        DSP
      </Menu.Item>
    </Menu>
  );
  const {
    user: { organizationId },
  } = useAuth() as { user: User };

  const { handleSearch, isSearching, searchQuery } = useSearch();
  let searchResult = users.filter((user) => {
    return (
      user.firstName.toLowerCase().indexOf(searchQuery.toLowerCase()) ||
      user.lastName.toLowerCase().indexOf(searchQuery.toLowerCase())
    );
  });

  const getUsers = useCallback(
    async function getUsers() {
      setLoading(true);
      try {
        const {
          data: {
            data: { organizationUsers },
          },
        } = await axios.get(`organization/${organizationId}/users`);
        setUsers(organizationUsers);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [organizationId]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const [form] = Form.useForm();

  return (
    <div>
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input
            onChange={(e) => handleSearch(e)}
            allowClear
            placeholder="search"
            prefix={<SearchOutlined />}
          />
        </div>
        <Dropdown overlay={menu}>
          <Button>
            Add Organization <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <div className={styles.table} style={{ overflowX: "auto" }}>
        {isSearching ? (
          <Table
            size="small"
            rowClassName={() => "table-row"}
            dataSource={searchResult}
            columns={columns}
            loading={loading}
            style={{ minWidth: "600px", textTransform: "capitalize" }}
          />
        ) : (
          <Table
            size="small"
            rowClassName={() => "table-row"}
            dataSource={users}
            columns={columns}
            loading={loading}
            style={{ minWidth: "600px", textTransform: "capitalize" }}
          />
        )}
      </div>
      <Modal
        title="Add Organization"
        visible={!!orgType}
        onCancel={() => setOrgType("")}
        footer={null}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={async (values) => {
            setIsSubmitting(true);
            await axios
              .post("admin/addorganization", {
                ...values,
                orgType,
              })
              .then(() => {
                message.success("Organization added successfully");
                getUsers();
                setOrgType("");
              })
              .catch((err) => {
                requestErrorHandler(err);
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter organization's email address",
              },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            label="Organization Email Address"
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="businessName"
            rules={[
              {
                required: true,
                message: "Please enter organization's Business Name",
              },
            ]}
            label="Organization Business Name"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please provide a valid phone number",
              },
              {
                pattern: /^(\+?234?)?0?(7|8|9)(0|1)\d{8}$/,
                message: "Please provide a valid phone number.",
              },
            ]}
            label="Organization Phone Number"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please enter organization's First Name",
              },
            ]}
            label="First Name"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please enter organization's Last Name",
              },
            ]}
            label="Last Name"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item>
            <Button loading={isSubmitting} type="primary" htmlType="submit">
              Add Organization
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

const MainView = (props: ReachPathProps) => {
  const { user } = useAuth();
  return (
    <div>
      {user?.email === "webmaster@materialspro.ng" && (
        <TopTab links={[{ to: "", name: "Users" }]} />
      )}
      <Users path="" />
    </div>
  );
};

export default function UserManagement(props: ReachPathProps) {
  return (
    <DashboardShared title="User Management">
      <Router>
        <MainView path="/" />
      </Router>
    </DashboardShared>
  );
}
