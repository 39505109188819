import React, { useState } from "react";
import { Button, DatePicker, Form, Input, message, Modal, Upload } from "antd";
import axiosConfig from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";
import uploadToS3 from "util/uploadToS3";
import { ArrowUpOutlined } from "@ant-design/icons";
import moment from "moment";
import useProject from "hooks/useProject";

type Props = {
  isModalOpen: boolean;
  callback: () => Promise<void>;
  onCancel: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  projectId: string;
};

export default function AddTimeline({
  isModalOpen,
  callback,
  onCancel,
  projectId,
}: Props) {
  const [form] = Form.useForm();

  const { getOneProject } = useProject();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: any) => {
    const payload = {
      projectId: projectId,
      timeLineDetails: [{ ...values, eventGenerator: "system" }],
    };

    try {
      setIsSubmitting(true);
      await axiosConfig.put(`homeowner/update-timeline`, payload);
      message.success("Timeline Updated");
      getOneProject(projectId);
      callback();
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
      //   setShowModal(false);
    }
  };
  return (
    <Modal
      open={isModalOpen}
      centered
      title="Create Event"
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="photo"
          label="Upload Business Logo"
          valuePropName="file"
          getValueFromEvent={(e) => {
            const file = e.fileList[0];
            if (file?.status === "done") {
              return file.response;
            }
          }}
        >
          <Upload
            customRequest={uploadToS3}
            listType="picture"
            multiple={false}
            maxCount={1}
            accept="image/*"
          >
            <Button size="large" icon={<ArrowUpOutlined />}>
              Click to upload
            </Button>
            <br />
            <small>
              Image should not be greater than <b>20MB</b>
            </small>
          </Upload>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Type in an account name" }]}
          label="Description"
          name="description"
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ paddingBottom: 0 }}
          label="Date"
          name="date"
          rules={[
            {
              required: true,
              message: "Please provide a valid delivery date",
            },
          ]}
        >
          <DatePicker
            format="DD MMMM YYYY"
            className="w-100"
            disabledDate={(date) => {
              return date?.valueOf() < moment().add(1, "days").valueOf();
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isSubmitting}
            htmlType="submit"
            block
            type="primary"
            size="large"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
