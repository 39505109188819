import styles from "pages/admin/layout.module.css";
import logo from "assets/logo/logo.png";
import avatar from "assets/icons/avatar.png";
// import userAvatar from "assets/user.png";
import icon from "assets/logo/logo-icon.png";
import { Menu, Button, Tooltip, Divider } from "antd";
import { Link, navigate, useMatch } from "@reach/router";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { XCircle, LogOut } from "react-feather";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { toggle, close } from "store/slices/sidenav";
import useAuth from "hooks/useAuth";
import DeviceWidth from "context/MobileResizeContext";
import useModules from "hooks/useModules";

export default function SideNav({
  collapseNav,
  setCollapseNav,
}: {
  collapseNav: boolean;
  setCollapseNav?: any;
}) {
  const { logOut, user } = useAuth();
  const { dashboardRoute } = useModules();
  const { open } = useSelector((state: RootState) => state.sidenav);
  const dispatch = useDispatch();
  const isMobile = useContext(DeviceWidth);

  const params = useMatch("*") as {
    "*": string;
    uri: string;
    path: `${string}/*`;
  };
  const path = useMemo(() => {
    return params?.["*"].replace(/\/.*/, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.["*"]]);
  const getBasePath = useCallback((path: `${string}/*`) => {
    return path.replace(/\/\*$/, "");
  }, []);

  useEffect(() => {
    window.innerWidth < 767 && setCollapseNav(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <div
      className={`${classNames([
        styles.side__nav,
        { [styles.side__nav__close]: !open },
      ])} ${collapseNav && styles["collapse-nav"]}`}
    >
      <div>
        <div className={styles.cancel}>
          {!collapseNav ? (
            <a href="https://materialpro-home.netlify.app/">
              <img src={logo} alt="logo" className={styles.logo} />
            </a>
          ) : (
            <a href="https://materialpro-home.netlify.app/">
              <img src={icon} alt="logo" width="60" />
            </a>
          )}
          <button
            onClick={() => {
              dispatch(toggle());
            }}
          >
            <XCircle />
          </button>
        </div>
        <div
          onClick={() => navigate("/admin/profile")}
          className={`${collapseNav ? styles["dp-collapsed"] : styles.dp}`}
        >
          <div className={styles.circle} />
          <div data-testid="logo-icon">
            <img src={avatar} width={!collapseNav ? 100 : 60} alt="profile" />
          </div>
          {!collapseNav && (
            <>
              <p data-testid="user-name">{user?.firstName}</p>
              <span>Super Admin</span>
            </>
          )}
        </div>
        <Menu
          className={styles.menu}
          onSelect={() => {
            dispatch(close());
          }}
          selectedKeys={[path]}
          mode="inline"
        >
          {dashboardRoute.map(({ icon, title, path }) => {
            return (
              <Menu.Item
                className={styles.menuItem}
                key={getBasePath(path)}
                icon={icon}
                style={
                  window.location.pathname.includes(getBasePath(path))
                    ? {
                        backgroundColor: "transparent",
                        borderLeft: "3px solid #00962C",
                        opacity: 1,
                      }
                    : {
                        backgroundColor: "transparent",
                        borderLeft: "3px solid transparent",
                        opacity: 0.4,
                      }
                }
              >
                <Tooltip title={collapseNav ? title : ""} placement="topLeft">
                  <Link
                    onClick={() => dispatch(toggle())}
                    to={getBasePath(path)}
                    style={{ opacity: `${collapseNav ? 0 : 1}` }}
                  >
                    {title}
                  </Link>
                </Tooltip>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
      <Divider />
      <div>
        <Tooltip title={collapseNav ? "Log out" : ""} placement="topLeft">
          <Button
            onClick={logOut}
            type="link"
            block
            danger
            icon={<LogOut height={15} />}
            style={{
              marginBottom: "1rem",
              textAlign: "left",
            }}
          >
            <span
              className={collapseNav ? styles.hide : ""}
              style={{ marginLeft: 10 }}
            >
              Log Out
            </span>
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
