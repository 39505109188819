import styles from "./mat-card.module.css";
import { Typography } from "antd";

const { Text, Title } = Typography;
interface itemProps {
  image?: string;
  title: string | JSX.Element;
  subTitle?: string;
  topRight?: string;
  bottomRight?: string | JSX.Element;
}
const MaterialCard = ({
  image,
  title,
  subTitle,
  topRight,
  bottomRight,
}: itemProps) => {
  return (
    <div className={styles.item}>
      {image && (
        <div className={styles["item-img"]}>
          <img src={image} alt={title.toString()} height="50" width="50" />
        </div>
      )}
      <div className={styles["item-text"]}>
        <div className={styles["item-main-text"]}>
          <Title style={{ fontSize: "1rem" }}>{title}</Title>
          <Text
            className={styles["top-right-variant"]}
            style={{ fontSize: ".8rem", color: "#fff" }}
          >
            {topRight}
          </Text>
        </div>
        <div className={styles["item-footer"]}>
          <Text style={{ width: "85%", fontSize: ".8rem" }}>{subTitle}</Text>
          <Text style={{ fontSize: ".8rem" }}>{bottomRight}</Text>
        </div>
      </div>
    </div>
  );
};

export default MaterialCard;
