import { Button, Divider, Empty, Spin } from "antd";
import Comment from "components/Comment";
import AddComment from "components/Comment/AddComment";
import Like from "components/Like";
import { useEffect, useState } from "react";
import styles from "./post.module.css";
import { CommentOutlined } from "@ant-design/icons";
// import { parse } from "rss-to-json";
import Share from "components/Share";
import AxiosBlogConfig from "util/axiosBlogConfig";
import { navigate, useMatch } from "@reach/router";
import colors from "util/colorlib";
import BlogActions from "components/BlogActions";

const BlogDetails = (props: ReachPathProps) => {
  const match = useMatch("/admin/blog-backoffice/:id") as {
    uri: string;
    path: string;
    id: string;
  };

  const [post, setPost] = useState<Post>({
    title: "",
    content: "",
    comments: [],
    createdAt: "",
    id: "",
    image: "",
    likes: 0,
    updatedAt: "",
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loadingPost, setLoadingPost] = useState<boolean>(false);
  const getPost = () => {
    setLoadingPost(true);
    AxiosBlogConfig.get(`/blogpost/onepost/${match.id}`)
      .then((res) => {
        setPost(res.data.blogPost);
      })
      .catch((error) => {})
      .finally(() => setLoadingPost(false));
  };
  useEffect(() => {
    if (!match.id) return;
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.id]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles["top-bar"]}>
          <div>
            <Button
              onClick={() => navigate(-1)}
              style={{ padding: 0 }}
              type="text"
            >
              Back-office{" "}
            </Button>{" "}
            | {post.id.substring(0, 7)}
          </div>
          <BlogActions getBlogPosts={getPost} post={post} />
        </div>
        <header className={styles["details-header-container"]}>
          <div data-aos="fade-in" className={styles["header-content"]}>
            <h1>{loadingPost ? "Loading..." : post.title}</h1>
          </div>
        </header>
        <main className={styles.content}>
          {loadingPost ? (
            <Spin />
          ) : (
            <>
              <article>
                <section className={styles["post-banner"]}>
                  <img width="40%" src={post.image} alt="blog-banner" />
                </section>
                <section className={styles["post-content"]}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.content,
                    }}
                  />
                </section>
              </article>
              <aside className={styles["user-interaction"]}>
                Was this post helpful?
                <Like
                  postId={post.id}
                  likes={post.likes}
                  callback={() => getPost()}
                />
                Share with your friends
                <Share title={post.title} text={post.content} />
              </aside>
              <Divider />
              <section className={styles.comments}>
                <div className="comment-header">
                  <h1>
                    Comments <CommentOutlined /> {post.comments.length ?? ""}
                  </h1>
                </div>
                {post.comments.length < 1 ? (
                  <Empty description="No comments on this Post" />
                ) : (
                  post.comments.map((comment, idx) => (
                    <Comment
                      color={colors[idx]}
                      key={idx}
                      callback={() => getPost()}
                      comment={comment}
                    />
                  ))
                )}
                <Divider />
                <Button
                  id="bottom"
                  onClick={() => setShowModal(true)}
                  type="primary"
                  size="large"
                >
                  ADD COMMENT
                </Button>
                <AddComment
                  showModal={showModal}
                  setShowModal={setShowModal}
                  blogId={post.id}
                  callback={() => {
                    getPost();
                  }}
                />
              </section>
            </>
          )}
        </main>
      </div>
    </>
  );
};

export default BlogDetails;
