import { message } from "antd";

const useError = () => {
  const fetchError = ({error}:any) => {
    if (error.response) {
      const data = error.response.data;
      if (data.error) message.error(data.error);
      if (data.message) message.error(data.message);
      data.errors?.forEach((error: any) => message.error(error.msg));
    } else message.error("An eror occurred");
  }

  return {fetchError}
}

export default useError