import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import CreateBuilderClassModal from "components/CreateBuilderClassModal/CreateBuilderClassModal";
import EditBuilderClassModal from "components/EditBuilderClassModal/EditBuilderClassModal";
import PageSpinner from "components/PageSpinner";
import axios from "config/axios";
import { useEffect, useState } from "react";
// import styles from "../profile/profile.module.css";
import styles from "./settings.module.css";
import useModules from "hooks/useModules";

export default function Builder(props: ReachPathProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [classes, setClasses] = useState<BuilderClass[]>([]);
  const [current, setCurrent] = useState<BuilderClass | null>(null);
  const { allowAction } = useModules();
  async function getBuilderClasses() {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get("buildercredit/getclass");
      setClasses(data.builderClass);
    } catch (err: any) {
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getBuilderClasses();
  }, []);
  return (
    <div className={styles["bank-container"]}>
      {allowAction("settings-write") && (
        <div className="c-nd">
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Create account class
          </Button>
        </div>
      )}
      {isLoading && !classes ? (
        <div style={{ paddingTop: "10rem" }}>
          <PageSpinner />
        </div>
      ) : (
        classes.map((c) => (
          <Classes
            builder={c}
            key={c.id}
            setCurrent={setCurrent}
            getBuilderClasses={getBuilderClasses}
          />
        ))
      )}
      <CreateBuilderClassModal
        isModalOpen={isModalOpen}
        getBuilderClasses={getBuilderClasses}
        onCancel={() => setIsModalOpen(false)}
      />
      <EditBuilderClassModal
        current={current}
        setCurrent={setCurrent}
        getBuilderClasses={getBuilderClasses}
      />
    </div>
  );
}

function Classes(props: {
  builder: BuilderClass;
  getBuilderClasses: () => Promise<void>;
  setCurrent: (builder: BuilderClass) => void;
}) {
  const { allowAction } = useModules();

  async function handleDelete() {
    await axios.post("buildercredit/deleteclass", {
      className: props.builder.className,
    });
    props.getBuilderClasses();
  }
  return (
    <div className={styles.table}>
      <table>
        <tbody>
          <tr>
            <th>Account class name</th>
            <td>{props.builder.className}</td>
          </tr>
          <Divider style={{ width: "100%", margin: "0.5rem" }} />
          <tr>
            <th>Allocated credit amount</th>
            <td>₦{props.builder.creditAmount.toLocaleString()}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <td>
              {allowAction("settings-write") && (
                <div className="c-end">
                  <Button type="link" danger onClick={handleDelete}>
                    Delete
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => props.setCurrent(props.builder)}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
