import DashboardShared from "components/DashboardShared/DashboardShared";
import styles from "./material.module.css";
import { Input, Dropdown, Button, Menu, Collapse } from "antd";
import { DownOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Material from "./material";
import { useContext, useEffect, useState } from "react";
import DeviceWidth from "context/MobileResizeContext";
import { Link, Router } from "@reach/router";
import MaterialDetail from "./material-detail";
import AddMaterial from "pages/admin/materials/Modal/AddMaterial";
import AddCategory from "pages/admin/materials/Modal/AddCategory";
import axiosConfig from "config/axios";
import useMaterials from "hooks/useMaterials";
import useCategories from "hooks/useCategories";
import useSearch from "hooks/useSearch";
import useModules from "hooks/useModules";
// import debounce from "lodash.debounce";

const { Panel } = Collapse;

const MaterialsPage = (props: ReachPathProps) => {
  // const [isSearching, setIsSearching] = useState<boolean>(false);
  const [brands, setBrands] = useState([]);
  // const [searchQuery, setSearchQuery] = useState<string>("");
  // const [searchItems, setSearchItems] = useState<[]>([]);
  const isMobile = useContext(DeviceWidth);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);

  const { materials, isLoading, getMaterials } = useMaterials();
  const { category, getCategories } = useCategories();
  const { handleSearch, isSearching, searchQuery } = useSearch();

  const { allowAction } = useModules();

  // The filter handler
  let searchResult = materials.filter((mat: any) => {
    return (
      mat.brand.brandName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      mat.category?.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const getBrands = () => {
    return axiosConfig
      .get("getbrands")
      .then(
        ({
          data: {
            data: { brands },
          },
        }) => {
          setBrands(brands);
        }
      )
      .catch((error) => {});
  };

  useEffect(() => {
    Promise.all([getCategories(), getMaterials(), getBrands()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterMenu = (
    <Menu style={{ width: "230px", textAlign: "center" }}>
      <Collapse bordered={false}>
        <Panel showArrow={false} header="brands" key="1">
          <Collapse accordion>
            {brands &&
              brands.map((brand: any) => {
                return (
                  <Panel
                    style={{ textAlign: "left" }}
                    header={brand.brandName}
                    key={brand.brandId}
                  >
                    {materials &&
                      materials.map((material: any) => {
                        return (
                          material.brand.brandName === brand.brandName && (
                            <div className={styles["filter-item"]}>
                              <Link to={`material-detail/${material.id}`}>
                                <img
                                  src={material.imageUrl}
                                  alt={material.category?.name}
                                  width="20"
                                  height="20"
                                  style={{
                                    borderRadius: "10px",
                                    marginRight: "1rem",
                                  }}
                                />
                                {material.category?.name}
                              </Link>
                            </div>
                          )
                        );
                      })}
                  </Panel>
                );
              })}
          </Collapse>
        </Panel>
      </Collapse>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <div className={styles["index-topbar"]}>
        {/* <Space className={styles["left-section"]}> */}
        {!isMobile && (
          <Input
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e)}
            allowClear
            placeholder="search"
            style={{ flex: 1, minWidth: "150px" }}
            size="middle"
          />
        )}
        <Dropdown overlay={filterMenu} trigger={["click"]}>
          <Button
            size="middle"
            type="default"
            onClick={(e) => e.preventDefault}
          >
            Filter <DownOutlined />
          </Button>
        </Dropdown>
        {allowAction("Homeowners-write") && (
          <>
            {" "}
            <Button
              onClick={() => setShowModal(true)}
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
            >
              Add new material
            </Button>
            <Button
              onClick={() => setShowCategoryModal(true)}
              type="link"
              size="middle"
              icon={<PlusOutlined />}
            >
              Add new category
            </Button>
          </>
        )}
        {/* </Space> */}
      </div>
      <AddMaterial
        showModal={showModal}
        setShowModal={setShowModal}
        callback={() => getMaterials()}
      />
      <AddCategory
        showModal={showCategoryModal}
        setShowModal={setShowCategoryModal}
        callback={() => getCategories()}
      />
      <div className={`${styles[`main-body`]} ${styles.index}`}>
        {isMobile && (
          <Input
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e)}
            allowClear
            placeholder="search"
            size="small"
            style={{ width: "100%" }}
          />
        )}

        <Material
          isSearching={isSearching}
          category={category}
          searchItems={searchResult}
          materials={materials}
          isLoading={isLoading}
          callback={() => {
            getMaterials();
            getCategories();
          }}
        />
      </div>
    </div>
  );
};

const Materials = () => {
  const isDetailsPage = window.location.pathname.includes("detail");
  return (
    <DashboardShared title={isDetailsPage ? `Material Details` : `Materials`}>
      <Router>
        <MaterialsPage path="/" />
        <MaterialDetail path="material-detail/:id" />
      </Router>
    </DashboardShared>
  );
};
export default Materials;
