import { Timeline } from "antd";
import Modal from "antd/lib/modal/Modal";

interface OrderTrackingProps {
  styles?: any;
  setShowModal: any;
  showModal: boolean;
  addMaterial?: () => void;
  transaction: Transaction;
}
const OrderTracking = ({
  styles,
  setShowModal,
  showModal,
  addMaterial,
  transaction,
}: OrderTrackingProps) => {
  const getOrderTracking = (status: Transaction["trackingStatus"]) => {
    switch (status) {
      case "order_created":
        return transaction.trackingStatus !== "pending" ? "green" : "yellow";
      case "order_proccessed":
        return transaction.trackingStatus !== "pending" &&
          transaction.trackingStatus !== "order_created"
          ? "green"
          : transaction.trackingStatus === "order_created"
          ? "yellow"
          : "gray";
      case "driver_pickup":
        return transaction.trackingStatus !== "pending" &&
          transaction.trackingStatus !== "order_created" &&
          transaction.trackingStatus !== "order_proccessed"
          ? "green"
          : transaction.trackingStatus === "order_proccessed"
          ? "yellow"
          : "gray";
      case "enroute":
        return transaction.trackingStatus === "enroute" ||
          transaction.trackingStatus === "delivered"
          ? "green"
          : transaction.trackingStatus === "driver_pickup"
          ? "yellow"
          : "gray";
      case "delivered":
        return transaction.trackingStatus === "delivered"
          ? "green"
          : transaction.trackingStatus === "enroute"
          ? "yellow"
          : "gray";
    }
  };
  return (
    <Modal
      className={styles}
      title="Order status"
      onCancel={() => setShowModal(false)}
      onOk={addMaterial}
      visible={showModal}
      footer={false}
    >
      <Timeline>
        <Timeline.Item
          pending={getOrderTracking("order_created") !== "green"}
          color={getOrderTracking("order_created")}
        >
          Order created
        </Timeline.Item>
        <Timeline.Item
          pending={getOrderTracking("order_proccessed") !== "green"}
          color={getOrderTracking("order_proccessed")}
        >
          Order processed
        </Timeline.Item>
        <Timeline.Item
          pending={getOrderTracking("driver_pickup") !== "green"}
          color={getOrderTracking("driver_pickup")}
        >
          Driver at pickup location
        </Timeline.Item>
        <Timeline.Item
          pending={getOrderTracking("enroute") !== "green"}
          color={getOrderTracking("enroute")}
        >
          Enroute
        </Timeline.Item>
        <Timeline.Item
          pending={getOrderTracking("delivered") !== "green"}
          color={getOrderTracking("delivered")}
        >
          Delivered
        </Timeline.Item>
      </Timeline>
    </Modal>
  );
};

export default OrderTracking;
