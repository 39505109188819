import {
  Input,
  Button,
  Form,
  Table,
  Menu,
  Modal,
  message,
  Tooltip,
  Checkbox,
  Row,
  Col,
} from "antd";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import styles from "../payments/request.module.css";
import classNames from "classnames";
import { Home, Package, Truck } from "react-feather";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";
import TopTab from "components/TopTab/TopTab";

const columns = [
  {
    title: "FIRST NAME",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "LAST NAME",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "EMAIL ADDRESS",
    dataIndex: "email",
    key: "email",
    ellipsis: true,
    render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
  },
  {
    title: "PHONE NUMBER",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "USER ROLE",
    dataIndex: "role",
    key: "role",
    render: () => "Admin",
  },
];

export default function Admins(props: ReachPathProps) {
  const [admins, setAdmins] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { user } = useAuth() as { user: User };

  const organizationId = user?.organizationId;
  const { handleSearch, isSearching, searchQuery } = useSearch();
  let searchResult = admins.filter((user) => {
    return (
      user.firstName.toLowerCase().indexOf(searchQuery.toLowerCase()) ||
      user.lastName.toLowerCase().indexOf(searchQuery.toLowerCase())
    );
  });

  const getAdmins = useCallback(
    async function () {
      setLoading(true);
      try {
        const {
          data: { matproAdmins },
        } = await axios.get(`admin/matproadmins`);


        setAdmins(matproAdmins);
      } catch (error) {

      } finally {
        setLoading(false);
      }
    },
    [organizationId]
  );

  useEffect(() => {
    getAdmins();
  }, [getAdmins]);

  const [form] = Form.useForm();

  return (
    <div>
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input
            onChange={(e) => handleSearch(e)}
            allowClear
            placeholder="search"
            prefix={<SearchOutlined />}
          />
        </div>

        <Button onClick={() => setShowModal(true)}>Add Admin</Button>
      </div>
      <div className={styles.table} style={{ overflowX: "auto" }}>
        {isSearching ? (
          <Table
            size="small"
            rowClassName={() => "table-row"}
            dataSource={searchResult}
            columns={columns}
            loading={loading}
            style={{ minWidth: "600px", textTransform: "capitalize" }}
          />
        ) : (
          <Table
            size="small"
            rowClassName={() => "table-row"}
            dataSource={admins}
            columns={columns}
            loading={loading}
            style={{ minWidth: "600px", textTransform: "capitalize" }}
          />
        )}
      </div>
      <Modal
        title="Add Admin"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={async (values) => {
            setIsSubmitting(true);
            await axios
              .post("admin/add-admin", {
                ...values,
                email: values?.email?.toLowerCase(),
                organizationId,
              })
              .then(() => {
                message.success("Admin added successfully");
                getAdmins();
                setShowModal(false);
              })
              .catch((err) => {
                requestErrorHandler(err);
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          }}
        >
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please enter Admin's First Name",
              },
            ]}
            label="First Name"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please enter Admin's Last Name",
              },
            ]}
            label="Last Name"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter Admin's email address",
              },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            label="Admin Email Address"
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please provide a valid phone number",
              },
              {
                pattern: /^(\+?234?)?0?(7|8|9)(0|1)\d{8}$/,
                message: "Please provide a valid phone number.",
              },
            ]}
            label="Admin Phone Number"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                min: 6,
                required: true,
                message: "Password must be at least 6 characters",
              },
            ]}
            label="Admin Password"
          >
            <Input.Password type="password" />
          </Form.Item>
          <Form.Item
            // initialValue={editModal?.permissions}
            rules={[{ required: true, message: "Select at least one Module" }]}
            name="selectedModules"
            label="Select Modules"
          >
            <Checkbox.Group className="w-100">
              <Row>
                <Col xs={12}>
                  <Checkbox value="dashboard">Dashboard</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="dashboard-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="transactions">Transaction</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="transactions-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="ho-transactions">HO Transaction</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="ho-transactions-write">
                    Write Access
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="homeowners">Homeowner</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="homeowners-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="brands">Brands</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="brands-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="materials">Materials</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="materials-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="builders">Builders</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="builders-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="suppliers">Suppliers</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="suppliers-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="dsp">DSP</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="dsp-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="payments">Payment</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="payments-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="user-management">User Management</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="user-management-write">
                    Write Access
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="settings">Settings</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="settings-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="profile">Profile</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="profile-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="blog">Blog</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="blog-write">Write Access</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="pending-signups">Pending Signups</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="pending-signups-write">
                    Write Access
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Checkbox value="contact-sales">Contact Sales</Checkbox>
                </Col>
                <Col xs={12}>
                  <Checkbox value="contact-sales-write">Write Access</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item>
            <Button loading={isSubmitting} type="primary" htmlType="submit">
              Add Admin
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
