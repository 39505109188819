import { Button, Divider, message, Skeleton } from "antd";
import Title from "antd/lib/typography/Title";
// import { DownloadOutlined } from "@ant-design/icons";
import styles from "./builders.module.css";
import { useEffect, useState } from "react";
import axios from "config/axios";
import { navigate, useMatch } from "@reach/router";
import requestErrorHandler from "util/requestErrorHandler";

const ContactSalesDetails = (props: ReachPathProps) => {
  // const [builder] = useState<BuilderObject | null>(null);
  // const [user, setUser] = useState<User | null>(null);
  const [enquiry, setEnquiry] = useState<any | []>();
  const [isLoading, setIsLoading] = useState(false);

  const match = useMatch("/admin/builders/sales/:id") as {
    uri: string;
    path: string;
    id: string;
  };
  const getEnquiry = () => {
    axios
      .get(`salesreport/getonereport/${match.id}`)
      .then(({ data }) => {
        setEnquiry(data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setIsLoading(true);
    getEnquiry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderEntity = (entity: any) => (entity === "" ? "--" : entity);
  // return <h1>Try work na</h1>;
  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        <Title level={5}>
          <Button
            style={{ padding: 0 }}
            type="text"
            onClick={() => navigate("/admin/builders/sales")}
          >
            Contact sales
          </Button>{" "}
          | <small style={{ opacity: 0.3 }}>{enquiry?.fullName}</small>
        </Title>
        <span></span>
      </div>

      <>
        <Divider />

        {isLoading && (
          <>
            <Skeleton.Input size="large" style={{ width: 1000, height: 350 }} />
          </>
        )}
        {!isLoading && (
          <>
            <div className={styles["details-card"]}>
              <b>Enquirer Info</b>
              <br />
              <br />
              <span>
                Full Name
                <b>{enquiry?.fullName}</b>
              </span>
              <span>
                Phone Number
                <b>{enquiry?.phone}</b>
              </span>
              <span>
                Email Address
                <b style={{ textTransform: "lowercase" }}>{enquiry?.email}</b>
              </span>
              <span>
                Company Address
                <b> {renderEntity(enquiry?.companyAddress)} </b>
              </span>
              <span>
                Company Name
                <b> {renderEntity(enquiry?.companyName)}</b>
              </span>
              <span>
                Company Size
                <b> {renderEntity(enquiry?.companySize)}</b>
              </span>
            </div>
            <div className={styles["details-card"]}>
              <b>Enquiry</b>
              <br />
              <br />
              <span>
                {renderEntity(enquiry?.report)}
                {/* <b> {renderEntity(enquiry?.report)}</b> */}
              </span>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default ContactSalesDetails;
