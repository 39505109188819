import { Button, Divider, Form, Input, Modal, Upload } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { ArrowUpOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";
import Spinner from "assets/icons/Spinner";
import useMaterials from "hooks/useMaterials";
import uploadToS3 from "util/uploadToS3";

interface modalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  callback?: () => void | any;
  id: string | undefined;
  material?: any;
}

const EditMaterial = ({
  showModal,
  setShowModal,
  callback,
  id,
  material,
}: modalProps) => {
  const [form] = Form.useForm();
  const { editMaterial } = useMaterials();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const action = () => {
    setIsSubmitting(false);
    setShowModal(false);
    callback && callback();
  };
  const handleSubmit = async (values: {}) => {
    setIsSubmitting(true);
    await editMaterial(id, values, action);
    resetFields();
  };
  const { resetFields } = form;
  return (
    <Modal
      className={styles.modal}
      title="Edit material"
      onCancel={() => setShowModal(false)}
      onOk={() => setShowModal(false)}
      visible={showModal}
      footer={false}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        className={styles["modal-form"]}
        initialValues={material}
      >
        <Form.Item
          label="Unit of measurement"
          name="unitOfMeasure"
          rules={[
            { required: true, message: "Please Enter Unit of measurememt" },
          ]}
        >
          <Input min={0} />
        </Form.Item>
        <Form.Item
          rules={[{ required: false, message: "Please Upload an image" }]}
          label="Upload material image"
          name="image"
          valuePropName="file"
          getValueFromEvent={(e) => {
            const file = e.fileList[0];
            if (file?.status === "done") {
              return file.response;
            }
          }}
        >
          <Upload
            customRequest={uploadToS3}
            listType="picture"
            multiple={false}
            maxCount={1}
            accept="image/*"
          >
            <Button icon={<ArrowUpOutlined />}>
              Click Here to upload an image
            </Button>
            <br />
            <small>
              Image should not be greater than <b>20MB</b>
            </small>
          </Upload>
        </Form.Item>
        <Divider />
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          {isSubmitting ? <Spinner /> : `SUBMIT`}
        </Button>
      </Form>
    </Modal>
  );
};

export default EditMaterial;
