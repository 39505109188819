import { message } from "antd";
import axiosConfig from "config/axios";
import { useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

const useCategories = () => {
  const [category, setCategory] = useState([]);
  const getCategories = (callback?: any) => {
    axiosConfig
      .get("category/all")
      .then(
        ({
          data: {
            data: { categories },
          },
        }) => {
          setCategory(categories);
        }
      )
      .catch((error) => {});
  };

  const addCategory = (values: any, callback?: () => any) => {
    axiosConfig
      .post("category", { ...values })
      .then(() => {
        message.success({
          content: "Category created",
        });
        callback && callback();
      })
      .catch((error) => {
        requestErrorHandler(error);
        callback && callback();
      });
  };

  const editCategory = (id: any, values: {}, callback?: () => any) => {
    axiosConfig
      .put(`category/${id}`, { ...values })
      .then(() => {
        message.success({
          content: "Category updated",
        });
        callback && callback();
      })
      .catch((error) => {
        requestErrorHandler(error);
        callback && callback();
      });
  };

  const deleteCategory = (id: any, callback?: () => any) => {
    axiosConfig
      .delete(`category/${id}`)
      .then(() => {
        message.success({
          content: `category deleted`,
        });
        callback && callback();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  };

  return { category, getCategories, addCategory, editCategory, deleteCategory };
};

export default useCategories;
