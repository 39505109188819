import axiosConfig from "config/axios";

interface InitialOrderValues {
  orderId: string;
  truckOrdersPerschedule: InitiateOrderItems;
}

const useGodumpApi = () => {
  const checkGodumpOrder = async (values: CreateOrderRequest) => {
    return await axiosConfig.post(`go-dump/check-order`, { ...values });
  };

  const initiateGodumpOrder = async (values: InitialOrderValues) => {
    return await axiosConfig.post(`go-dump/initiate-order`, { ...values });
  };

  const createodumpOrder = async (orderId: string) => {
    return await axiosConfig.post(`go-dump/order`, { orderId });
  };

  return {
    checkGodumpOrder,
    initiateGodumpOrder,
    createodumpOrder,
  };
};

export default useGodumpApi;
