import { Input, Table, Typography } from "antd";
// import TopTab from "components/TopTab/TopTab";
import { useEffect, useState } from "react";
import styles from "./transactions.module.css";
import axios from "config/axios";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
// import { navigate } from "@reach/router";

const { Text } = Typography;

const History = ({
  id,
}: ReachPathProps & {
  id?: string;
}) => {
  const [orders, setOrders] = useState<OrderCart[]>([]);
  const paidRender = (text: string | boolean) => {
    return (
      <>{text ? <Text style={{ color: "#00962C" }}>Paid</Text> : <Text style={{ color: "#FF612F" }}>Pending</Text>}</>
    );
  };

  useEffect(() => {
    axios.get(`order/completed/${id}`).then(({ data }) => {
      setOrders(data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const webTableColumns = [
    {
      title: "Order ID",
      dataIndex: "frontendId",
      key: "frontendId",
    },
    {
      title: "DATE",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: string) => dayjs(text).format("MMM DD, YYYY") as unknown as string,
    },
    {
      title: "PAYMENT STATUS",
      dataIndex: "isPaidFor",
      key: "isPaidFor",
      render: (text: string) => paidRender(text),
    },
    {
      title: "TOTAL",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (text: number) => text?.toLocaleString(),
    },
  ];

  // const mobileTableColumns = [
  //   {
  //     title: "Date",
  //     dataIndex: "updatedAt",
  //     key: "updatedAt",
  //     render: (text: string) => (
  //       <>
  //         <Text>{dayjs(text).format("MMM DD, YYYY") as unknown as string}</Text>
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Payment Status",
  //     dataIndex: "isPaidFor",
  //     key: "isPaidFor",
  //     render: (text: string) => (
  //       <>
  //         <div>{paidRender(text)}</div>
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Total",
  //     dataIndex: "totalPrice",
  //     key: "totalPrice",
  //     render: (text: number) => (
  //       <>
  //         <Text>{text?.toLocaleString()}</Text>
  //       </>
  //     ),
  //   },
  // ];

  return (
    <div className={styles.container}>
      {/* <TopTab
        links={[
          { to: "../", name: "Pending" },
          { to: "", name: "History" },
          { to: "/builders/transactions/outstanding", name: "Outstanding" },
        ]}
      /> */}
      <div className={styles.topbar}>
        <Input
          prefix={<SearchOutlined />}
          size="middle"
          style={{
            width: "100%",
            // paddingRight: isMobile ? "0" : "10px",
            marginTop: "1rem",
            maxWidth: "350px",
          }}
        />
      </div>
      <div className={styles["table-container"]}>
        <Table
          dataSource={orders}
          columns={webTableColumns}
          size="small"
          // onRow={(item) => {
          //   return {
          //     onClick: (event) => {
          //       navigate("transactions/" + item.id);
          //     },
          //   };
          // }}
          rowClassName={styles["table-row"]}
        />
      </div>
    </div>
  );
};

export default History;
