import { message } from "antd";
import { useEffect, useState } from "react";
import styles from "./like.module.css";
import AxiosBlogConfig from "util/axiosBlogConfig";
import { LikeOutlined } from "@ant-design/icons";
import requestErrorHandler from "util/requestErrorHandler";

const Like = ({ postId, likes, callback }: any) => {
  const [liked, setLiked] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem(`liked-${postId}`) === "true")
      return setLiked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLikes = async () => {
    if (liked) return;
    await AxiosBlogConfig.put(`blogpost/likepost/${postId}`)
      .then(() => {
        message.success("Post liked!");
        localStorage.setItem(`liked-${postId}`, "true");
        setLiked(true);
        callback();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
    // setLikes(likes + 1);
  };
  return (
    <div
      onClick={() => handleLikes()}
      className={`${!liked ? styles["like-wrap"] : styles["liked-wrap"]} ${
        styles["share-ui-icon"]
      }`}
    >
      {/* <img src="/like.png" width="30" /> */}
      <LikeOutlined />
      <p>{likes}</p>
    </div>
  );
};

export default Like;
