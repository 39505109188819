import { message } from "antd";
import { useEffect, useState } from "react";
import styles from "../Like/like.module.css";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import requestErrorHandler from "util/requestErrorHandler";

const Share = ({ title, text }: any) => {
  const [isShareSupported, setIsShareSupported] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const sharableText = text.replace(/<[^>]*>/g, "");
  const sharableTitle = title.replace(/<[^>]*>/g, "");

  const checkShareSupport = () => {
    if ("share" in navigator) {
      setIsShareSupported(true);
    } else setIsShareSupported(false);
  };
  useEffect(checkShareSupport, []);

  if (typeof window == "undefined") return <></>;
  // let url = window.location.href;
  // const canonical = document.querySelector("link[rel=canonical]");
  let url = document.location.href;

  const shareDetails = { url, sharableTitle, sharableText };
  function handleCopy() {
    try {
      const el = document.createElement("input");
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      message.success({
        content: "Url copied",
        style: {
          top: 100,
          left: "45%",
          position: "fixed",
        },
      });
      setCopied(true);
    } catch (error) {}
  }

  const handleShare = async () => {
    try {
      await window.share(shareDetails).then(() => {
        message.success("Post shared successfully!");
      });
    } catch (error) {
      requestErrorHandler(error);
    }
  };
  return (
    <div className={styles["like-wrap"]}>
      <div>
        {isShareSupported ? (
          <img
            className={styles["share-ui-icon"]}
            onClick={() => handleShare()}
            src="/share.png"
            width="45"
            alt="share"
          />
        ) : (
          <>
            <FacebookShareButton
              url={url ?? window.location.href}
              title={sharableTitle}
            >
              <FacebookIcon size={40} round={true} />
            </FacebookShareButton>
            <TwitterShareButton
              url={url ?? window.location.href}
              title={sharableTitle}
              via={""}
            >
              <TwitterIcon size={40} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton
              url={url ?? window.location.href}
              title={sharableTitle}
            >
              <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>
            <WhatsappShareButton
              url={url ?? window.location.href}
              title={sharableTitle}
            >
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>{" "}
            {copied ? (
              <div
                onClick={handleCopy}
                className={`${styles["share-ui-icon"]} ${styles["liked-wrap"]}`}
              >
                <CheckOutlined />
                <p> copied</p>
              </div>
            ) : (
              <div
                onClick={handleCopy}
                className={`${styles["share-ui-icon"]} ${styles["liked-wrap"]}`}
              >
                {" "}
                <CopyOutlined />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Share;
