import { Modal, Form, Input, Button, message, Upload } from "antd";
import axios from "config/axios";
import { useEffect, useMemo, useState } from "react";
import uploadToS3 from "util/uploadToS3";
import { ArrowUpOutlined } from "@ant-design/icons";
import requestErrorHandler from "util/requestErrorHandler";

export default function EditBrandModal({
  selectedBrand,
  setSelectedBrand,
  getBrands,
}: {
  selectedBrand: Brand | null;
  setSelectedBrand: (brand: Brand | null) => void;
  getBrands: () => Promise<void>;
}) {
  function onCancel() {
    setSelectedBrand(null);
  }

  const visible = useMemo(() => Boolean(selectedBrand), [selectedBrand]);

  return (
    <Modal
      afterClose={() => {
        getBrands();
      }}
      footer={null}
      open={visible}
      title={`Edit ${selectedBrand?.brandName}`}
      onCancel={onCancel}
    >
      {!!selectedBrand && (
        <EditForm initialValues={selectedBrand} onCancel={onCancel} />
      )}
    </Modal>
  );
}

function EditForm({
  initialValues,
  onCancel,
}: {
  initialValues: Brand;
  onCancel: () => void;
}) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    form.setFieldsValue({
      brandName: initialValues.brandName,
      brandLogo: initialValues.brandLogo,
    });
  }, [form, initialValues]);
  const brandName = initialValues.brandName;
  async function handleSubmit() {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await axios.post(`editbrandname/${initialValues.id}`, {
        brandName: form.getFieldValue("brandName"),
      });
      await axios.post(`editbrandlogo/${initialValues.id}`, {
        brandLogo: form.getFieldValue("brandLogo"),
      });
      onCancel();
      message.success({
        content: (
          <>
            <b>{brandName}</b> Edited Successfully`
          </>
        ),
        style: {
          textTransform: "capitalize",
        },
      });
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
      preserve={false}
      onFinish={handleSubmit}
    >
      <Form.Item
        data-testid="brand-name"
        name="brandName"
        label="Brand Name"
        rules={[{ required: true, message: "Please enter a valid brand" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        data-testid="brand-image"
        rules={[{ required: true, message: "Please Upload an image" }]}
        name="brandLogo"
        label="Upload Brand Image"
        valuePropName="file"
        getValueFromEvent={(e) => {
          const file = e.fileList[0];
          if (file?.status === "done") {
            return file.response;
          }
        }}
      >
        <Upload
          customRequest={uploadToS3}
          listType="picture"
          multiple={false}
          maxCount={1}
          accept="image/*"
        >
          <Button icon={<ArrowUpOutlined />}>
            Click Here to upload an image
          </Button>
          <br />
          <small>
            Image should not be greater than <b>20MB</b>
          </small>
        </Upload>
      </Form.Item>
      <div className="end--buttons">
        <Button htmlType="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          data-testid="save-edit"
          loading={isSubmitting}
          type="primary"
          htmlType="submit"
        >
          Save Edit
        </Button>
      </div>
    </Form>
  );
}
