import {
  Button,
  message,
  Space,
  Statistic,
  Switch,
  Table,
  Tooltip,
} from "antd";
import Spinner from "assets/icons/Spinner";
import EditDynamicPrice from "components/EditDynmicPrice";
import axiosConfig from "config/axios";
import useCategories from "hooks/useCategories";
import useMaterials from "hooks/useMaterials";
import { useCallback, useEffect, useState } from "react";
import styles from "./mp.module.css";
import requestErrorHandler from "util/requestErrorHandler";

const MaterialPricing = ({
  builder,
  getBuilder,
}: {
  builder?: BuilderObject;
  getBuilder: () => void;
}) => {
  const [isToggling, setIsToggling] = useState<boolean>(false);
  const [hasDynamicPricing, setHasDynamicPricing] = useState<
    boolean | undefined
  >(builder?.dynamicPricing);

  const { getMaterials, materials, isLoading } = useMaterials();
  const { getCategories, category } = useCategories();

  const builderId = builder?.id;
  const toggleDynamicPricing = () => {
    setIsToggling(true);
    if (!hasDynamicPricing) {
      try {
        axiosConfig.post("dynamicprice/turnon", {
          builderId,
        });
        getBuilder();
        setHasDynamicPricing(true);
        message.success("Dynamic pricing activiated");
      } catch (error) {
        requestErrorHandler(error);
      } finally {
        setIsToggling(false);
      }
    } else {
      try {
        axiosConfig.post("dynamicprice/turnoff", {
          builderId,
        });
        setHasDynamicPricing(false);
        message.warning("Dynamic pricing Deactiviated");
      } catch (error) {
        requestErrorHandler(error);
      } finally {
        setIsToggling(false);
      }
    }
  };

  useEffect(() => {
    getMaterials();
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !category) return <Spinner />;

  return (
    <div>
      <div className="c-end">
        <Space>
          <h3 data-testid="title"> Allow Dynamic Pricing</h3>
          <Switch
            checked={hasDynamicPricing}
            checkedChildren={<>ON</>}
            unCheckedChildren={<>OFF</>}
            disabled={isToggling}
            onChange={() => toggleDynamicPricing()}
          />
        </Space>
      </div>
      {!hasDynamicPricing ? (
        <></>
      ) : (
        <div className={styles["content"]}>
          {category.map((cat: any, index) => (
            <section key={index} className={styles["spreadsheet-container"]}>
              <h2 className={styles.heading}>{cat.name}</h2>
              {materials.map(
                (mat: any, index: any) =>
                  mat.categoryId === cat.id && (
                    <div
                      data-testid="category-item"
                      key={index}
                      className={styles["spreadsheet"]}
                    >
                      <div
                        data-testid="brand-item"
                        className={styles["left-side"]}
                      >
                        <b> {mat.brand.brandName}</b>
                      </div>
                      <VariantItem
                        variants={mat.variants}
                        builderId={builderId}
                      />
                    </div>
                  )
              )}
            </section>
          ))}
        </div>
      )}
    </div>
  );
};

export type EditModal = {
  status: boolean;
  variantId: string;
  dynamicPrce?: string | number;
  moq?: string;
};

const VariantItem = ({
  variants,
  builderId,
}: {
  variants: any;
  builderId?: string;
}) => {
  // const [price, setPrice] = useState<number>(variant.unitPrice);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dynamicPrices, setDynamicPrices] = useState<DynamiPricing[]>([]);
  const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(false);

  const [editModal, setEditModal] = useState<EditModal>({
    status: false,
    variantId: "",
    dynamicPrce: "",
  });

  const getDynmiacPrice = async () => {
    if (!builderId) return;
    setIsLoadingPrices(true);
    await axiosConfig
      .get(`dynamicprice/getdynamicprice/${builderId}`)
      .then((res) => {
        setDynamicPrices(res.data.allDynamicPrices);
      })
      .catch(() => {})
      .finally(() => setIsLoadingPrices(false));
  };

  useEffect(() => {
    getDynmiacPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDynamicPrice = useCallback(async (variantId: any, newPrice: any) => {
    setIsLoading(true);
    try {
      await axiosConfig.put("dynamicprice/changedynamicprice", {
        builderId,
        variantId,
        newPrice,
      });
      message.success({
        content: (
          <>
            {" "}
            <b style={{ textTransform: "capitalize" }}>
              {
                variants.find((variant: any) => variant.id === variantId)
                  .description
              }
            </b>{" "}
            Price updated
          </>
        ),
      });
      getDynmiacPrice();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: any) => {
    // e.preventDefault();
    if (values.dynamicPrice === 0) {
      return message.warning("Price cannot be 0");
    }
    setDynamicPrice(values.variantId, values.dynamicPrice);
  };

  const columns = [
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <Space>
            {}
            <img
              src={
                variants.find((variant: any) => variant.description === text)
                  .variantImage
              }
              alt=""
              height={50}
            />
            {text}
          </Space>
        </Tooltip>
      ),
    },
    {
      title: "PRICE",
      dataIndex: "unitPrice",
      key: "unitPrice",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <Statistic valueStyle={{ fontSize: "16px" }} value={text} />
        </Tooltip>
      ),
    },
    {
      title: "DYNAMIC PRICE",
      dataIndex: "id",
      key: "id",
      render: (text: string) => {
        return (
          <>
            {dynamicPrices.map(
              (dynamicPrice, idx) =>
                dynamicPrice.variantId === text && (
                  <Space key={idx}>
                    <Statistic
                      valueStyle={{ fontSize: "16px" }}
                      value={dynamicPrice.price}
                    />
                    <Button
                      data-testid="set-price"
                      type="primary"
                      onClick={() =>
                        setEditModal({
                          dynamicPrce: dynamicPrice.price,
                          status: true,
                          variantId: text,
                        })
                      }
                    >
                      SET PRICE
                    </Button>
                  </Space>
                )
            )}

            {/* <span style={{ textTransform: "lowercase" }}>{text}</span> */}
          </>
        );
      },
    },
  ];

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <Table
        rowClassName={styles["table-row"]}
        columns={columns}
        dataSource={variants}
        className={styles.table}
        loading={isLoadingPrices}
        size="small"
        style={{
          textTransform: "capitalize",
          fontSize: "0.2rem",
          minWidth: "600px",
        }}
      />
      <div key={editModal.dynamicPrce}>
        <EditDynamicPrice
          dynamicPrice={editModal.dynamicPrce ?? ""}
          setShowModal={setEditModal}
          showModal={editModal.status}
          variantId={editModal.variantId}
          onFinish={handleSubmit}
          isEditingPrice={isLoading}
        />
      </div>
    </div>
  );
};
export default MaterialPricing;
