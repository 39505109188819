import { Space, Table, Input, Typography, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import DeviceWidth from "context/MobileResizeContext";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./supplier.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import axios from "config/axios";
import { navigate } from "@reach/router";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";
const { Text } = Typography;

export function AllSuppliers(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [loading, setLoading] = useState<boolean>();
  const [suppliers, setSuppliers] = useState<BuilderObject[]>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();
  const [totalPages, setTotalPages] = useState<number>(0);

  const getActiveSupppliers = useCallback(
    (size: number = 10, page: number = 0) => {
      setLoading(true);
      axios
        .get(`supplier/getallsuppliers?page=${page}&size=${size}`)
        .then(({ data }) => {
          setTotalPages(data.data.totalPages);
          setSuppliers(
            data.data.allSuppliers
              .filter(function (supplier: any) {
                return supplier.supplier[0] !== undefined;
              })
              .map((supplier: any) => {
                const sup = supplier.supplier[0] as BuilderObject;
                const tableObject = sup;
                const user = supplier.user[0] as User;
                tableObject.phoneNumber = user?.phoneNumber;
                tableObject.admin = `${user?.firstName} ${user?.lastName}`;
                return tableObject;
              })
          );
          setLoading(false);
        })
        .catch((error) => {
          requestErrorHandler(error);
          setLoading(false);
        });
    },
    []
  );
  useEffect(() => {
    getActiveSupppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let searchResult = suppliers.filter((supplier) => {
    return (
      supplier.businessName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      supplier.admin.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      supplier.adminEmail.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      supplier.businessAddress
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      supplier.phoneNumber.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      supplier.status.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });
  const columns = [
    {
      title: "BUSINESS NAME",
      dataIndex: "businessName",
      key: "businessName",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "ADMIN",
      dataIndex: "admin",
      key: "admin",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "adminEmail",
      key: "adminEmail",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "ADDRESS",
      dataIndex: "businessAddress",
      key: "businessAddress",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text: OrganizationStatus) => (
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          {text === "pending_confirmation" && (
            <Text style={{ color: "#F2994A" }}>Not confirmed</Text>
          )}
          {text === "pending_approval" && (
            <Text style={{ color: "#F2994A" }}>Pending</Text>
          )}
          {text === "suspended" && (
            <Text style={{ color: "#F2994A" }}>Suspended</Text>
          )}
          {text === "approved" && (
            <Text style={{ color: "#00962C" }}>Approved</Text>
          )}
          {/* <Button style={{ transform: "rotateZ(90deg)" }} type="text">
            <MoreOutlined />
          </Button> */}
        </Space>
      ),
    },
  ];

  // const mobileTableColumns = [
  //   {
  //     dataIndex: "column-one",
  //     key: "1",
  //     ellipsis: true,
  //     render: (text: string) => (
  //       <>
  //         <Text>{text[0]}</Text>
  //         <br />
  //         <Text style={{ fontSize: "0.6rem" }}>{text[1]}</Text>
  //         <br />
  //         <Text>{text[2]}</Text>
  //       </>
  //     ),
  //   },
  //   {
  //     dataIndex: "column-two",
  //     key: "2",
  //     ellipsis: true,
  //     render: (text: string) => (
  //       <>
  //         <Text>{text[0]}</Text>
  //         <br />
  //         <Text style={{ fontSize: "0.6rem" }}>{text[1]}</Text>
  //         <br />
  //         <div>{text[2]}</div>
  //       </>
  //     ),
  //   },
  //   {
  //     dataIndex: "column-three",
  //     key: "3",
  //     ellipsis: true,
  //     render: (text: string) => (
  //       <>
  //         <Text>{text[0]}</Text>
  //         <br />
  //         <Text style={{ fontSize: "0.75rem" }}>{text[1]}</Text>
  //         <br />
  //         {text[2] === "ACTIVE" ? (
  //           <Text style={{ color: "#00962C" }}>{text[2]}</Text>
  //         ) : (
  //           <Text style={{ color: "#F2994A" }}>{text[2]}</Text>
  //         )}
  //       </>
  //     ),
  //   },
  // ];

  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "..", name: "New Requests" },
          { to: "", name: "All Suppliers" },
          { to: "../active", name: "Active Suppliers" },
          { to: "../sales", name: "Sales" },
        ]}
      />
      <div className={classNames([styles.topbar, "c-end"])}>
        <Input
          prefix={<SearchOutlined />}
          size="middle"
          onChange={(e) => handleSearch(e)}
          allowClear
          style={{ width: isMobile ? "100%" : "250px", paddingRight: "1rem" }}
        />
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table
          rowClassName={styles["table-row"]}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`details/${record.organizationId}`);
              },
            };
          }}
          columns={columns}
          dataSource={isSearching ? searchResult : suppliers}
          className={styles.table}
          size="small"
          pagination={{
            pageSize: 10,
            total: totalPages * 10,
            onChange: (page) => getActiveSupppliers(page - 1),
          }}
          loading={loading}
          style={{
            fontSize: "0.2rem",
            minWidth: "600px",
            textTransform: "capitalize",
          }}
        />
      </div>
    </div>
  );
}
