import React, { useEffect, useState } from "react";
import styles from "./transactions.module.css";
import logo from "assets/logo/main.png";
import classNames from "classnames";
import dayjs from "dayjs";
import { Select, Tag, Typography } from "antd";
import useAuth from "hooks/useAuth";
import BalanceAlertMessage from "components/BalanceAlertMessage.tsx";
import { useNavigate } from "@reach/router";

const { Option } = Select;

const InvoiceRef = React.forwardRef<
  HTMLDivElement,
  {
    // transactionId:string;
    order: OrderCart;
    qty: string;
    orderItems: OrderCart["order_items"];
    paymentMethod: string;
    setPaymentMethod: (s: string) => void;
  }
>(({ order, orderItems, paymentMethod, setPaymentMethod, qty }, ref) => {
  const navigate = useNavigate();
  // const { builder } = useAuth();

  const builder: any = {};
  const [paymentView, setPaymentView] = useState<1 | 2>(1);

  useEffect(() => {
    setPaymentView(+builder?.balance! === 0 ? 2 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="capture" ref={ref} className={classNames(styles.invoice)}>
      <div className="c-end">
        <div className={styles.matadd}>
          <div className={styles.invoice__header__logo}>
            <img width="100" src={logo} alt="logo" />
          </div>
          <address>
            <h2 className="primary-text">MaterialsPro</h2>
            <p>The Eagle’s Nest, 1st Floor,</p>
            <p>24, Adewunmi Adebimpe Drive,</p>
            <p>By the 2nd Roundabout,</p>
            <p>Lekki Phase 1, Lagos</p>
            {/* <p>Tax identification number: ShoplaKefty</p> */}
          </address>
        </div>
      </div>
      <div className={styles.invoice__header}>
        <address>
          <aside>
            <Typography.Title
              level={5}
              style={{ textTransform: "capitalize", marginBottom: "0" }}
            >
              To: {order.builder.businessName}
            </Typography.Title>
            <div className={styles["aside-card"]}>
              <span style={{ textTransform: "capitalize" }}>
                {order.billingAddress}
              </span>
              <p>
                Email:{" "}
                <a href={`mailto:${order.billingEmail}`}>
                  {order.billingEmail}
                </a>
              </p>
              <p>
                Phone:{" "}
                <a href={`tel:${order.billingPhoneNumber}`}>
                  {order.billingPhoneNumber}
                </a>
              </p>
            </div>
          </aside>
          <aside className={styles["aside-card"]}>
            {/* <p>{showOrderNumber}</p> */}

            <p>
              Invoice Number: {order.invoiceNumber}/<small>Reconciled </small>
            </p>
            <p>Date: {dayjs(order.updatedAt).format("DD MM YYYY")}</p>
            <p>
              Mode of payment:{" "}
              {order.isPaidFor ? (
                order.paymentMethod === "transfer" ? (
                  "Bank transfer"
                ) : (
                  "Trade credit"
                )
              ) : (
                <Select
                  style={{ padding: 0, margin: 0 }}
                  value={paymentMethod}
                  onChange={setPaymentMethod}
                >
                  <Option value="transfer">Bank transfer</Option>
                  <Option value="credit">Trade credit</Option>
                </Select>
              )}
            </p>
          </aside>
          <aside>
            {paymentMethod === "transfer" && (
              <div>
                <Typography.Title level={5}>Payable to:</Typography.Title>
                <div className={styles["aside-card"]}>
                  <p>Bank name: {builder!.accountBank}</p>
                  <p>Account name: {builder!.accountName}</p>
                  <p>Account number: {builder!.accountNumber}</p>
                </div>
              </div>
            )}
          </aside>
        </address>
      </div>
      <BalanceAlertMessage
        builder={builder}
        order={order}
        paymentView={paymentView}
      />
      <div className={styles["invoice-table-container"]}>
        <table>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Item description</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {orderItems?.map((item, index) => {
              return (
                <tr
                  className={styles["primary-row"]}
                  // style={{ cursor: "pointer" }}
                  key={index}
                  // onClick={() => {
                  //   navigate(`/builders/transactions/transaction/${item.id}`, {
                  //     state: {
                  //       ...item,
                  //       orderNumber: order?.orderNumber,
                  //       isPaidFor: order?.isPaidFor,
                  //       frontendId: order?.frontendId,
                  //       orderId: order.id,
                  //       variantId: item.variantId,
                  //     },
                  //   });
                  // }}
                >
                  <td className={styles["item-display"]}>{index + 1}</td>
                  <td className={styles["item-display"]}>
                    <Tag color="green" className={styles["item-display"]}>
                      {item.variant.material.brand.brandName}{" "}
                      {item.variant.material.category?.name}
                      {` ${item.variant.description}`}
                    </Tag>
                  </td>
                  {/* <td>
                    {(item.quantity * +item.variant.unitSize).toLocaleString()}{" "}
                    {item.variant.material.unitOfMeasure}
                  </td>
                  <td>
                    {dynamicPrices
                      .find((d) => d.variantId === item.variantId)
                      ?.price.toLocaleString() ??
                      item.variant.unitPrice.toLocaleString()}
                  </td>
                  <td>{order.materialPrice.toLocaleString()}</td> */}
                  <td className={styles["item-display"]}>
                    {/* {HERE */}
                    {/* {(
                      item?.order_details
                        ?.map((v) => v.amount)
                        ?.reduce((a, b) => a + b, 0) * +item?.variant?.unitSize
                    )?.toLocaleString()}{" "} */}
                    {qty} {item.variant.material.unitOfMeasure}
                  </td>
                  <td className={styles["item-display"]}>
                    {new Intl.NumberFormat().format(item?.finalUnitPrice)}
                  </td>
                  <td className={styles["item-display"]}>
                    {/* HERE */}
                    {item.order_details
                      ?.map((v) => +v.materialPrice)
                      ?.reduce((a, b) => +a + b, 0)
                      ?.toLocaleString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <th>Subtotal</th>
              <td className={styles["item-display"]}>
                {new Intl.NumberFormat().format(order.materialPrice)}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <th>Delivery</th>
              <td className={styles["item-display"]}>
                {new Intl.NumberFormat().format(order.deliveryPrice)}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>

              {(order.isPaidFor && order.paymentMethod === "credit") ||
              (!order.isPaidFor && paymentMethod === "credit") ? (
                <>
                  <th>Processing fee</th>
                  <td className={styles["item-display"]}>
                    {new Intl.NumberFormat().format(
                      (order.totalPrice * 7) / 100
                    )}
                  </td>
                </>
              ) : (
                <></>
              )}
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              {/* <th>VAT</th>
              <td>7%</td> */}
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <th>Total</th>

              {((order.isPaidFor && order.paymentMethod === "transfer") ||
                (!order.isPaidFor && paymentMethod === "transfer")) && (
                <td className={styles["item-display"]}>
                  {new Intl.NumberFormat().format(order.totalPrice)}
                </td>
              )}
              {((order.isPaidFor && order.paymentMethod === "credit") ||
                (!order.isPaidFor && paymentMethod === "credit")) && (
                <td className={styles["item-display"]}>
                  {new Intl.NumberFormat().format(
                    (order.totalPrice * 7) / 100 + order.totalPrice
                  )}
                </td>
              )}
            </tr>
          </tfoot>
        </table>
      </div>
      <p className={styles["sales-invoice"]}>
        Sales Invoice: {order.frontendId}
      </p>
    </div>
  );
});

export default InvoiceRef;
