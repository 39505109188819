const ArrowDown = ({ size, color = "#00962C" }: any) => {
  return (
    <svg
      width={size}
      height={size * 0.6}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 8L0 0H12L6 8Z" fill={color} />
    </svg>
  );
};

export default ArrowDown;
