import { Button, Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import { EditModal } from "components/MaterialPricing";

const EditDynamicPrice = ({
  showModal,
  setShowModal,
  variantId,
  dynamicPrice,
  onFinish,
  isEditingPrice,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<EditModal>>;
  variantId: string;
  dynamicPrice: string | number;
  onFinish?: (values: any) => void;
  isEditingPrice?: boolean;
}) => {
  const [form] = Form.useForm();
  return (
    <div id={dynamicPrice.toString()}>
      <Modal
        title="Edit Dynamic Price"
        footer={false}
        open={showModal}
        onCancel={() =>
          setShowModal({
            dynamicPrce: "",
            status: false,
            variantId: "",
          })
        }
        key={dynamicPrice}
      >
        <Form
          key={dynamicPrice}
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            data-testid="dynamic-price"
            initialValue={dynamicPrice}
            label={"Dynamic Price"}
            name="dynamicPrice"
            rules={[
              {
                required: true,
                message: "Please enter Dynamic Price",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data-testid="variant-id"
            initialValue={variantId}
            label={"Variant ID"}
            name="variantId"
            hidden
          >
            <Input />
          </Form.Item>
          <Button
            data-testid="submit"
            size="large"
            type="primary"
            htmlType="submit"
            loading={isEditingPrice}
          >
            SUBMIT
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default EditDynamicPrice;
