import {
  Button,
  Divider,
  message,
  Skeleton,
  Space,
  Switch,
  Table,
  Form,
  Input,
  Empty,
  Tabs,
} from "antd";
import Title from "antd/lib/typography/Title";
import {
  CheckCircleOutlined,
  MoreOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useCallback, useContext } from "react";
import styles from "./builders.module.css";
import { useEffect, useState } from "react";
import axios from "config/axios";
import { navigate, useMatch } from "@reach/router";
import AddCreditClass from "components/AddCreditClass/AddCreditClass";
import axiosConfig from "config/axios";
import useError from "hooks/useError";
import Spinner from "assets/icons/Spinner";
import FileViewer from "react-file-viewer";
import Modal from "antd/lib/modal/Modal";
import { saveAs } from "file-saver";
import DeviceWidth from "context/MobileResizeContext";
import MaterialPricing from "components/MaterialPricing";
import EditEmailModal from "components/EditEmailModal";
import AddMoqClass from "components/AddMoqClass";
import { getMoqClass } from "../settings/moq";
import { useDispatch } from "react-redux";
import { updateBuilder } from "store/slices/builder";
import requestErrorHandler from "util/requestErrorHandler";
import useModules from "hooks/useModules";
import AddDiscount from "components/AddDiscount";

const BusinessInfo = ({
  builder,
  setBuilder,
  user,
  rawData,
  getBuilder,
  isLoading,
}: {
  builder?: BuilderObject;
  setBuilder: any;
  user: any;
  rawData: any;
  getBuilder: any;
  isLoading: boolean;
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isReqLoading, setIsReqLoading] = useState(false);
  const [loadingSecAddress, setLoadingSecAddress] = useState<boolean>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMOdal, setShowModal] = useState<boolean>();
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>();
  const [showEditEmail, setShowEditEmail] = useState<boolean>(false);
  const [showSecondaryAmountField, setShowSecondaryAmountField] =
    useState<boolean>();
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [isMoqModal, setIsMoqModal] = useState<{
    status: boolean;
    builderClassId: string;
  }>({
    status: false,
    builderClassId: "",
  });
  const [, setLoadingMoqs] = useState<boolean>(true);
  const [moqClasses, setMoqClasses] = useState<MoqClass[]>([]);
  const { allowAction } = useModules();

  const _getMoqClasses = () => getMoqClass(setMoqClasses, setLoadingMoqs);

  useEffect(() => {
    _getMoqClasses();
  }, []);

  const isMobile = useContext(DeviceWidth);

  // const { builder, setBuilder } = useContext(BuilderContext);

  const { fetchError } = useError();

  const approveBuilder = useCallback(
    async (builderId: string) => {
      if (isReqLoading) return;
      setIsReqLoading(true);
      try {
        const {
          data: { data },
        } = await axios.post("builder/approvebuilder", { builderId });
        setBuilder(data);
        message.success("Builder successfully approved");
        getBuilder();
      } catch (error: any) {
        requestErrorHandler(error);
      } finally {
        setIsReqLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReqLoading]
  );

  const suspendBuilder = useCallback(
    async (builderId: string) => {
      if (isReqLoading) return;
      setIsReqLoading(true);
      try {
        const {
          data: { data },
        } = await axios.post("builder/suspendbuilder", { builderId });
        setBuilder(data);
        message.success("Builder successfully suspended");
        getBuilder();
      } catch (error: any) {
        requestErrorHandler(error);
      } finally {
        setIsReqLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReqLoading]
  );

  const ToggleSecondaryAddress = (builderId?: string) => {
    // setShowSecondaryAmountField(!showSecondaryAmountField);
    setIsReqLoading(true);
    axiosConfig
      .put("/admin/toggleaddress", { builderId })
      .then((res) => {
        message.success("Changed secondary address permission");
        getBuilder();
        setIsReqLoading(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
        setIsReqLoading(false);
      });
    setShowSecondaryAmountField(builder?.isSecondaryAddressAvailable);
  };
  const builderId = builder?.id;
  const handleSubmit = (value: any) => {
    setLoadingSecAddress(true);
    axiosConfig
      .put("admin/setdeliveryprice", {
        builderId,
        price: value?.secondaryAmount,
      })
      .then((res) => {
        message.success("Secondary Address price set");
        setLoadingSecAddress(false);
        form.resetFields();
        getBuilder();
      })
      .catch((error) => {
        requestErrorHandler(error);
        setLoadingSecAddress(false);
      });
  };

  const type = "pdf";

  const SaveFile = () => {
    saveAs(rawData?.cacDocument, `CACdocument.${type}`);
  };

  const editEmail = (values: any) => {
    if (values.email === builder?.adminEmail)
      return message.error("Please enter a different email");
    setIsEditingEmail(true);
    axiosConfig
      .put("/admin/changeBuilderEmail", {
        builderId,
        ...values,
      })
      .then((data) => message.success("Builder Email Edited"))
      .catch((error) => requestErrorHandler(error))
      .finally(() => setIsEditingEmail(false));
  };

  const handlePlaceOrder = () => {
    dispatch(updateBuilder(builder!));
    localStorage.setItem("builder", JSON.stringify(builder));
    navigate(`/admin/builders/buy`);
  };

  const isApproved =
    !isLoading &&
    builder?.status === "approved" &&
    allowAction("builders-write");
  const isSuspended =
    !isLoading &&
    builder?.status === "suspended" &&
    allowAction("builders-write");
  const pendingApproval =
    !isLoading &&
    builder?.status === "pending_approval" &&
    allowAction("builders-write");

  return (
    <>
      <Space className={styles.cta}>
        {isLoading && (
          <>
            <Skeleton.Button />
            <Skeleton.Button />
          </>
        )}
        {isApproved && (
          <Space>
            <Button
              onClick={() => setShowDiscountModal(true)}
              // href={`/admin/builders/buy/${builderId}`}
            >
              Set Discount
            </Button>
            <Button
              onClick={handlePlaceOrder}
              // href={`/admin/builders/buy/${builderId}`}
            >
              Place Order
            </Button>
            <Button
              type="primary"
              onClick={() => suspendBuilder(builder.id)}
              danger
              disabled={isReqLoading}
            >
              Suspend account
            </Button>
          </Space>
        )}
        {isSuspended && (
          <Button
            disabled={isReqLoading}
            type="primary"
            onClick={() => approveBuilder(builder.id)}
          >
            {isReqLoading ? <Spinner /> : "Activate account"}
          </Button>
        )}
        {pendingApproval && (
          <>
            <Button
              type="primary"
              onClick={() => suspendBuilder(builder.id)}
              danger
              disabled={isReqLoading}
            >
              Reject account
            </Button>
            <Button
              disabled={isReqLoading}
              type="primary"
              onClick={() => approveBuilder(builder.id)}
            >
              <CheckCircleOutlined />
              Approve account
            </Button>
          </>
        )}
      </Space>
      <Divider />

      {isLoading && (
        <>
          <Skeleton.Input size="large" style={{ width: 1000, height: 350 }} />
        </>
      )}
      {!isLoading && Boolean(builder) && (
        <>
          <div className={styles["details-card"]}>
            <span>
              Business Name
              <b>{builder?.businessName}</b>
            </span>
            <span>
              Business Address
              <b>{builder?.businessAddress}</b>
            </span>
            <span>
              Zip/postal code
              <b>{builder?.zipCode}</b>
            </span>
            <span>
              State / Country
              <b>{builder?.state}, Nigeria</b>
            </span>
            <span>
              Phone address
              <b>{user?.phoneNumber}</b>
            </span>
            <span>
              Email address
              <b style={{ textTransform: "lowercase" }}>
                {builder?.adminEmail}{" "}
                {allowAction("builders-write") && (
                  <Button onClick={() => setShowEditEmail(true)}>Edit</Button>
                )}
              </b>
            </span>
            <EditEmailModal
              showModal={showEditEmail}
              setSowModal={setShowEditEmail}
              organizatino={builder}
              onFinish={(values) => {
                editEmail(values);
                getBuilder();
              }}
              isEditingEmail={isEditingEmail}
            />
          </div>

          <div className={styles["details-card"]}>
            <span>
              CAC Document
              {allowAction("builders-write") && (
                <b>
                  <Button onClick={(e) => setShowModal(true)} type="default">
                    VIEW
                  </Button>
                </b>
              )}
            </span>
            <span>
              Tax ID
              <b>{builder?.taxId ?? "Not uploaded"}</b>
            </span>
            <Modal
              footer={
                rawData?.cacDocument ? (
                  <Button
                    disabled={!allowAction("builders-write")}
                    onClick={SaveFile}
                  >
                    {" "}
                    <DownloadOutlined /> Download Document
                  </Button>
                ) : (
                  ""
                )
              }
              open={showMOdal}
              onCancel={() => setShowModal(false)}
            >
              {rawData?.cacDocument ? (
                <FileViewer fileType={type} filePath={rawData?.cacDocument} />
              ) : (
                <div>
                  <Empty description="No document uploaded yet" />
                </div>
              )}
            </Modal>
          </div>
          <div className={styles["details-card"]}>
            <span>
              Account class
              {allowAction("builders-write") && (
                <b className="c-class">
                  {builder?.builder_credit?.className}{" "}
                  <Button
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    type="link"
                  >
                    {builder?.builderCreditId ? "Edit" : "Add"}
                  </Button>
                </b>
              )}
            </span>
          </div>
          <div className={styles["details-card"]}>
            <span>
              Moq class
              <b className="c-class">
                {
                  moqClasses.find((_moq) => _moq.id === builder?.builderClassId)
                    ?.title
                }{" "}
                {allowAction("builders-write") && (
                  <Button
                    onClick={() => {
                      setIsMoqModal({
                        status: true,
                        builderClassId: builder?.builderClassId ?? "",
                      });
                    }}
                    type="link"
                  >
                    {builder?.builderClassId ? "Edit" : "Add"}
                  </Button>
                )}
              </b>
            </span>
          </div>
          <div className={styles["details-card"]}>
            <Space className={styles["set-availability"]}>
              <b>Allow secondary address</b>
              <div style={{ padding: "0 0.5rem" }} />
              <Switch
                checked={builder?.isSecondaryAddressAvailable}
                checkedChildren={<>ON</>}
                unCheckedChildren={<>OFF</>}
                disabled={isReqLoading}
                onChange={() => ToggleSecondaryAddress(builder?.id)}
              />
            </Space>
            {builder?.secondaryDeliveryPrice ? (
              <div>
                <Space>
                  Current Secondary Delivery price{" "}
                  <span> ₦{builder?.secondaryDeliveryPrice}</span>
                </Space>
              </div>
            ) : (
              <></>
            )}
            {builder?.isSecondaryAddressAvailable && (
              <>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                  <Form.Item
                    label={"Enter secondary delivery price"}
                    name="secondaryAmount"
                    rules={[
                      {
                        required: showSecondaryAmountField,
                        message: "Please enter a secondary delivery address",
                      },
                    ]}
                    style={{
                      width: isMobile ? "100%" : "50%",
                      marginTop: "0.5rem",
                    }}
                  >
                    <Input
                    // defaultValue={
                    //   builder?.secondaryDeliveryPrice || "Set extra charge here"
                    // }
                    />
                  </Form.Item>
                  {allowAction("builders-write") && (
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={loadingSecAddress}
                    >
                      SUBMIT`
                    </Button>
                  )}
                </Form>
              </>
            )}
          </div>
          <AddDiscount
            isModalOpen={showDiscountModal!}
            id={builder?.id as string}
            builder={builder!}
            onCancel={() => {
              setShowDiscountModal(false);
            }}
          />
          <AddCreditClass
            isModalOpen={isModalOpen}
            id={builder?.id as string}
            onCancel={() => {
              setIsModalOpen(false);
            }}
            callback={() => getBuilder()}
          />
          <AddMoqClass
            isModalOpen={isMoqModal.status}
            id={builder?.id as string}
            builderClassId={isMoqModal.builderClassId}
            onCancel={() => {
              setIsMoqModal({
                status: false,
                builderClassId: "",
              });
            }}
            callback={() => getBuilder()}
            moqClasses={moqClasses}
          />
        </>
      )}
    </>
  );
};
const Users = () => {
  // const isMobile = useContext(DeviceWidth);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const { allowAction } = useModules();

  const match = useMatch("/admin/builders/details/:id") as {
    uri: string;
    path: string;
    id: string;
  };

  const getUsers = useCallback(
    async function getUsers() {
      setLoading(true);
      try {
        const {
          data: {
            data: { organizationUsers },
          },
        } = await axios.get(`organization/${match.id}/users`);
        setUsers(organizationUsers);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [match.id]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);
  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "USER ROLE",
      dataIndex: "role",
      key: "role",
      render: (text: string) => (
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          {text}
          {allowAction("builders-write") && (
            <Button style={{ transform: "rotateZ(90deg)" }} type="text">
              <MoreOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={users}
        className={styles.table}
        rowClassName={styles["table-row"]}
        size="middle"
        style={{ fontSize: "0.2rem", overflowX: "auto" }}
        pagination={false}
      />

      {/* {isMobile && (
        <Table
          columns={mobileTableColumns}
          dataSource={mobileTableData}
          className={styles.table}
          size="middle"
          style={{ fontSize: "0.2rem", overflowX: "auto" }}
          pagination={false}
        />
      )} */}
    </div>
  );
};
const MainView = (props: ReachPathProps) => {
  const { TabPane } = Tabs;
  const isMobile = useContext(DeviceWidth);
  const [builder, setBuilder] = useState<BuilderObject>();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [rawData, setRawData] = useState<any>();

  const match = useMatch("/admin/builders/details/:id") as {
    uri: string;
    path: string;
    id: string;
  };

  useEffect(() => {
    setIsLoading(true);
    getBuilder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.id]);

  const getBuilder = () => {
    axiosConfig
      .get(`builder/getonebuilder/${match.id}`)
      .then(({ data }) => {
        setUser(data.data.user[0]);
        const builderData = data.data.builder[0];
        data.data.user.map((x: any) => {
          if (x.role.toLowerCase() === "admin") {
            const userId = x.id;
            const userPhoneNumber = x.phoneNumber;
            const _builder: BuilderObject = {
              ...builderData,
              userId,
              userPhoneNumber,
            };
            setBuilder(_builder);
          }
          return null;
        });

        setRawData(data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }
  // const { builder } = useContext(BuilderContext);
  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        <Title level={5}>
          <Button
            style={{ padding: 0 }}
            type="text"
            onClick={() => navigate(-1)}
          >
            Builder
          </Button>
        </Title>
        <span></span>
      </div>
      <Tabs defaultActiveKey="1" type="card" size="large" centered={isMobile}>
        <TabPane tab="Business Info" key="1">
          <BusinessInfo
            isLoading={isLoading}
            builder={builder}
            getBuilder={getBuilder}
            rawData={rawData}
            user={user}
            setBuilder={setBuilder}
          />
        </TabPane>
        <TabPane tab="Users" key="2">
          <Users />
        </TabPane>
        <TabPane tab="Material Pricing" key="3">
          <MaterialPricing builder={builder} getBuilder={getBuilder} />
        </TabPane>
        {/* <Router>
        <BusinessInfo path="/" />
        <Users path="user" />
      </Router> */}
      </Tabs>
    </div>
  );
};

export default MainView;
