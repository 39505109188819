import { Button, message, Popconfirm } from "antd";
import styles from "./comment.module.css";
import dayjs from "dayjs";
import { useState } from "react";
import AxiosBlogConfig from "util/axiosBlogConfig";
import requestErrorHandler from "util/requestErrorHandler";

const Comment = ({
  comment,
  callback,
  color,
}: {
  comment: { id: string; name: string; comment: string; createdAt: string };
  callback: () => void;
  color: any;
}) => {
  const [deleting, setDeleting] = useState<boolean>(false);

  const handleDelete = () => {
    setDeleting(true);
    AxiosBlogConfig.delete(`blogpost/deletecomment/${comment.id}`)
      .then(() => {
        message.success("Comment Deleted");
        callback();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setDeleting(false));
  };

  return (
    <div className={styles["comment-wrap"]}>
      <section className={styles.header}>
        <div className={styles.image} style={{ backgroundColor: color }}>
          {comment.name.split("")[0].toUpperCase()}
        </div>
        <h3>{comment.name}</h3>
      </section>
      <article>{comment.comment}</article>
      <section className={`${styles.footer} c-end`}>
        <span>{dayjs(comment.createdAt).format("DD MMM YYYY : HH mm ss")}</span>
        <Popconfirm
          okButtonProps={{ danger: true, size: "large" }}
          cancelButtonProps={{ type: "link", size: "large" }}
          okText="YES"
          cancelText="NO"
          title="Sure to Delete Comment?"
          onConfirm={() => handleDelete()}
        >
          <Button loading={deleting} type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
      </section>
    </div>
  );
};

export default Comment;
