import { Table, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DeviceWidth from "context/MobileResizeContext";
import { useContext, useEffect, useState } from "react";
import styles from "./sales.module.css";
import classNames from "classnames";
import axios from "config/axios";
import { navigate } from "@reach/router";
import useSearch from "hooks/useSearch";
import DashboardShared from "components/DashboardShared/DashboardShared";
import requestErrorHandler from "util/requestErrorHandler";

export function Sales(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [loading, setLoading] = useState<boolean>();
  const [enquiry, setEnquiry] = useState<any | []>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();

  let searchResult = enquiry.filter((enq: any) => {
    return (
      enq.fullName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      enq.email.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      enq.phone.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      enq.report.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get("salesreport/getallreports")
      .then(({ data }) => {
        setEnquiry(data.data.filter((item: any) => item.orgType === "builder"));
        setLoading(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // let result = enquiry.filter((builder: any) => {
  //   return (
  //     builder.businessName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
  //   );
  // });

  const columns = [
    {
      title: "FULL NAME",
      dataIndex: "fullName",
      key: "fullName",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },

    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "EQUIRY",
      dataIndex: "report",
      key: "report",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
  ];

  return (
    <DashboardShared title="Contact Sales">
      <div className={styles.container}>
        <div className={classNames([styles.topbar, "c-end"])}>
          <Input
            prefix={<SearchOutlined />}
            size="middle"
            onChange={(e) => handleSearch(e)}
            allowClear
            style={{ width: isMobile ? "100%" : "250px", paddingRight: "1rem" }}
          />
        </div>
        <div style={{ width: "100%", overflowX: "auto" }}>
          <Table
            rowClassName={styles["table-row"]}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`builders/sales/${record.id}`);
                },
              };
            }}
            columns={columns}
            dataSource={isSearching ? searchResult : enquiry}
            loading={loading}
            className={styles.table}
            size="small"
            style={{
              fontSize: "0.2rem",
              minWidth: "600px",
              textTransform: "capitalize",
            }}
          />
        </div>
      </div>
    </DashboardShared>
  );
}
