import { Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import TopTab from "components/TopTab/TopTab";
import DeviceWidth from "context/MobileResizeContext";
import { useContext, useEffect, useState } from "react";
import styles from "./transactions.module.css";
import axios from "config/axios";
import { message, Tooltip } from "antd";
import useSearch from "hooks/useSearch";
import dayjs from "dayjs";
import { navigate } from "@reach/router";
import requestErrorHandler from "util/requestErrorHandler";

const Unpaid = (proops: ReachPathProps) => {
  const isMobile = useContext(DeviceWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get("order/getall/unpaidorder");
        setTransactions(res.data.unpaidOrders);
      } catch (error: any) {
        requestErrorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  let searchResult = transactions?.filter((transaction) => {
    return (
      transaction?.frontendId.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      transaction.builder.businessName
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.paymentMethod
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(transaction.createdAt)
        .format("MMM DD, YYYY")
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const webTableColumns = [
    {
      title: "INVOICE ID",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      // render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) =>
        dayjs(text).format("MMM DD, YYYY") as unknown as string,
    },
    {
      title: "TYPE",
      dataIndex: "builderId",
      key: "builderId",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text ? "BUILDER" : "HOMEOWNER"}>
          {text ? "BUILDER" : "HOMEOWNER"}
        </Tooltip>
      ),
    },
    {
      title: "NAME",
      dataIndex: "builder",
      key: "builder",
      ellipsis: true,
      render: (text: any, record: any) => (
        <Tooltip
          title={
            text?.businessName
              ? text.businessName
              : record.homeOwner?.firstName + " " + record.homeOwner?.lastName
          }
        >
          {text?.businessName
            ? text.businessName
            : record.homeOwner?.firstName + " " + record.homeOwner?.lastName}
        </Tooltip>
      ),
    },
    {
      title: "PAYMENT METHOD",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "TOTAL PRICE",
      dataIndex: "totalPrice",
      key: "totalPrice",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>₦{text}</Tooltip>,
    },
  ];

  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "", name: "Unpaid" },
          { to: "pending", name: "Pending" },
          { to: "history", name: "History" },
        ]}
      />
      <div className={styles.topbar}>
        <Input
          className={styles.search}
          prefix={<SearchOutlined />}
          placeholder="search"
          onChange={(e) => handleSearch(e)}
          allowClear
          size="middle"
          style={{ width: isMobile ? "100%" : "auto", marginRight: "1rem" }}
        />
      </div>
      <div className={styles.table} style={{ overflowX: "auto" }}>
        <Table
          loading={isLoading}
          dataSource={isSearching ? searchResult : transactions}
          columns={webTableColumns}
          rowClassName={styles["table-row"]}
          style={{
            minWidth: "600px",
          }}
          onRow={(row) => {
            return {
              onClick: (event) => {
                navigate(`transactions/unpaid/${row.id}`);
              },
            };
          }}
          size="middle"
        />
      </div>
    </div>
  );
};

export default Unpaid;
