import { Modal, Form, Input, Button, Upload } from "antd";
import axios from "config/axios";
import { useState } from "react";
import uploadToS3 from "util/uploadToS3";
import { ArrowUpOutlined } from "@ant-design/icons";
import requestErrorHandler from "util/requestErrorHandler";

export default function CreateBrandModal({
  createOpen,
  onCancel,
  getBrands,
}: {
  createOpen: boolean;
  onCancel: () => void;
  getBrands: (search?: string) => Promise<void>;
}) {
  return (
    <Modal
      afterClose={() => getBrands()}
      footer={null}
      open={createOpen}
      title={"Create Brand"}
      onCancel={onCancel}
    >
      <CreateForm onCancel={onCancel} />
    </Modal>
  );
}

function CreateForm({ onCancel }: { onCancel: () => void }) {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  function handleClose() {
    form.resetFields();
    onCancel();
  }
  async function handleSubmit() {
    if (submitting) return;
    setSubmitting(true);
    try {
      await axios.post("createbrand", form.getFieldsValue());
      handleClose();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <Form
      layout="vertical"
      form={form}
      preserve={false}
      onFinish={handleSubmit}
    >
      <Form.Item
        data-testid="brand-name"
        name="brandName"
        label="Brand Name"
        rules={[{ required: true, message: "Please enter a valid brand" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        data-testid="brand-image"
        rules={[{ required: true, message: "Please Upload an image" }]}
        name="brandLogo"
        label="Upload Brand Image"
        valuePropName="file"
        getValueFromEvent={(e) => {
          const file = e.fileList[0];
          if (file?.status === "done") {
            return file.response;
          }
        }}
      >
        <Upload
          customRequest={uploadToS3}
          listType="picture"
          multiple={false}
          maxCount={1}
          accept="image/*"
        >
          <Button icon={<ArrowUpOutlined />}>
            Click Here to upload an image
          </Button>
          <br />
          <small>
            Image should not be greater than <b>20MB</b>
          </small>
        </Upload>
      </Form.Item>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1rem",
          marginTop: "1rem",
        }}
      >
        <Button data-testid="cancel" htmlType="button" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          data-testid="create-brand"
          loading={submitting}
          type="primary"
          htmlType="submit"
        >
          Create Brand
        </Button>
      </div>
    </Form>
  );
}
