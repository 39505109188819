import { Alert, Button, Divider, Skeleton, Space, message } from "antd";
import styles from "./builders.module.css";
import { useEffect, useState } from "react";
import axiosConfig from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";
import TopTab from "components/TopTab/TopTab";

const Feedback = ({
  homeowner,
  isLoading,
}: {
  homeowner?: HomeownerObject;
  isLoading?: Boolean;
} & ReachPathProps) => {
  const [feedbacks, setFeedbacks] = useState<any[]>();

  const getFeedbacks = async () => {
    try {
      const { data } = await axiosConfig.get(`/homeowner/get-feedback`);

      setFeedbacks(data.feedbacks);
    } catch (error) {}
  };

  useEffect(() => {
    getFeedbacks();
  }, []);

  return (
    <div>
      <>
        <Space className={styles.cta}>
          {isLoading && (
            <>
              <Skeleton.Button />
              <Skeleton.Button />
            </>
          )}
        </Space>
        <TopTab
          links={[
            { to: "..", name: "All Home Owners" },
            { to: "", name: "User Feedbacks" },
          ]}
        />
        <Divider />

        {isLoading && (
          <>
            <Skeleton.Input size="large" style={{ width: 1000, height: 350 }} />
          </>
        )}
        {!isLoading && (
          <>
            {feedbacks
              ?.filter((feedback) => !feedback.isAddressed)
              ?.map((feedback, index) => (
                <FeedbackBox
                  key={index}
                  feedback={feedback}
                  getFeedbacks={getFeedbacks}
                  index={index}
                />
              ))}
          </>
        )}
      </>
    </div>
  );
};

export const FeedbackBox = ({
  feedback,
  index,
  getFeedbacks,
}: {
  feedback: any;
  index: number;
  getFeedbacks: () => void;
}) => {
  const [isAddressing, setIsAddressing] = useState<boolean>(false);

  const addressFeedback = async (id: string) => {
    setIsAddressing(true);
    try {
      const { data } = await axiosConfig.post(`homeowner/address-feedback`, {
        feedbackId: id,
      });
      message.success("FeedBack Addressed");
      getFeedbacks();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsAddressing(false);
    }
  };

  return (
    <div
      key={index}
      style={{
        padding: "1rem",
        opacity: feedback.isAddressed ? 0.5 : 1,
      }}
      className={styles["details-card"]}
    >
      <span>
        {feedback?.homeOwner?.firstName}
        <p
          style={{
            textTransform: "lowercase",
            lineHeight: 1.1,
            opacity: 0.6,
          }}
        >
          {feedback?.feedback}
        </p>
      </span>
      <div>
        {feedback.isAddressed ? (
          <Alert style={{ fontSize: "1rem" }} message="Feedback Addressed" />
        ) : (
          <div className="c-end">
            <Button
              style={{ padding: 0, margin: 0 }}
              type="link"
              size="small"
              loading={isAddressing}
              disabled={isAddressing}
              onClick={() => addressFeedback(feedback?.id)}
            >
              Address Feedback
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedback;
