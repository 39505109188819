import { Button, Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";

const EditEmailModal = ({
  showModal,
  setSowModal,
  organizatino,
  onFinish,
  isEditingEmail,
}: {
  showModal: boolean;
  setSowModal: any;
  organizatino: any;
  onFinish: (e: any) => void;
  isEditingEmail: boolean;
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      title="Edit Email"
      footer={false}
      visible={showModal}
      onCancel={() => setSowModal(false)}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          initialValue={organizatino?.adminEmail}
          label={"Enter email"}
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter Email",
            },
          ]}
          // style={{
          //   width: isMobile ? "100%" : "50%",
          //   marginTop: "0.5rem",
          // }}
        >
          <Input
          // defaultValue={
          //   builder?.secondaryDeliveryPrice || "Set extra charge here"
          // }
          />
        </Form.Item>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          loading={isEditingEmail}
        >
          SUBMIT
        </Button>
      </Form>
    </Modal>
  );
};

export default EditEmailModal;
