import {
  Button,
  Divider,
  Empty,
  message,
  Skeleton,
  Space,
  Table,
  Tabs,
} from "antd";
import DeviceWidth from "context/MobileResizeContext";
import {
  CheckCircleOutlined,
  MoreOutlined,
  // PlusOutlined,
  DeleteFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState, useCallback } from "react";
import styles from "./supplier.module.css";
import axios from "config/axios";
// import Material from "../materials/material";
import { navigate, useMatch } from "@reach/router";
import EditSupplierCategory from "components/EditSupplierCategory/EditSupplierCategory";
import FileViewer from "react-file-viewer";
import { saveAs } from "file-saver";
import Modal from "antd/lib/modal/Modal";
import axiosConfig from "config/axios";
import EditEmailModal from "components/EditEmailModal";
import requestErrorHandler from "util/requestErrorHandler";
import useModules from "hooks/useModules";

const BusinessInfo = () => {
  const [supplier, setSupplier] = useState<BuilderObject | null>(null);
  const [enabledCategories, setEnabledCategories] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReqLoading, setIsReqLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>();
  const [rawData, setRawData] = useState<any>();
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [showEditEmail, setShowEditEmail] = useState<boolean>(false);
  const { allowAction } = useModules();

  const match = useMatch("/admin/suppliers/details/:id") as {
    uri: string;
    path: string;
    id: string;
  };
  const approveSupplier = useCallback(
    async (supplierId: string) => {
      if (isReqLoading) return;
      setIsReqLoading(true);
      try {
        const {
          data: { data },
        } = await axios.post("supplier/approve", { supplierId });
        setSupplier(data);
        message.success("Supplier successfully approved");
      } catch (error: any) {
        requestErrorHandler(error);
      } finally {
        setIsReqLoading(false);
      }
    },
    [isReqLoading]
  );

  const suspendSupplier = useCallback(
    async (supplierId: string) => {
      if (isReqLoading) return;
      setIsReqLoading(true);
      try {
        const {
          data: { data },
        } = await axios.post("supplier/suspend", { supplierId });
        setSupplier(data);
        message.success("Supplier successfully suspended");
      } catch (error: any) {
        requestErrorHandler(error);
      } finally {
        setIsReqLoading(false);
      }
    },
    [isReqLoading]
  );

  const getSupplier = useCallback(async () => {
    await axios
      .get(`supplier/getonesupplier/${match.id}`)
      .then(({ data }) => {
        setSupplier(data.data.supplier[0]);
        setRawData(data.data);
        axiosConfig
          .get(`suppliercategory/${data.data.supplier[0].id}`)
          .then(({ data }) => {
            setEnabledCategories(data.data.supplierCategories);
          })
          .catch((error) => {});
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [match.id]);

  useEffect(() => {
    setIsLoading(true);
    getSupplier();
  }, [getSupplier]);

  const disableSupplierCategory = async (categoryId: string, name: string) => {
    await axiosConfig
      .post("suppliercategory/disable", {
        supplierId: supplier?.id,
        categoryId,
      })
      .then((res) => {
        message.warning(`${name} Category disabled`);
        getSupplier();
      })
      .catch((error) => requestErrorHandler(error));
  };

  const type = "pdf";

  const SaveFile = () => {
    saveAs(rawData?.cacDocument, `CACdocument.${type}`);
  };

  const supplierId = supplier?.id;

  const editEmail = (values: any) => {
    if (values.email === supplier?.adminEmail)
      return message.error("Please enter a different email");
    setIsEditingEmail(true);
    axiosConfig
      .put("/admin/changeBuilderEmail", {
        supplierId,
        ...values,
      })
      .then((data) => message.success("Builder Email Edited"))
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setIsEditingEmail(false));
  };

  const isApproved =
    !isLoading &&
    supplier?.status === "approved" &&
    allowAction("builders-write");
  const isSuspended =
    !isLoading &&
    supplier?.status === "suspended" &&
    allowAction("builders-write");
  const pendingApproval =
    !isLoading &&
    (supplier?.status === "pending_approval" ||
      supplier?.status === "pending_confirmation") &&
    allowAction("builders-write");

  return (
    <>
      <Space className={styles.cta}>
        {isLoading && (
          <>
            <Skeleton.Button />
            <Skeleton.Button />
          </>
        )}
        {isApproved && (
          <Button
            type="primary"
            onClick={() => suspendSupplier(supplier.id)}
            danger
          >
            Suspend account
          </Button>
        )}
        {isSuspended && (
          <Button type="primary" onClick={() => approveSupplier(supplier.id)}>
            Activate account
          </Button>
        )}
        {pendingApproval && (
          <>
            <Button
              type="primary"
              onClick={() => suspendSupplier(supplier.id)}
              danger
            >
              Reject account
            </Button>
            <Button type="primary" onClick={() => approveSupplier(supplier.id)}>
              <CheckCircleOutlined />
              Approve account
            </Button>
          </>
        )}
      </Space>
      <Divider />

      {isLoading && (
        <>
          <Skeleton.Input size="large" style={{ width: 1000, height: 350 }} />
        </>
      )}
      {!isLoading && Boolean(supplier) && (
        <>
          <div className={styles["details-card"]}>
            <span>
              Business Name
              <b>{supplier?.businessName}</b>
            </span>
            <span>
              Business Address
              <b>{supplier?.businessAddress}</b>
            </span>
            <span>
              Zip/postal code
              <b>{supplier?.zipCode}</b>
            </span>
            <span>
              State / Country
              <b>{supplier?.state}, Nigeria</b>
            </span>
            <span>
              Phone address
              <b>+234989768987</b>
            </span>
            <span>
              Email address
              <b style={{ textTransform: "lowercase" }}>
                {supplier?.adminEmail}{" "}
                {allowAction("suppliers-write") && (
                  <Button onClick={() => setShowEditEmail(true)}>Edit</Button>
                )}
              </b>
            </span>
            <EditEmailModal
              showModal={showEditEmail}
              setSowModal={setShowEditEmail}
              onFinish={(e) => {
                editEmail(e);
                getSupplier();
              }}
              isEditingEmail={isEditingEmail}
              organizatino={supplier}
            />
          </div>

          <div className={styles["details-card"]}>
            <span>
              CAC Document
              {allowAction("suppliers-write") && (
                <b>
                  <Button onClick={(e) => setShowModal(true)} type="default">
                    VIEW
                  </Button>
                </b>
              )}
            </span>
            <span>
              Tax ID
              <b>{rawData?.taxId ?? "Not uploaded"}</b>
            </span>
            <Modal
              footer={
                rawData?.cacDocument && allowAction("suppliers-write") ? (
                  <Button onClick={SaveFile}>
                    {" "}
                    <DownloadOutlined /> Download Document
                  </Button>
                ) : (
                  ""
                )
              }
              visible={showModal}
              onCancel={() => setShowModal(false)}
            >
              {rawData?.cacDocument ? (
                <FileViewer fileType={type} filePath={rawData?.cacDocument} />
              ) : (
                <div>
                  <Empty description="No document uploaded yet" />
                </div>
              )}
            </Modal>
          </div>
          <div className={styles["details-card"]}>
            {enabledCategories.length > 0 && (
              <span>
                Added Categories
                <div>
                  <ul>
                    {enabledCategories
                      .sort((a, b) =>
                        a.SupplierCategory.priorityLevel <
                        b.SupplierCategory.priorityLevel
                          ? 1
                          : 0
                      )
                      .map((category, idx) => (
                        <li
                          key={idx}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textTransform: "capitalize",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div>{category.name} </div>
                          <div>{category.SupplierCategory.priorityLevel}</div>
                          {allowAction("suppliers-write") && (
                            <div>
                              <Button
                                onClick={() =>
                                  disableSupplierCategory(
                                    category.id,
                                    category.name
                                  )
                                }
                                danger
                                type="text"
                                size="small"
                              >
                                <DeleteFilled />
                              </Button>
                            </div>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              </span>
            )}
            {allowAction("suppliers-write") && (
              <EditSupplierCategory
                callback={() => getSupplier()}
                id={supplier?.id}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
const Users = () => {
  // const isMobile = useContext(DeviceWidth);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  const match = useMatch("/admin/suppliers/details/:id") as {
    uri: string;
    path: string;
    id: string;
  };

  const getUsers = useCallback(
    async function getUsers() {
      setLoading(true);
      try {
        const {
          data: {
            data: { organizationUsers },
          },
        } = await axios.get(`organization/${match.id}/users`);
        setUsers(organizationUsers);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [match.id]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      render: (text: any) => (
        <span style={{ textTransform: "lowercase" }}>{text}</span>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "USER ROLE",
      dataIndex: "role",
      key: "role",
      render: (text: string) => (
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          {text}
          <Button style={{ transform: "rotateZ(90deg)" }} type="text">
            <MoreOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  // const mobileTableColumns = [
  //   {
  //     dataIndex: "column-one",
  //     key: "1",
  //     render: (text: string) => (
  //       <>
  //         <Text>{text[0]}</Text>
  //         <br />
  //         <Text style={{ fontSize: "0.6rem" }}>{text[1]}</Text>
  //         <br />
  //         <Text>{text[2]}</Text>
  //       </>
  //     ),
  //   },
  //   {
  //     dataIndex: "column-two",
  //     key: "2",
  //     render: (text: string) => (
  //       <>
  //         <Text>{text[0]}</Text>
  //         <br />
  //         <Text style={{ fontSize: "0.6rem" }}>{text[1]}</Text>
  //         <br />
  //         <div>{text[2]}</div>
  //       </>
  //     ),
  //   },
  //   {
  //     dataIndex: "column-three",
  //     key: "3",
  //     render: (text: string) => (
  //       <>
  //         <Text>{text[0]}</Text>
  //         <br />
  //         <Text style={{ fontSize: "0.75rem" }}>{text[1]}</Text>
  //         <br />
  //         <Text>{text[2]}</Text>
  //       </>
  //     ),
  //   },
  // ];

  return (
    <div style={{ overflow: "auto" }}>
      <Table
        loading={loading}
        columns={columns}
        dataSource={users}
        className={styles.table}
        rowClassName={styles["table-row"]}
        size="middle"
        style={{ minWidth: "600px", textTransform: "capitalize" }}
        // style={{ fontSize: "0.2rem", overflowX: "auto" }}
        pagination={false}
      />
    </div>
  );
};
const BankAccount = () => {
  return (
    <div className={`${styles["details-card"]} ${styles["bank-account-card"]}`}>
      <span>
        Bank name
        <b>Guaranty trust bank</b>
      </span>
      <span>
        Account name
        <b>Joy Obianuju</b>
      </span>
      <span>
        Account number
        <b>021678593</b>
      </span>
    </div>
  );
};

// const MaterialsView = () => {
//   // const [showModal, setShowModal] = useState<boolean>(false);
//   const [materials, setMaterials] = useState([]);
//   const [category, setCategory] = useState([]);
//   const [isLoading, setIsLoading] = useState<boolean>(true);

//   const getMaterials = () => {
//     return axios
//       .get("material")
//       .then(
//         ({
//           data: {
//             data: { materials },
//           },
//         }) => {
//           setMaterials(materials);
//           setIsLoading(false);
//         }
//       )
//       .catch((error) => {
//         setIsLoading(false);
//       });
//   };
//   const getCategory = () => {
//     axios
//       .get("category/all")
//       .then(
//         ({
//           data: {
//             data: { categories },
//           },
//         }) => {
//           setCategory(categories);
//           setIsLoading(false);
//         }
//       )
//       .catch((error) => {
//         setIsLoading(false);
//       });
//   };

//   useEffect(() => {
//     Promise.all([getCategory(), getMaterials()]);
//   }, []);
//   return (
//     <div>
//       <div className={styles.cta}>
//         <Button
//           // onClick={() => setShowModal(true)}
//           size="middle"
//           type="primary"
//           icon={<PlusOutlined />}
//         >
//           Add new material
//         </Button>
//       </div>
//       <div>
//         <Material
//           category={category}
//           materials={materials}
//           isLoading={isLoading}
//         />
//       </div>
//     </div>
//   );
// };
const MainView = (props: ReachPathProps) => {
  const { TabPane } = Tabs;
  const isMobile = useContext(DeviceWidth);
  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        <Button style={{ padding: 0 }} type="text" onClick={() => navigate(-1)}>
          Supplier
        </Button>
      </div>
      <Tabs defaultActiveKey="1" type="card" size="large" centered={isMobile}>
        <TabPane tab="Business Info" key="1">
          <BusinessInfo />
        </TabPane>
        <TabPane tab="Users" key="2">
          <Users />
        </TabPane>
        <TabPane tab="Bank account" key="3">
          <BankAccount />
        </TabPane>
        {/* <TabPane tab="Materials" key="4">
          <MaterialsView />
        </TabPane> */}
      </Tabs>
    </div>
  );
};

export default MainView;
