import { Button, Divider, Form, Input, Modal, Select, Upload } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./styles.module.css";
import Spinner from "assets/icons/Spinner";
import useCategories from "hooks/useCategories";
import uploadToS3 from "util/uploadToS3";
import { ArrowUpOutlined } from "@ant-design/icons";
import QuillTextArea from "components/QuillTextArea";
import axiosConfig from "config/axios";

interface modalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  callback?: () => void | any;
  id: string | undefined;
  selectedCategory?: any;
  afterClose?: () => void;
}
const { Option } = Select;
const EditCategory = ({
  showModal,
  setShowModal,
  callback,
  id,
  selectedCategory,
  afterClose,
}: modalProps) => {
  const [form] = Form.useForm();
  const { editCategory } = useCategories();
  const [trucks, setTrucks] = useState<[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const action = () => {
    setIsSubmitting(false);
    setShowModal(false);
    callback && callback();
    resetFields();
  };
  const handleSubmit = async (values: {}) => {
    setIsSubmitting(true);
    await editCategory(id, values, action);
  };
  useEffect(() => {
    axiosConfig
      .get("truck")
      .then(({ data: { data } }) => {
        setTrucks(data);
      })
      .catch((err) => {});
  }, []);

  const { resetFields } = form;
  return (
    <Modal
      className={styles.modal}
      title="Edit category"
      onCancel={() => setShowModal(false)}
      onOk={() => setShowModal(false)}
      open={showModal}
      footer={false}
      afterClose={afterClose}
    >
      <Form
        form={form}
        initialValues={selectedCategory}
        onFinish={handleSubmit}
        layout="vertical"
        className={styles["modal-form"]}
      >
        <Form.Item
          label="Category name"
          name="name"
          rules={[{ required: true, message: "Please enter a category name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please enter a description" }]}
        >
          {/* <Input.TextArea /> */}
          <QuillTextArea />
        </Form.Item>

        <Form.Item
          label="Technical Specification"
          name="technicalSpecification"
          rules={[
            {
              required: true,
              message: "Please enter a technical specification",
            },
          ]}
        >
          {/* <Input.TextArea /> */}
          <QuillTextArea />
        </Form.Item>
        <Form.Item label="Select Truck" name="truckId">
          <Select style={{ width: "100%" }} allowClear>
            {trucks?.map(
              ({ id, truckType }: { id: string; truckType: string }) => (
                <>
                  <Option
                    style={{ textTransform: "capitalize" }}
                    key={id}
                    value={id}
                  >
                    {truckType}
                  </Option>
                </>
              )
            )}
          </Select>
        </Form.Item>
        <Form.Item
          label="Minimum delivery time (in Days)"
          name="minimum_delivery_time"
          rules={[
            {
              required: true,
              message: "Please enter a minimum sales quantity",
            },
          ]}
        >
          <Input type="number" min={0} />
        </Form.Item>
        <Form.Item
          rules={[{ required: false, message: "Please Upload an image" }]}
          label="Upload category image"
          name="image"
          valuePropName="file"
          getValueFromEvent={(e) => {
            const file = e.fileList[0];
            if (file?.status === "done") {
              return file.response;
            }
          }}
        >
          <Upload
            customRequest={uploadToS3}
            listType="picture"
            multiple={false}
            maxCount={1}
            accept="image/*"
          >
            <Button icon={<ArrowUpOutlined />}>
              Click Here to upload an image
            </Button>
            <br />
            <small>
              Image should not be greater than <b>20MB</b>
            </small>
          </Upload>
        </Form.Item>
        <Divider />
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          {!isSubmitting ? `SUBMIT` : <Spinner />}
        </Button>
      </Form>
    </Modal>
  );
};

export default EditCategory;
