import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./settings.module.css";
import CreateTruckModal from "components/CreateTruckModal/CreateTruckModal";
import { useEffect, useState } from "react";
import axios from "config/axios";
import EditTruckModal from "components/EditTruckModal/EditTruckModal";
import PageSpinner from "components/PageSpinner";
import useModules from "hooks/useModules";

export default function Truck(props: ReachPathProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [trucks, setTrucks] = useState<TruckSetting[]>([]);
  const [, setCurrentTruck] = useState<TruckSetting | null>(null);
  async function getTrucks() {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get("truck", { params: { page: 0, pageSize: 25 } });
      setTrucks(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTrucks();
  }, []);
  return (
    <div className={styles["bank-container"]}>
      <div className="c-en">
        <Button
          size="large"
          type="primary"
          className=""
          icon={<PlusOutlined />}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          Create truck type
        </Button>
      </div>
      {isLoading ? (
        <div style={{ paddingTop: "10rem" }}>
          <PageSpinner />
        </div>
      ) : (
        trucks.map((truck) => (
          <TruckList
            key={truck.id}
            truck={truck}
            setCurrentTruck={setCurrentTruck}
            getTrucks={getTrucks}
          />
        ))
      )}

      <CreateTruckModal
        isModalOpen={isModalOpen}
        getTrucks={getTrucks}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      />
    </div>
  );
}

function TruckList(props: {
  truck: TruckSetting;
  getTrucks: () => Promise<void>;
  setCurrentTruck: (truck: TruckSetting) => void;
}) {
  async function deleteTruck() {
    await axios.post("truck/delete", { truckType: props.truck.truckType });
    props.getTrucks();
  }

  const { allowAction } = useModules();

  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <div className={styles.trucklist}>
      <div>
        <h3>{props.truck.truckType}</h3>
        {allowAction("settings-write") && (
          <>
            <Button type="link" danger onClick={deleteTruck}>
              Delete
            </Button>
            <Button type="link" onClick={() => setShowModal(true)}>
              Edit
            </Button>
          </>
        )}
      </div>
      <ul>
        {props.truck.truckTonnage.map((tonnage, idx) => (
          <li key={idx}>{`${tonnage.toLocaleString()} tonnes`}</li>
        ))}
      </ul>
      <EditTruckModal
        showModal={showModal}
        setShowModal={setShowModal}
        getTrucks={props.getTrucks}
        currentTruck={props.truck}
      />
    </div>
  );
}
