import { Menu } from "react-feather";
import { useDispatch } from "react-redux";
import Bell from "assets/icons/Bell";
import { toggle } from "store/slices/sidenav";
import styles from "./dashshared.module.css";
import { Space } from "antd";
import useAuth from "hooks/useAuth";

type DashboardSharedProps = {
  title?: string;
  children: React.ReactNode;
  right?: any;
};
export default function DashboardShared(props: DashboardSharedProps) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  return (
    <div className={styles.main}>
      <div className={styles.topbar}>
        <h1>{props.title} &nbsp;</h1>
        <Space
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          <h4 data-testid="greeting" style={{ margin: 0 }}>
            {" "}
            👋 <span style={{ fontWeight: "lighter" }}>Hello </span>{" "}
            {user?.lastName}
          </h4>
          <button className={styles.bell}>
            <Bell />
          </button>
          {props.right && <div>{props.right}</div>}
          <button
            className={styles.toggle}
            onClick={() => {
              dispatch(toggle());
            }}
          >
            <Menu />
          </button>
        </Space>
      </div>
      <div className={styles.content}>
        <div>{props.children}</div>
      </div>
    </div>
  );
}
