import { Alert, Button, Divider, Empty, Spin } from "antd";
import { BlogItem } from "components/BlogPost/BlogItem";
import CreateBlogPost from "components/BlogPost/CreateBlogPost";
import useBlog from "hooks/useBlog";
import React, { useEffect, useState } from "react";
import styles from "./blog.module.css";
import { PlusOutlined } from "@ant-design/icons";
import DashboardShared from "components/DashboardShared/DashboardShared";
import { Router } from "@reach/router";
import BlogDetails from "./blog-details";
import TopTab from "components/TopTab/TopTab";
import { Subscriber } from "./Subscriber";
import useModules from "hooks/useModules";

const BackOffice = (props: ReachPathProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const { isFetching, blogPosts, getBlogPosts } = useBlog();
  const { allowAction } = useModules();

  useEffect(() => {
    getBlogPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles["container"]}>
      <TopTab
        links={[
          { to: "", name: "Blog" },
          { to: "subscribers", name: "Subscribers" },
        ]}
      />
      <div style={{ textAlign: "center" }}>
        {allowAction("blog-write") && (
          <Button
            icon={<PlusOutlined />}
            onClick={() => setShowModal(true)}
            type="primary"
            size="large"
          >
            NEW BLOG POST
          </Button>
        )}
      </div>
      <Divider></Divider>
      <div className={styles.content}>
        {allowAction("blog-write") && (
          <CreateBlogPost
            showModal={showModal}
            setShowModal={setShowModal}
            callback={() => getBlogPosts()}
          />
        )}
        {/* <p>Blog content</p> */}

        <div className={styles.content}>
          <div className={styles["blog-wrapper"]}>
            {isFetching ? (
              <Spin />
            ) : !isFetching && !blogPosts ? (
              <Alert closable type="error" message={"An error occured"} />
            ) : blogPosts.length < 1 ? (
              <Empty description="There doesn't seem to be any Blog posts at this time" />
            ) : (
              blogPosts.map((item: Post, idx) => (
                <BlogItem
                  getBlogPosts={() => getBlogPosts()}
                  key={idx}
                  post={item}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MainView = () => {
  return (
    <DashboardShared title="Blog Back-office">
      <Router>
        <BackOffice path="/" />
        <Subscriber path="/subscribers" />
        <BlogDetails path="/:id" />
      </Router>
    </DashboardShared>
  );
};

export default MainView;
