import { Input, Table, Tooltip } from "antd";
import DashboardShared from "components/DashboardShared/DashboardShared";
import axiosConfig from "config/axios";
import DeviceWidth from "context/MobileResizeContext";
import useSearch from "hooks/useSearch";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./pending-signups.module.css";
import classNames from "classnames";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import requestErrorHandler from "util/requestErrorHandler";

export default function Profile(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [pendingSignups, setPendingSignups] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>();

  const { handleSearch, isSearching, searchQuery } = useSearch();

  const getAllPendingSignups = useCallback(
    (page: number = 0, size: number = 10) => {
      setLoading(true);
      axiosConfig
        .get(`admin/getUncompleted`)
        .then(({ data }) => {
          setPendingSignups(data.data);
          setLoading(false);
        })
        .catch((error) => {
          requestErrorHandler(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  useEffect(() => {
    getAllPendingSignups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let result = pendingSignups.filter((pendingSignup) => {
    return (
      pendingSignup.email.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      pendingSignup.accountType
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(pendingSignup.createdAt)
        .format("MMM DD, YYYY")
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const columns = [
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
      key: "email",
      ellipsis: false,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "ACCOUNT TYPE",
      dataIndex: "accountType",
      key: "accountType",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "DATE CREATED",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          {dayjs(text).format("MMM DD, YYYY") as unknown as string}
        </Tooltip>
      ),
    },
  ];

  return (
    <DashboardShared title="Pending Signups">
      <div className={styles.container}>
        <div className={classNames([styles.topbar, "c-end"])}>
          <Input
            prefix={<SearchOutlined />}
            size="middle"
            onChange={(e) => handleSearch(e)}
            allowClear
            style={{ width: isMobile ? "100%" : "250px", paddingRight: "1rem" }}
          />
        </div>
        <div style={{ width: "100%", overflowX: "auto" }}>
          <Table
            rowClassName={styles["table-row"]}
            columns={columns}
            dataSource={isSearching ? result : pendingSignups}
            className={styles.table}
            loading={loading}
            size="middle"
            style={{
              textTransform: "capitalize",
              fontSize: "0.2rem",
              minWidth: "600px",
            }}
          />
        </div>
      </div>
    </DashboardShared>
  );
}
