import { Modal, Form, Input, Button, message } from "antd";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";
type Props = {
  isPassOpen: boolean;
  onCancel: () => void;
};
export default function ChangePassModal({ isPassOpen, onCancel }: Props) {
  const { logOut } = useAuth();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  async function handleSubmit() {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await axios.put("user/password", form.getFieldsValue());
      onCancel();
      message.success("Password changed successfully");
      setTimeout(() => {
        message.success("Please login with new password");
        logOut();
      }, 1000);
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }
  return (
    <Modal
      footer={null}
      visible={isPassOpen}
      title={"Change Password"}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          data-testid="current-password"
          rules={[{ required: true, message: "Cannot be blank" }]}
          name="currentPassword"
          label="Current Password"
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          data-testid="new-password"
          rules={[{ required: true, message: "Cannot be blank" }]}
          name="newPassword"
          label="New Password"
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          data-testid="confirm-new-password"
          // rules={[{ required: true, message: "Cannot be blank" }]}
          name="confirmNewPassword"
          label="Confirm New Password"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item data-testid="save">
          <Button
            htmlType="submit"
            data-testid="save"
            block
            type="primary"
            size="large"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
