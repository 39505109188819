import DashboardShared from "components/DashboardShared/DashboardShared";
import { Router, Link } from "@reach/router";
import Builder from "./builder";
import Truck from "./truck";
import Bank from "./bank";
import styles from "./settings.module.css";
import Moq from "./moq";
import Admins from "./Admins";
import SupplierTool from "./SupplierTool";

const links = [
  { to: "", children: "Builder Credit Class" },
  { to: "truck", children: "Truck settings" },
  { to: "bank", children: "Bank accounts" },
  { to: "moq", children: "Builder MOQ Class" },
  { to: "admins", children: "Admins" },
  { to: "supplier-tool", children: "SupplierTool" },
];

export default function Settings(props: ReachPathProps) {
  return (
    <DashboardShared title="Settings">
      <div className={styles.main}>
        <div className={styles.nav}>
          {links.map((link) => (
            <StyledLink {...link} key={link.children} />
          ))}
        </div>
        <div className={styles.body}>
          <div className={styles.list}>
            <Router>
              <Builder path="/" />
              <Truck path="truck" />
              <Bank path="bank" />
              <Moq path="moq" />
              <Admins path="admins" />
              <SupplierTool path="supplier-tool" />
            </Router>
          </div>
        </div>
      </div>
    </DashboardShared>
  );
}

export function StyledLink({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) {
  return (
    <Link
      to={to}
      getProps={(props: any) => {
        return props.isCurrent
          ? {
              style: {
                background: "#00962C",
                color: "#fff",
                boxShadow: "0px 2px 10px #00962C77",
                borderRadius: "4px",
              },
            }
          : {};
      }}
    >
      {children}
    </Link>
  );
}
