import axiosConfig from "config/axios";
import { useState } from "react";

const useDynamicPrice = (builderId: string) => {
  const [dynamicPrices, setDynamicPrices] = useState<DynamiPricing[]>([]);
  const [, setIsLoadingPrices] = useState<boolean>(false);
  const getDynamicPrice = async () => {
    if (!builderId) return;
    setIsLoadingPrices(true);
    await axiosConfig
      .get(`dynamicprice/getdynamicprice/${builderId}`)
      .then((res) => {
        setDynamicPrices(res.data.allDynamicPrices);
      })
      .catch((error) => {})
      .finally(() => setIsLoadingPrices(false));
  };

  return {
    getDynamicPrice,
    dynamicPrices,
  };
};

export default useDynamicPrice;
