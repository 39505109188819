import { useCallback, useState } from "react";
import axiosConfig from "../config/axios";
import requestErrorHandler from "util/requestErrorHandler";

export default function useProject() {
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isMarking, setIsMarking] = useState<boolean>(false);
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [project, setProject] = useState<ProjectType>();

  const getContractorProjects = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get("homeowner/contractor-projects");
      setProjects(
        response.data.projects?.sort(
          (projectFirst: ProjectType, projectSecond: ProjectType) =>
            new Date(projectSecond.createdAt).getTime() -
            new Date(projectFirst.createdAt).getTime()
        )
      );
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjects = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        "homeowner/get-projects-homeowner"
      );
      setProjects(
        response.data.projects?.sort(
          (projectFirst: ProjectType, projectSecond: ProjectType) =>
            new Date(projectSecond.createdAt).getTime() -
            new Date(projectFirst.createdAt).getTime()
        )
      );
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOneProject = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `homeowner/get-project?projectId=${id}`
      );
      setProject(response.data.project);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteProject = useCallback(async (id: string) => {
    setIsDeleting(true);
    try {
      await axiosConfig.delete(`homeowner/delete-project?projectId=${id}`);
      getProjects();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsDeleting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markProject = useCallback(
    async (id: string, status: "pending" | "ongoing" | "finished") => {
      setIsMarking(true);
      try {
        await axiosConfig.post(`homeowner/change-status`, {
          projectId: id,
          status: status,
        });
        getProjects();
      } catch (error) {
        requestErrorHandler(error);
      } finally {
        setIsMarking(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    loading,
    project,
    projects,
    getProjects,
    deleteProject,
    isDeleting,
    getOneProject,
    isMarking,
    markProject,
    getContractorProjects,
  };
}
