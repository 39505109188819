import { Button } from "antd";
// import styles from "styles/notfound.module.css";
import styles from "./admin/layout.module.css";

const NotFound = (props: ReachPathProps) => {
  return (
    <div className={styles["not-found"]}>
      {/* <img src="logo.png" alt="logo" width={200} /> */}
      <h1>404</h1>
      <h3>Page not found</h3>
      <img src="/404b.png" alt="404" width={350} />
      <Button href="/" type="link">
        Go to Home page
      </Button>
    </div>
  );
};
export default NotFound;
