import { Link } from "@reach/router";
import { Col, Divider, Empty, Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ItemCard from "components/Cards/ItemCard";
import DeviceWidth from "context/MobileResizeContext";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./builders.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import axios from "config/axios";
import SkeletonInput from "antd/lib/skeleton/Input";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";
import useModules from "hooks/useModules";

export function NewRequest(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [isLoading, setIsloading] = useState(false);
  const [builders, setBuilders] = useState<BuilderObject[]>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();
  const { allowAction } = useModules();

  const getPendingBuilders = useCallback(async () => {
    setIsloading(true);
    try {
      const {
        data: { data },
      } = await axios.get("builder/getallpendingbuilders");
      setBuilders(
        data.allPendingBuilders.map((builder: any) => builder.builder[0])
      );
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsloading(false);
    }
  }, []);

  let searchResult = builders.filter((builder) => {
    return (
      builder.businessName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      builder.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      builder.businessAddress.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1
    );
  });

  useEffect(() => {
    getPendingBuilders();
  }, [getPendingBuilders]);
  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "", name: "New Requests" },
          { to: "all", name: "All Builders" },
          { to: "active", name: "Active Builders" },
          { to: "credit", name: "Builder Credit" },
        ]}
      />
      <div className={classNames([styles.topbar, "c-end"])}>
        <Input
          prefix={<SearchOutlined />}
          placeholder="search"
          onChange={(e) => handleSearch(e)}
          size="middle"
          allowClear
          style={{ width: isMobile ? "100%" : "350px", paddingRight: "1rem" }}
        />
      </div>
      <Divider />
      <div className={styles["request-container"]}>
        {isLoading &&
          [1, 2, 3].map((i) => (
            <Col key={i} xs={24} md={12} lg={8} xxl={6}>
              <SkeletonInput
                style={{
                  width: `${isMobile ? "300px" : "250px"}`,
                  height: "100px",
                }}
                active={true}
                size={`large`}
              />
            </Col>
          ))}
        {isSearching
          ? !isLoading &&
            searchResult.map((builder: BuilderObject, idx: number) => (
              <Link key={idx} to={`details/${builder.organizationId}`}>
                <ItemCard
                  key={builder.id}
                  title={builder.businessName}
                  subTitle={builder.businessAddress}
                  bottomRight={
                    allowAction("brands-write") ? (
                      <Link to={`details/${builder.organizationId}`}>
                        Detail
                      </Link>
                    ) : (
                      <></>
                    )
                  }
                />
              </Link>
            ))
          : !isLoading &&
            builders.map((builder, idx) => (
              <Link key={idx} to={`details/${builder.organizationId}`}>
                <ItemCard
                  key={builder.id}
                  title={builder.businessName}
                  subTitle={builder.businessAddress}
                  bottomRight={
                    allowAction("brands-write") ? (
                      <Link to={`details/${builder.organizationId}`}>
                        Detail
                      </Link>
                    ) : (
                      <></>
                    )
                  }
                />
              </Link>
            ))}
        {!isLoading && builders.length === 0 && (
          <Empty description="No builders found" />
        )}
      </div>
    </div>
  );
}
