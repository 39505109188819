import { Router } from "@reach/router";
import DashboardShared from "components/DashboardShared/DashboardShared";
import BuilderDetails from "./builder-details";
import { NewRequest } from "./NewRequest";
import { ActiveBuilders } from "./ActiveBuilders";
import { AllBuilders } from "./AllBuilders";
import { Credit } from "./Credit";
import History from "./orders";
import ContactSalesDetails from "./sales-report-details";
import Buy from "./buy";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useEffect } from "react";
import { updateBuilder } from "store/slices/builder";

export default function Builders(props: ReachPathProps) {
  const dispatch = useDispatch();
  const { builder } = useSelector((state: RootState) => state.builder);

  useEffect(() => {
    const _builder = JSON.parse(localStorage.getItem("builder")!);
    const _builderId = _builder?.id;
    if (!builder?.id && _builderId) {
      dispatch(updateBuilder(_builder));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const path = window.location.pathname.includes("details")
    ? "Builder detail"
    : "Builders";
  return (
    <DashboardShared title={path}>
      <Router>
        <NewRequest path="/" />
        <History path="orders/:id" />
        <ActiveBuilders path="active" />
        <AllBuilders path="all" />
        <BuilderDetails path="details/:id" />
        <Buy path="buy/*" />
        <Credit path="credit" />
        <ContactSalesDetails path="sales/:id" />
      </Router>
    </DashboardShared>
  );
}
