import React from "react";
import { useNavigate, useParams } from "@reach/router";
import useQuery from "hooks/useQuery";
import { CheckSquareOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";

const BASE_URL = process.env.REACT_APP_GODUMP_ADMIN;
function Tracking(props: ReachPathProps) {
  const { truckId } = useParams();
  const navigate = useNavigate();

  const deviceObjectId = useQuery().get("deviceObjectId");
  const currentTrip = useQuery().get("currentTrip");
  const numOfTrips = useQuery().get("numOfTrips");

  return (
    <div>
      <Button onClick={() => navigate(-1)}>
        <ArrowLeftOutlined /> Back
      </Button>
      <iframe
        frameBorder={0}
        width={"100%"}
        height={"100vh"}
        style={{ height: "100vh" }}
        src={`${BASE_URL}/trucks/tracking/${truckId}?deviceObjectId=${deviceObjectId}&currentTrip=${currentTrip}&numOfTrips=${numOfTrips}`}
      ></iframe>
    </div>
  );
}

export default Tracking;
