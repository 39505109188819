import { Button, Form, Input, message, Modal, Upload } from "antd";
import { useState } from "react";

import QuillTextArea from "components/QuillTextArea";
import useBlog from "hooks/useBlog";
import { ArrowUpOutlined } from "@ant-design/icons";
import uploadToS3 from "util/uploadToS3";
import requestErrorHandler from "util/requestErrorHandler";

const CreateBlogPost = ({
  showModal,
  setShowModal,
  callback,
}: {
  showModal: any;
  setShowModal: any;
  callback: () => void;
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { createBlogPost } = useBlog();

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    createBlogPost(values)
      .then(() => {
        message.success("Post created successfully");
        setShowModal(false);
        callback();
        form.resetFields();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setIsLoading(false));
    // setIsLoading(true);
  };

  return (
    <Modal open={showModal} onCancel={() => setShowModal(false)} footer={""}>
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <Form.Item
          data-testid="title"
          name="title"
          label="Title"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data-testid="content"
          name="content"
          label="Content"
          rules={[{ required: true }]}
        >
          {/* <DraftEditor /> */}
          <QuillTextArea />
        </Form.Item>
        <Form.Item
          data-testid="image"
          name="image"
          label="Upload Blog Image"
          valuePropName="file"
          getValueFromEvent={(e) => {
            const file = e.fileList[0];
            if (file?.status === "done") {
              return file.response;
            }
          }}
        >
          <Upload
            customRequest={uploadToS3}
            listType="picture"
            maxCount={1}
            accept="image/*"
          >
            <Button icon={<ArrowUpOutlined />}>
              Click Here to upload an image
            </Button>
            <br />
            <small>
              Image should not be greater than <b>20MB</b>
            </small>
          </Upload>
        </Form.Item>
        <Button
          data-testid="button"
          type="primary"
          htmlType="submit"
          block
          size="large"
          loading={isLoading}
        >
          Proceed
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateBlogPost;
