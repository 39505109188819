import { Table, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./request.module.css";
import classNames from "classnames";
import TopTab from "components/TopTab/TopTab";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";

const columns = [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text: string) => <span>{dayjs(text).format("DD MM YYYY")}</span>,
  },
  {
    title: "Reference",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Amount",
    dataIndex: "materialPrice",
    key: "materialPrice",
    render: (_: number, record: PaymentsPartner) => (
      <span>
        ₦
        {(record.supplierId !== null
          ? record.materialPrice
          : record.deliveryPrice
        ).toLocaleString()}
      </span>
    ),
  },
  {
    title: "Recepient",
    dataIndex: "supplier",
    key: "supplier.businessName",
    ellipsis: true,
    render: (_: BuilderObject, record: PaymentsPartner) => (
      <Tooltip
        title={
          (record.supplierId !== null
            ? record.supplier
            : record.logisticsPartner
          ).businessName
        }
      >
        {
          (record.supplierId !== null
            ? record.supplier
            : record.logisticsPartner
          ).businessName
        }
      </Tooltip>
    ),
  },
  {
    title: "Status",
    dataIndex: "isPaid",
    key: "isPaid",
    render: (text: boolean) => (text ? "Paid" : "Unpaid"),
  },
];

export default function History(props: ReachPathProps) {
  const [page] = useState(0);
  const [, setTotalpages] = useState(1);
  const [data, setData] = useState<PaymentsPartner[]>([]);
  const getPayments = useCallback(() => {
    axios
      .get(`paymentpartner/getpaid`, { params: { page } })
      .then(({ data: { data } }) => {
        setData(data.paymentPartners);
        setTotalpages(data.totalpages);
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }, [page]);
  useEffect(() => {
    getPayments();
  }, [getPayments]);
  return (
    <>
      <TopTab
        links={[
          { to: "../", name: "Pending payments" },
          { to: "", name: "History" },
        ]}
      />
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input placeholder="search" prefix={<SearchOutlined />} />
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table
          style={{ minWidth: "600px" }}
          rowClassName={styles["table-row"]}
          dataSource={data}
          columns={columns}
        />
      </div>
    </>
  );
}
