import {
  Button,
  message,
  Popconfirm,
  Space,
  Statistic,
  Table,
  Tooltip,
} from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import EditMoq from "components/EditMoq";
import { EditModal } from "components/MaterialPricing";
import axiosConfig from "config/axios";
import styles from "./moq.module.css";
import { useEffect, useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

const MoqTable = ({
  data,
  callback,
  materials,
  builderClassId,
}: {
  data: any;
  callback?: () => void;
  materials: Variant[] | any;
  builderClassId: string;
}) => {
  // const [price, setPrice] = useState<number>(variant.variantId);

  const [variants, setVariants] = useState<Variant[] | any>([]);
  const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [editModal, setEditModal] = useState<EditModal>({
    status: false,
    variantId: "",
    moq: "",
  });

  // const { elem, handleScroll } = useScroll();

  useEffect(() => {
    if (!materials) return setIsLoadingPrices(true);
    materials.map((material: any) =>
      setVariants((prev: any) => [...prev, ...material.variants])
    );
    setIsLoadingPrices(false);
  }, [materials]);

  // c6fa7bc7-f029-4d04-8c5b-c6c4924aedb5"

  const deleteVariant = async (builderClassId: string, variantId: string) => {
    setIsDeleting(true);
    try {
      await axiosConfig.post("builderclass/removevariant", {
        builderClassId,
        variantId,
      });
      message.success("Variant removed");
      callback && callback();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const columns = [
    {
      title: "DESCRIPTION",
      dataIndex: "variantId",
      key: "variantId",
      render: (text: string) => (
        <Space>
          <img
            src={
              variants &&
              variants.find((variant: any) => variant.id === text)?.variantImage
            }
            alt=""
            height={50}
            width={50}
          />
          {variants &&
            variants.find((variant: any) => variant.id === text)?.description}
        </Space>
      ),
    },
    {
      title: "MOQ",
      dataIndex: "variantId",
      key: "variantId",
      // ellipsis: true,
      render: (text: string) => {
        const renderText =
          variants &&
          variants.find((variant: Variant) => variant.id === text)?.minSalesQty;
        return (
          <Tooltip title={renderText}>
            <Statistic valueStyle={{ fontSize: "16px" }} value={renderText} />
          </Tooltip>
        );
      },
    },
    {
      title: "DYNAMIC MOQ",
      dataIndex: "minQty",
      key: "minQty",
      render: (text: string) => {
        return (
          <>
            <Space>
              <Statistic valueStyle={{ fontSize: "16px" }} value={text} />
            </Space>

            {/* <span style={{ textTransform: "lowercase" }}>{text}</span> */}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "minQty",
      key: "minQty",
      render: (text: string, record: any) => {
        return (
          <>
            <Space>
              <Button
                icon={<EditFilled />}
                // type="def"

                onClick={() =>
                  setEditModal({
                    moq: text,
                    status: true,
                    variantId: record.variantId,
                  })
                }
              ></Button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() =>
                  deleteVariant(builderClassId, record.variantId)
                }
                okButtonProps={{
                  loading: isDeleting,
                  danger: true,
                  size: "middle",
                }}
                cancelButtonProps={{ size: "middle" }}
              >
                <Button
                  icon={<DeleteFilled />}
                  // type="def"
                  danger
                ></Button>
              </Popconfirm>
            </Space>

            {/* <span style={{ textTransform: "lowercase" }}>{text}</span> */}
          </>
        );
      },
    },
  ];

  const editMoqVariant = () => {
    callback && callback();
  };

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <Table
        rowClassName={styles["table-row"]}
        columns={columns}
        dataSource={data}
        className={styles.table}
        loading={isLoadingPrices}
        size="small"
        style={{
          textTransform: "capitalize",
          fontSize: "0.2rem",
          minWidth: "600px",
        }}
      />
      <div key={editModal.moq}>
        <EditMoq
          setShowModal={setEditModal}
          showModal={editModal.status}
          variantId={editModal.variantId}
          currentMoq={editModal.moq ?? ""}
          builderClassId={builderClassId}
          variants={variants}
          callback={() => editMoqVariant()}
        />
      </div>
    </div>
  );
};

export default MoqTable;
