import {
  Typography,
  Button,
  Table,
  Space,
  message,
  Skeleton,
  Form,
  Input,
  Popconfirm,
  InputNumber,
  Tooltip,
  Upload,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./material.module.css";
import { useCallback, useContext, useEffect, useState } from "react";
import DeviceWidth from "context/MobileResizeContext";
import AddVariant from "pages/admin/materials/Modal/AddVariant";
import axiosConfig from "config/axios";
import EditMaterial from "./Modal/EditMaterial";
import useMaterials from "hooks/useMaterials";
import { UploadOutlined } from "@ant-design/icons";
import uploadToS3 from "util/uploadToS3";
import { navigate } from "@reach/router";
import EditVariant from "./Modal/EditVariant";
import useModules from "hooks/useModules";

const { Text, Title } = Typography;

interface Item {
  key: string;
  description: string;
  size: string;
  unitPrice: string;
  minSalesQty: string;
  total: string;
  variantImage: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? <InputNumber min={0} /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        dataIndex === "variantImage" ? (
          <Form.Item
            initialValue={dataIndex}
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please upload ${title}!`,
              },
            ]}
            valuePropName="file"
            getValueFromEvent={(e) => {
              const file = e.fileList[0];
              if (file?.status === "done") {
                return file.response;
              }
            }}
          >
            <Upload
              customRequest={uploadToS3}
              listType="picture"
              multiple={false}
              maxCount={1}
              accept="image/*"
            >
              <Button icon={<UploadOutlined />}> Image</Button>
              <br />
              <small>
                Image should not be greater than <b>20MB</b>
              </small>
            </Upload>
          </Form.Item>
        ) : (
          <Form.Item
            initialValue={record}
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        )
      ) : (
        children
      )}
    </td>
  );
};

const MaterialDetail = ({
  id,
  path,
  ...props
}: {
  path: string;
  id?: string;
}) => {
  // const variant: [] | any = [];
  const [form] = Form.useForm();
  const { deleteMaterial } = useMaterials();
  const isMobile = useContext(DeviceWidth);
  const [showModal, setShowModal] = useState<boolean>(false);
  // const [showEditModal, setShoweditModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showEditVariant, setShowEditVariant] = useState<boolean>(false);
  const [material, setMaterial] = useState<{} | any>();
  const [loading, setLoading] = useState<boolean>();
  const [selectedVariant, setSelectedVariant] = useState<Variant>();
  const [deleting, setDeleting] = useState<boolean>(false);

  const getMaterial = useCallback(() => {
    setLoading(true);
    axiosConfig.get(`material/${id}`).then(
      ({ data: { data } }) => {
        setMaterial(data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, [id]);

  const updateVariant = (key: any, newData: any) => {
    const { description, unitSize, minSalesQty, unitPrice, variantImage } =
      newData[0];
    axiosConfig
      .put(`variant/${key}`, {
        description,
        unitSize,
        unitPrice,
        minSalesQty,
        variantImage,
      })
      .then(
        () => {
          message.success({
            content: "Variant Updated",
          });
          getMaterial();
        },
        (error) => {
          message.error({
            content: "Error: could not update variant",
          });
        }
      );
  };
  useEffect(() => {
    Promise.all([getMaterial()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteMaterial = () => {
    deleteMaterial(id);
  };

  const handleDeleteVariant = async (id: string) => {
    setDeleting(true);
    await axiosConfig
      .delete(`variant/${id}`)
      .then(
        () => {
          message.success({
            content: "Variant deleted",
          });
          getMaterial();
        },
        (error) => {}
      )
      .finally(() => setDeleting(false));
  };

  const tableData: any = [];

  material &&
    material.variants.length > 0 &&
    material.variants.map((variant: any) =>
      tableData.push({
        key: variant.id,
        variantImage: variant.variantImage,
        description: variant.description,
        unitSize: variant.unitSize,
        unitPrice: variant.unitPrice,
        minSalesQty: variant.minSalesQty,
        sku: variant.sku,
      })
    );

  let totalminSalesQty = 0;

  material &&
    material.variants.map((v: any) => (totalminSalesQty += v.minSalesQty));

  const columns = [
    {
      title: "IMAGE",
      dataIndex: "variantImage",
      key: "variantImage",
      editable: true,
      render: (text: string) => (
        <div>
          {text ? (
            <img
              style={{ borderRadius: "10px" }}
              src={text}
              alt={text}
              width="50"
            />
          ) : (
            <p style={{ opacity: "0.5" }}> No image </p>
          )}
        </div>
      ),
    },
    {
      title: "VARIANT DESCRIPTION",
      dataIndex: "description",
      key: "description",
      editable: true,
    },
    {
      title: "SIZE",
      dataIndex: "unitSize",
      key: "unitSize",
      editable: true,
    },
    {
      title: "UNIT PRICE",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (price: number) => `₦${price}`,
      editable: true,
    },
    {
      title: "MOQ",
      dataIndex: "minSalesQty",
      key: "minSalesQty",
      editable: true,
    },
    {
      title: "ACTIVE SCHEDULE DAYS",
      dataIndex: "daysBeforeScheduleExpire",
      key: "daysBeforeScheduleExpire",
      editable: true,
    },
    {
      title: "",
      dataIndex: "total",
      key: "total",
      render: (text: string, record: any) => {
        const editable = isEditing(record);
        return (
          <Space style={{ display: "flex", justifyContent: "space-between" }}>
            {text}
            {editable ? (
              <span>
                <Button
                  style={{ padding: ".3rem" }}
                  type="link"
                  onClick={() => save(record.key)}
                >
                  Save
                </Button>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <Button style={{ padding: ".3rem" }} type="link" danger>
                    Cancel
                  </Button>
                </Popconfirm>
              </span>
            ) : (
              <Space>
                {selectedVariant?.id === record.id && (
                  <EditVariant
                    material={material}
                    materialId={id}
                    showModal={showEditVariant}
                    setShowModal={setShowEditVariant}
                    callback={() => getMaterial()}
                    initialValues={record}
                  />
                )}
                <Tooltip title="Edit variant">
                  <Button
                    loading={deleting}
                    style={{ padding: ".3rem" }}
                    type="default"
                    onClick={() => {
                      setShowEditVariant(true);
                      setSelectedVariant(record);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete variant">
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => handleDeleteVariant(record.id)}
                    okButtonProps={{
                      danger: true,
                    }}
                    cancelButtonProps={{ type: "link" }}
                  >
                    <Button style={{ padding: ".3rem" }} danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
          </Space>
        );
      },
    },
  ];
  const [data, setData] = useState(tableData);
  const [editingKey, setEditingKey] = useState("");

  const { allowAction } = useModules();

  const isEditing = (record: Item) => record.key === editingKey;

  // const edit = (record: Partial<Item> & { key: React.Key }) => {
  //   form.setFieldsValue({ name: "", age: "", address: "", ...record });
  //   setEditingKey(record.key);
  // };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setData(newData);
      updateVariant(key, newData);
      setEditingKey("");
    } catch (error) {}
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType:
          col.dataIndex === "unitPrice" || col.dataIndex === "unitSize"
            ? "number"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const date = new Date();

  const matDate =
    material && material?.createdAt.split("T")[0].split("-").reverse();
  const currentDate = [
    date.getDate(),
    date.getMonth() + 1,
    date.getFullYear(),
  ].map(String);

  const isToday = material && matDate.toString() === currentDate.toString();

  const getDate = (currentDate: any, matDate: any) => {
    switch (material && currentDate[0] - matDate[0]) {
      case 1:
        return "Yesterday";
      case 2:
      case 3:
        return `${currentDate[0] - matDate[0]} days ago`;
      default:
        return (
          material &&
          new Date(matDate[2], matDate[1] - 1, matDate[0])
            .toString()
            .split(" ")
            .splice(0, 4)
            .join(" ")
        );
    }
  };

  const displayDate = isToday ? "Today" : getDate(currentDate, matDate);

  return (
    <div className={styles["details-container"]}>
      <div className={`${styles.topbar} ${styles["details-topbar"]}`}>
        <div>
          <Button
            onClick={() => navigate("/admin/materials")}
            type="text"
            style={{ padding: 0 }}
          >
            Materials
          </Button>{" "}
          {material && (
            <span
              style={{ textTransform: "capitalize", opacity: ".5" }}
            >{`| ${material?.brand?.brandName}
          `}</span>
          )}
          {!material && (
            <span>
              <Skeleton.Input
                style={{ width: "100px", height: "25px", borderRadius: "10px" }}
                active={true}
                size={`large`}
              />
            </span>
          )}
        </div>
        {allowAction("materials-write") && (
          <Space className={styles["button-container"]}>
            <Button
              className={styles["edit-button"]}
              icon={<EditOutlined />}
              type="default"
              size="middle"
              onClick={() => setShowEditModal(true)}
            >
              Edit
            </Button>
            <Popconfirm
              okButtonProps={{ danger: true }}
              title="sure to delete?"
              onConfirm={handleDeleteMaterial}
            >
              <Button
                className={styles["delete-button"]}
                danger
                icon={<DeleteOutlined />}
                type="primary"
                size="middle"
              >
                Delete
              </Button>
            </Popconfirm>
            <EditMaterial
              showModal={showEditModal}
              setShowModal={setShowEditModal}
              id={id}
              callback={() => getMaterial()}
              material={material}
            />
          </Space>
        )}
      </div>
      <div className={styles["details-card-container"]}>
        {!material &&
          [1, 2, 3].map((i) => (
            <Skeleton.Input
              key={i}
              style={{
                width: `${isMobile ? "100%" : "280px"}`,
                height: "130px",
                borderRadius: "15px",
              }}
              active={true}
              size={`large`}
            />
          ))}
        {material && (
          <>
            <div className={styles["details-card"]}>
              <span>
                Material Brand
                <b>{material?.brand.brandName}</b>
              </span>
              <span>
                Material Category
                <b>{material?.category.name}</b>
              </span>
              <span>
                Unit of measurement
                <b>{material?.unitOfMeasure}</b>
              </span>
            </div>
            <div className={styles["details-card"]}>
              <span>
                <Text>Number of variants</Text>
                <b>{material?.variants.length}</b>
              </span>
              <span>
                <Text>Date created</Text>
                <b>{displayDate}</b>
              </span>
              {/* <span>
                <Text>TOTAL</Text>
                <b>{`${totalminSalesQty} `}</b>
              </span> */}
            </div>
            <div className={styles["details-card"]}>
              <img src={material?.imageUrl} alt="cement" height="100" />
            </div>
          </>
        )}
      </div>
      <div className={styles["main-body"]}>
        <div className={styles["body-topbar"]}>
          <Title style={{ textTransform: "capitalize", margin: 0 }} level={4}>
            {material && material?.category.name}
          </Title>
          {allowAction("materials-write") && (
            <Space>
              <Button
                onClick={() => setShowModal(true)}
                icon={<PlusOutlined />}
                type="primary"
                size="middle"
              >
                Add new Variant
              </Button>
            </Space>
          )}
          <AddVariant
            material={material}
            materialId={id}
            showModal={showModal}
            setShowModal={setShowModal}
            callback={() => getMaterial()}
          />
        </div>
        <div>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              size="middle"
              rowClassName={styles["table-row"]}
              pagination={false}
              columns={mergedColumns}
              dataSource={material?.variants}
              className={styles.table}
              loading={loading}
              style={{
                fontSize: "0.2rem",
                overflowX: "auto",
                textTransform: "capitalize",
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default MaterialDetail;
