import React, { useState } from "react";
import { Button, Form, Input, InputNumber, Modal } from "antd";
import axios from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";
type Props = {
  isModalOpen: boolean;
  getBuilderClasses: () => Promise<void>;
  onCancel: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export default function CreateBuilderClassModal({
  isModalOpen,
  getBuilderClasses,
  onCancel,
}: Props) {
  const [form] = Form.useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  async function handleSubmit() {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await axios.post("buildercredit/createclass", form.getFieldsValue());
      form.resetFields();
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }
  return (
    <Modal
      open={isModalOpen}
      afterClose={getBuilderClasses}
      centered
      title="Create builder account type"
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          rules={[{ required: true, message: "Cannot be empty." }]}
          name="className"
          label="Account class name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Enter a number" }]}
          name="creditAmount"
          label="Eligible credit amount"
        >
          <InputNumber className="w-100" min={1} />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isSubmitting}
            htmlType="submit"
            block
            type="primary"
            size="large"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
