import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Tag,
} from "antd";
import axios from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";

const { Option } = Select;

type Props = {
  isModalOpen: boolean;
  getTrucks: () => Promise<void>;
  onCancel: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export default function CreateTruckModal({
  isModalOpen,
  getTrucks,
  onCancel,
}: Props) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [current, setCurrent] = useState("");
  async function handleSubmit(values: any) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      // const truckType = form.getFieldValue("truckType");
      const truckTonnage = form
        .getFieldValue("truckTonnage")
        .map((value: string) => +value) as number[];
      await axios.post("truck", { ...values, truckTonnage });
      message.success("Truck created successfully");
      form.resetFields();
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  function tagRender(props: any) {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, marginBottom: 3 }}
      >
        {value} tonnes
      </Tag>
    );
  }
  return (
    <Modal
      open={isModalOpen}
      afterClose={getTrucks}
      centered
      title="Create a truck"
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          rules={[{ required: true, message: "Input the truck type" }]}
          name="truckType"
          label="Truck Type"
          data-testid="truck-type"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Input tonnage" }]}
          name="truckTonnage"
          label="Truck Tonnage"
          data-testid="truck-tonnage"
        >
          <Select
            mode="multiple"
            onSearch={(e) => {
              if (e.match(/^\d+$/)) {
                setCurrent(e);
              } else {
                setCurrent("");
              }
            }}
            tagRender={tagRender}
            onChange={() => {
              setCurrent("");
            }}
          >
            {current && <Option value={current}>{current} tonnes</Option>}
          </Select>
        </Form.Item>
        <Row align="bottom">
          <Col xs={12} md={6}>
            <Form.Item
              rules={[{ required: true, message: "Input the base price" }]}
              name="baseprice"
              label="Base Price"
              data-testid="base-price"
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              rules={[{ required: true, message: "Input the base price" }]}
              name="instantBasePrice"
              label="Instant Base Price"
              data-testid="instant-base-price"
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              rules={[{ required: true, message: "Input the base price" }]}
              name="oneWeekBasePrice"
              label="One-week Base Price"
              data-testid="one-week-base-price"
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              rules={[{ required: true, message: "Input the base price" }]}
              name="twoWeeksBasePrice"
              label="Two-Weeks Base Price"
              data-testid="two-week-base-price"
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row align="bottom">
          <Col xs={12} md={6}>
            <Form.Item
              rules={[{ required: true, message: "Input the mileage" }]}
              name="mileage"
              label="Mileage"
              data-testid="mileage"
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              rules={[{ required: true, message: "Input the mileage" }]}
              name="instantMileage"
              label="Instant Mileage"
              data-testid="instant-mileage"
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              rules={[{ required: true, message: "Input the mileage" }]}
              name="oneWeekMileage"
              label="One-week Mileage"
              data-testid="one-week-mileage"
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              rules={[{ required: true, message: "Input the mileage" }]}
              name="twoWeeksMileage"
              label="Two-weeks Mileage"
              data-testid="two-week-mileage"
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            loading={isSubmitting}
            htmlType="submit"
            block
            type="primary"
            size="large"
            data-testid="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
