import {
  Typography,
  Button,
  Space,
  message,
  Skeleton,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  CheckSquareOutlined,
  FileTextOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import styles from "./transactions.module.css";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import dayjs from "dayjs";
import OrderTracking from "components/OrderTracking";
import { navigate } from "@reach/router";
import axiosConfig from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";
import useModules from "hooks/useModules";
import { useNavigate } from "@reach/router";
import ReconcileQty from "components/ReconcileQty";

const { Text } = Typography;

const TransactionDetail = (props: ReachPathProps & { id?: string }) => {
  // const [form] = Form.useForm();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  // const [dspInfo, setDspInfo] = useState<{} | any>();
  const [truckDetails, setTruckDetails] = useState<TruckDetails>();

  const { allowAction } = useModules();
  const getTransactionInfo = useCallback(async () => {
    try {
      const {
        data: { transaction },
      } = await axios.get(`transaction/gettransactionbytransid/${props.id}`);
      setTransaction(transaction);
    } catch {
      message.error("Error fetching order info");
    } finally {
      setLoading(false);
    }
  }, [props.id]);

  // builder/record-qty

  const transactionId = transaction?.id;
  const archiveTransaction = async () => {
    setLoading(true);
    try {
      await axiosConfig.delete(
        `transaction/archive-invoice?transactionId=${transactionId}`
      );
      message.success("Transaction Sent to Archive");
      navigate(-1);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const getTruckDetails = useCallback(async () => {
    try {
      const {
        data: {
          data: { truck },
        },
      }: { data: { data: { truck: TruckDetails } } } = await axios.get(
        `go-dump/get-truck?goDumpTrackingId=${transaction?.goDumpTrackingId}`
      );

      setTruckDetails(truck);
    } catch (error) {
      requestErrorHandler(error);
    }
  }, [transaction]);

  useEffect(() => {
    if (transaction?.goDumpTrackingId) {
      getTruckDetails();
    }
  }, [transaction]);

  useEffect(() => {
    getTransactionInfo();
  }, [getTransactionInfo]);

  // const addMaterial = () => {
  //   setShowModal(false);
  // };

  if (loading || !transaction) {
    return (
      <div className={` ${styles["details-container"]}`}>
        <Skeleton.Input active />
      </div>
    );
  }

  return (
    <div className={` ${styles["details-container"]}`}>
      <Button
        icon={<ArrowLeftOutlined />}
        type="text"
        size="small"
        onClick={() => {
          navigate(-1);
        }}
        style={{
          marginBottom: 10,
        }}
      >
        Back
      </Button>
      <div className={`${styles.topbar} ${styles["details-topbar"]}`}>
        {/* <div>
          <b>Transaction</b>
          <span style={{ opacity: ".5" }}> | {transaction.frontendId}</span>
        </div>
        <Space style={{ overflowX: "auto" }}>
          <Button icon={<CheckSquareOutlined />} type="link" size="small" onClick={() => setShowModal(true)}>
            Track order
          <span style={{ opacity: ".5" }}> | #342895</span>
        </div> */}
        {allowAction("transactions-write") && (
          <Space style={{ overflowX: "auto" }}>
            <Button
              icon={<CheckSquareOutlined />}
              type="link"
              size="small"
              onClick={() => setShowModal(true)}
            >
              TRACK ORDER
            </Button>
            {transaction?.goDumpTrackingId && (
              <Tooltip
                title={
                  !truckDetails?.deviceObjectId
                    ? "Unable to get tracking details"
                    : ""
                }
              >
                <Button
                  icon={<CheckSquareOutlined />}
                  type="link"
                  size="small"
                  onClick={() =>
                    navigate(
                      `/admin/transactions/live-tracking/${truckDetails?.id}?deviceObjectId=${truckDetails?.deviceObjectId}&currentTrip=${transaction?.currentTrip}&numOfTrips=${transaction?.numOfTrips}`
                    )
                  }
                >
                  LIVE TRACKING
                </Button>
              </Tooltip>
            )}

            {/* <ReconcileQty transaction={transaction} /> */}
            {/* <Button icon={<CheckSquareOutlined />} type="link" size="small">
            SET DELIVERY PAYMENT
          </Button> */}
            <Button hidden icon={<FileTextOutlined />} type="link" size="small">
              PREVIEW INVOICE
            </Button>
            <Popconfirm
              onConfirm={async () => {
                try {
                  await axios.post("builder/confrimdelivery", {
                    transactionId: transaction.id,
                  });
                  message.success("Order confirmed");
                } catch {
                  message.error("Error confirming order delivery");
                }
              }}
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<CheckSquareOutlined />} type="link" size="small">
                CONFIRM DELIVERY
              </Button>
            </Popconfirm>
            <Button
              icon={<CheckSquareOutlined />}
              type="link"
              size="small"
              onClick={archiveTransaction}
            >
              ARCHIVE TRANSACTION
            </Button>
          </Space>
        )}
        <OrderTracking
          transaction={transaction}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      </div>
      <div className={styles["details-card-container"]}>
        <div className={styles["details-card"]}>
          <span>
            Invoice ID
            <b>{transaction?.order?.invoiceNumber}</b>
          </span>
          <span>
            Order date
            <b>{dayjs(transaction.createdAt).format("DD MMM YYYY")}</b>
          </span>
          <span>
            Builder
            <b>{transaction.builder.businessName}</b>
          </span>
          <span>
            Payment status
            {transaction.order.isPaidFor ? (
              <b style={{ color: "#00962C" }}>Paid</b>
            ) : (
              <b style={{ color: "#FF612F" }}>Unpaid</b>
            )}
          </span>
          <span>
            Delivery status
            {transaction.trackingStatus === "delivered" ? (
              <b style={{ color: "#00962C" }}>Delivered</b>
            ) : (
              <b style={{ color: "#F2994A" }}>Pending</b>
            )}
          </span>
        </div>
        <div className={styles["details-card"]}>
          <span>
            <Text>Delivery option</Text>
            <b>Deliver to address</b>
          </span>
          <span>
            <Text>Delivery address</Text>
            <b>{transaction.deliveryAddress}</b>
          </span>
          {/* <span>
            <Text>State</Text>
            <b>Lagos, Nigeria</b>
          </span> */}
          <span>
            <Text>Supplier</Text>
            <b>{transaction.supplier.businessName}</b>
          </span>
          <span>
            <Text>Quantity</Text>
            <b>
              {transaction.quantity}{" "}
              {transaction.variant.material.unitOfMeasure}
            </b>
          </span>
          {transaction.deliveryPartner ? (
            <span>
              <Text>Delivery partner</Text>
              <b>Fastex delivery solution</b>
            </span>
          ) : (
            ""
          )}
          <span>
            <Text>Expected delivery date</Text>
            <b>{dayjs(transaction.deliveryDate).format("DD MM YYYY")}</b>
          </span>
        </div>
        {/* <div className={styles["details-card"]}>
          <span>
            <Text>DSP Name</Text>
            <b>{dspInfo?.businessName}</b>
          </span>
          <span>
            <Text>State</Text>
            <b>Lagos, Nigeria</b>
          </span> 
          <span>
            <Text>DSP Address</Text>
            <b>{dspInfo?.businessAddress}</b>
          </span>
          <span>
            <Text>Order Approve status</Text>
            <b>{dspInfo?.status} </b>
          </span>
        </div> */}
      </div>
      <div className={styles["main-body"]}>
        <div className={styles["profile-card"]}>
          <div className={styles.circle} />
          <div className={styles.circle} />
          <img
            src={transaction.variant.material.imageUrl}
            alt={`${transaction.variant.material.brand?.brandName} ${transaction.variant.material.category?.name} ${transaction.variant?.description}`}
            className={styles.avatar}
            height={200}
          />
          <div className={styles["profile-content"]}>
            <div className={styles.name}>
              <Typography.Title
                style={{ textTransform: "uppercase", margin: 0 }}
                level={3}
              >{`${transaction.variant.material.brand?.brandName} ${transaction.variant.material.category?.name} `}</Typography.Title>
              <Typography.Title
                style={{ textTransform: "uppercase", opacity: 0.5, margin: 0 }}
                level={4}
              >{`  ${transaction.variant.description}`}</Typography.Title>
            </div>
            <div className={styles.email}>
              <p></p>
            </div>
            <div className={styles.phoneNumber}>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetail;
