import { Link } from "@reach/router";
import {
  Button,
  Divider,
  Empty,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
} from "antd";
import styles from "./material.module.css";
import SkeletonInput from "antd/lib/skeleton/Input";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useContext, useState } from "react";
import DeviceWidth from "context/MobileResizeContext";
import EditCategory from "./Modal/EditCategory";
import useCategories from "hooks/useCategories";
import MaterialCard from "components/Cards/ItemCard/MaterialCard";

const { Text } = Typography;

interface materialProps {
  [materials: string]: any;
  category: any;
  isSearching?: boolean;
  searchItems?: any;
  isLoading: boolean;
  callback?: () => void;
}

const MaterialsSkeleton = () => {
  return (
    <div className={styles["material-container"]}>
      <Text className={styles["material-header"]}>{"cat.name"}</Text>
      <Divider style={{ margin: ".5rem" }} orientation="center" />
      <div className={styles["material-items"]}>
        <SkeletonInput
          style={{ width: 250, height: "80px" }}
          active={true}
          size={`large`}
        />
        <SkeletonInput
          style={{ width: 250, height: "80px" }}
          active={true}
          size={`large`}
        />
        <SkeletonInput
          style={{ width: 250, height: "80px" }}
          active={true}
          size={`large`}
        />
      </div>
    </div>
  );
};

const Material = ({
  materials,
  category,
  isSearching,
  searchItems,
  isLoading,
  callback,
}: materialProps) => {
  const isMobile = useContext(DeviceWidth);
  const { deleteCategory } = useCategories();
  // const [hasMaterial, setHasMaterial] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedCategory, setSelecetedCategory] = useState<any>();
  const [editCategoryId, setEditCategoryId] = useState<string>("");
  const handleDeleteCategory = async (name: string, id: any) => {
    await deleteCategory(id, callback);
  };

  !isLoading && <MaterialsSkeleton />;

  const MaterialItem = isLoading ? (
    <div className={styles["material-container"]}>
      <Text className={styles["material-header"]}>
        {" "}
        <SkeletonInput
          style={{ width: "100px", height: "25px", borderRadius: "10px" }}
          active={true}
          size={`large`}
        />
      </Text>
      <Divider style={{ margin: ".5rem" }} orientation="center" />
      <div className={styles["material-items"]}>
        {[1, 2, 3].map((i) => (
          <SkeletonInput
            key={i}
            style={{ width: "280px", height: "100px", borderRadius: "5px" }}
            active={true}
            size={`large`}
          />
        ))}
      </div>
    </div>
  ) : (
    category &&
    category.map((cat: any) => {
      return (
        <div key={cat.id} className={styles["material-container"]}>
          <div className={styles["material-header"]}>
            <Text>{cat.name}</Text>
            <Space>
              <Tooltip title={!isMobile && "Edit category"}>
                <Button
                  type="link"
                  onClick={() => {
                    setShowEditModal(true);
                    setEditCategoryId(cat.id);
                    setSelecetedCategory(cat);
                  }}
                >
                  <EditOutlined /> {isMobile ? "" : "Edit"}
                </Button>
              </Tooltip>
              <Tooltip title={!isMobile && "Delete category"}>
                <Popconfirm
                  title=" deleting this category will also delete all it's materials, sure to delete?"
                  okButtonProps={{
                    danger: true,
                  }}
                  onConfirm={() => handleDeleteCategory(cat.name, cat.id)}
                >
                  <Button type="link" danger>
                    <DeleteOutlined /> {isMobile ? "" : "Delete"}
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Space>
          </div>
          <Divider style={{ margin: ".5rem" }} orientation="center" />
          <div className={styles["material-items"]}>
            {materials &&
              materials.map((mat: any) => {
                return (
                  cat.id === mat.categoryId && (
                    <>
                      <div key={mat.id}>
                        <Link to={`material-detail/${mat.id}`}>
                          <MaterialCard
                            image={mat.imageUrl}
                            title={mat.brand.brandName}
                            subTitle={mat.category.name}
                            topRight={
                              mat.variants.length > 1
                                ? `${mat.variants.length} variants`
                                : `${mat.variants.length} variant`
                            }
                            // bottomRight={
                            //   window.location.pathname.includes("suppliers")
                            //     ? "250 basg left"
                            //     : ""
                            // }
                          />
                        </Link>
                      </div>
                    </>
                  )
                );
              })}
          </div>
        </div>
      );
    })
  );

  const searchResult = (
    <div className={styles["material-container"]}>
      <div className={styles["material-items"]}>
        {searchItems &&
          searchItems.map((mat: any) => {
            return (
              <>
                {mat.category && (
                  <div key={mat.id}>
                    <Link to={`material-detail/${mat.id}`}>
                      <MaterialCard
                        image={mat.imageUrl}
                        title={mat.brand.brandName}
                        subTitle={mat.category.name}
                        topRight={
                          mat.variants.length > 1
                            ? `${mat.variants.length} variants`
                            : `${mat.variants.length} variant`
                        }
                      />
                    </Link>
                  </div>
                )}
              </>
            );
          })}
        {!searchItems && <Empty />}
      </div>
    </div>
  );

  return (
    <>
      {isSearching ? searchResult : MaterialItem}{" "}
      <div key={editCategoryId}>
        <EditCategory
          showModal={showEditModal}
          setShowModal={() => setShowEditModal(false)}
          id={editCategoryId}
          callback={() => callback && callback()}
          selectedCategory={selectedCategory}
          afterClose={() => {
            setSelecetedCategory({});
          }}
        />
      </div>
    </>
  );
};

export default Material;
