import { Link } from "@reach/router";
import { Col, Divider, Empty, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ItemCard from "components/Cards/ItemCard";
import DeviceWidth from "context/MobileResizeContext";
import { useContext, useState, useCallback, useEffect } from "react";
import styles from "./supplier.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import axios from "config/axios";
import SkeletonInput from "antd/lib/skeleton/Input";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";
import useModules from "hooks/useModules";

export function NewRequest(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [isLoading, setIsloading] = useState(false);
  const [suppliers, setSuppliers] = useState<BuilderObject[]>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();
  const { allowAction } = useModules();
  const getPendingsuppliers = useCallback(async () => {
    setIsloading(true);
    try {
      const {
        data: { data },
      } = await axios.get("supplier/getallpendingsuppliers");
      setSuppliers(
        data.allPendingSuppliers.map((supplier: any) => {
          const s = supplier.supplier[0];
          return { ...s, id: supplier.id };
        })
      );
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsloading(false);
    }
  }, []);

  let searchResult = suppliers.filter((supplier) => {
    return (
      supplier.businessName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      supplier.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      supplier.businessAddress
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  useEffect(() => {
    getPendingsuppliers();
  }, [getPendingsuppliers]);

  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "", name: "New Requests" },
          { to: "all", name: "All Suppliers" },
          { to: "active", name: "Active Suppliers" },
          { to: "sales", name: "Sales" },
        ]}
      />
      <div className={classNames([styles.topbar, "c-end"])}>
        <Input
          prefix={<SearchOutlined />}
          placeholder="search"
          onChange={(e) => handleSearch(e)}
          allowClear
          size="middle"
          style={{ width: isMobile ? "100%" : "350px", paddingRight: "1rem" }}
        />
      </div>
      <Divider />
      <div className={styles["request-container"]}>
        {isLoading &&
          [1, 2, 3, 4, 5].map((i) => (
            <Col key={i} xs={24} md={12} lg={8} xxl={6}>
              <SkeletonInput
                style={{ width: 250, height: "100px" }}
                active={true}
                size={`large`}
              />
            </Col>
          ))}
        {isSearching
          ? !isLoading &&
            searchResult.map((supplier) => (
              <ItemCard
                key={supplier.id}
                title={supplier.businessName}
                subTitle={supplier.businessAddress}
                bottomRight={
                  allowAction("suppliers-write") ? (
                    <Link to={`details/${supplier.id}`}>Detail</Link>
                  ) : (
                    <></>
                  )
                }
              />
            ))
          : !isLoading &&
            suppliers.map((supplier) => (
              <ItemCard
                key={supplier.id}
                title={supplier.businessName}
                subTitle={supplier.businessAddress}
                bottomRight={
                  allowAction("suppliers-write") ? (
                    <Link to={`details/${supplier.id}`}>Detail</Link>
                  ) : (
                    <></>
                  )
                }
              />
            ))}
        {!isLoading && !suppliers.length && (
          <Empty description="No suppliers found" />
        )}
      </div>
    </div>
  );
}
