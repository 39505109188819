import { InputNumber, Space, message } from "antd";
import styles from "./cart.module.css";
import { useState } from "react";
import { debounce } from "lodash";
import axiosConfig from "config/axios";
// import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import useDynamicPrice from "hooks/useDynamicPrice";
import useMoqClass from "hooks/useMoqClass";
import { VariantCard } from "components/Cards/CategoryCardV2";
import requestErrorHandler from "util/requestErrorHandler";

// interface props {
//   selectedItems: { name: string; qty: number; image: string; rating: number };
// }

const SummaryItem = ({
  item,
  getCart,
  isQuotation,
  exceedQty,
  isUpdatingCart,
  setIsUpdatingCart,
}: {
  item?: [];
  getCart: () => void;
  isQuotation?: boolean;
  exceedQty?: number;
  isUpdatingCart?: boolean;
  setIsUpdatingCart: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <>
      {item &&
        item.map((cartItem: Cart) => (
          <div key={cartItem.variantId} className={styles["cart-item"]}>
            <OrderItem
              exceedQty={exceedQty}
              isQuotation={isQuotation}
              item={cartItem}
              getCart={getCart}
              isUpdatingCart={isUpdatingCart}
              setIsUpdatingCart={setIsUpdatingCart}
            />
          </div>
        ))}
    </>
  );

  // return (
  //   <>
  //     {item.quotation_details.map((d) => (
  //       <div key={d.id} className={styles["cart-item"]}>
  //         <QuotationItem
  //           d={d}
  //           item={item}
  //           handleRemove={handleRemove}
  //           getCart={getCart}
  //           length={item.quotation_details.length}
  //           tid={item.id}
  //         />
  //       </div>
  //     ))}
  //   </>
  // );
};

export default SummaryItem;

// function QuotationItem({
//   d,
//   item,
//   handleRemove,
//   getCart,
//   length,
//   tid,
// }: {
//   d: any;
//   item: any;
//   handleRemove: any;
//   getCart: any;
//   length: number;
//   tid: string;
// }) {
//   const [input, setInput] = useState(() => d.quantity);

//   const update = debounce(async (id: string, quantity: number) => {
//     try {
//       const { data } = await axios.put(`quotation/detail/${id}`, { quantity });
//       setInput(data.data.quotationDetail.quantity);
//       getCart();
//     } catch (error) {
//       message.error(`Quotation could not be updated`);
//     }
//   }, 2000);

//   return (
//     <div className={styles["item-content"]}>
//       <div className={styles["cart-top"]}>
//         <div>
//           <img
//             src={item.variant?.material?.imageUrl}
//             alt={item.variant.description}
//             height={80}
//           />
//         </div>
//         <div style={{ fontWeight: "bold" }}>
//           <h5>
//             {item.variant.material.brand.brandName}{" "}
//             {item.variant.material.category.name}
//           </h5>
//           <h5>{item.variant.description}</h5>
//         </div>
//       </div>
//       <div className={styles["cart-content"]}>
//         <div>
//           <div style={{ color: "#00962C", fontSize: 18 }}>
//             ₦{(input * item.variant.unitPrice).toLocaleString()}
//           </div>
//           ₦{item.variant.unitPrice.toLocaleString()} per{" "}
//           {item.variant.material?.unitOfMeasure}
//         </div>
//         <div className={styles["cart-footer"]}>
//           <div>
//             {/* <Button type="ghost" onClick={() => update(d.id, input - 1)}>
//               -
//             </Button> */}
//             <Input
//               type="number"
//               onBlur={(e) => update(d.id, +e.target.value)}
//               value={input}
//               onChange={(e) =>
//                 +e.target.value < 1 ? null : setInput(e.target.value)
//               }
//               min={1}
//             />
//             {/* <Button type="ghost" onClick={() => update(d.id, input + 1)}>
//               +
//             </Button> */}
//           </div>
//           <Button
//             onClick={() => handleRemove("quotation", d.id, length, tid)}
//             danger
//             type="primary"
//             icon={<DeleteFilled />}
//           >
//             Remove
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// }

export interface OrderItemProps {
  item: Cart;
  getCart: any;
  isQuotation?: boolean;
  exceedQty?: number;
  isUpdatingCart?: boolean;
  setIsUpdatingCart: React.Dispatch<React.SetStateAction<boolean>>;
}
export function OrderItem({
  item,
  getCart,
  exceedQty,
  isUpdatingCart,
  setIsUpdatingCart,
}: OrderItemProps) {
  let builder: Builder | any = {};
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const { getMoqclass } = useMoqClass();

  const itemName = `${item.variant.material.brand?.brandName} ${item.variant.material.category?.name}`;

  useEffect(() => {
    getMoqclass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cartId = item.id;

  const builderId = builder.id;
  const { getDynamicPrice } = useDynamicPrice(builderId);

  useEffect(() => {
    getDynamicPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleRemove(id: string, name: string) {
    setIsRemoving(true);
    try {
      await axiosConfig.delete(`cart/${id}`);
      message.success({
        content: (
          <>
            <b style={{ textTransform: "capitalize" }}>{name}</b> deleted from
            Cart
          </>
        ),
      });
      getCart();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsRemoving(false);
    }
  }

  // const mainVariant =
  //   moqClass?.minimumQuantity.find((q) => q.variantId === item.variantId)
  //     ?.minQty ?? item.variant.minSalesQty;

  // const mainVariant = item.variant.minSalesQty;

  return (
    <VariantCard
      type="cart"
      imageUrl={item.variant?.material?.imageUrl}
      line1={
        <Space>
          <h3>{item.variant.material.category?.name}:</h3>
          <p>{` ${item.variant?.description}`}</p>
        </Space>
      }
      line2={<p>₦{item?.variant.unitPrice.toLocaleString()} per ton</p>}
      line3={
        <Space>
          <QuantityInput
            cartId={cartId}
            getCart={getCart}
            item={item}
            setIsUpdatingCart={setIsUpdatingCart}
            exceedQty={exceedQty}
            isUpdatingCart={isUpdatingCart}
            itemName={itemName}
          />
          <p>₦{item?.price.toLocaleString()}</p>
        </Space>
      }
      removeProps={{
        onClick: () => handleRemove(item.id, itemName),
        loading: isRemoving,
      }}
    />
  );
}

const QuantityInput = ({
  isUpdatingCart,
  setIsUpdatingCart,
  item,
  getCart,
  cartId,
  exceedQty,
}: OrderItemProps & { cartId: string; itemName: string }) => {
  const [input, setInput] = useState(() => item.amount);
  const { mainVariant } = useMoqClass();

  const itemName = `${item.variant.material.brand?.brandName} ${item.variant.material.category?.name}`;

  const update = async (name: string, value: any) => {
    setIsUpdatingCart(true);
    await axiosConfig
      .put("cart/update", {
        cartId,
        totalQty: value,
      })
      .then(() => {
        message.success({
          content: (
            <>
              <b style={{ textTransform: "capitalize" }}> {name}</b> updated
            </>
          ),
        });
        getCart();
        setIsUpdatingCart(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
        setIsUpdatingCart(false);
      });
  };

  useEffect(() => {
    if (exceedQty) {
      axiosConfig
        .put("cart/update", {
          cartId,
          totalQty: input,
        })
        .then(() => {
          message.success({
            content: (
              <>
                <b style={{ textTransform: "capitalize" }}> {itemName}</b>{" "}
                updated
              </>
            ),
          });
          getCart();
          setIsUpdatingCart(false);
        })
        .catch((error) => {
          requestErrorHandler(error);
          setIsUpdatingCart(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exceedQty, input, itemName, getCart, cartId]);

  return (
    <InputNumber
      data-testid="cart-item-quantity-input"
      disabled={isUpdatingCart || window.location.pathname.includes("billing")}
      min={mainVariant(item.variantId, item.variant.minSalesQty)}
      type="number"
      defaultValue={item.totalQty}
      size="middle"
      onChange={debounce(async (value) => {
        if (+value < mainVariant(item.variantId, item.variant.minSalesQty)) {
          message.error("Value cannot be less than Minimum Order Quantity");
          return null;
        }
        setInput(value.toString());

        update(
          `${item.variant.material.brand?.brandName} ${item.variant.material.category?.name}`,
          value.toString()
        );
      }, 2000)}
    />
  );
};
