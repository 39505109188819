import { Link } from "@reach/router";
import { Col, Divider, Empty, Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ItemCard from "components/Cards/ItemCard";
import DeviceWidth from "context/MobileResizeContext";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./transporters.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import axios from "config/axios";
import SkeletonInput from "antd/lib/skeleton/Input";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";

export function NewRequest(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [isLoading, setIsloading] = useState(false);
  const [transporters, setTransporters] = useState<BuilderObject[]>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();

  let searchResult = transporters.filter((transporter) => {
    return (
      transporter.businessName
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transporter.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transporter.businessAddress
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const getPendingTranspoerters = useCallback(async () => {
    setIsloading(true);
    try {
      const {
        data: { data },
      } = await axios.get("logistics/getallpendingpartners");
      setTransporters(
        data.allPendingPartners.map(
          (logisticsPartner: any) => logisticsPartner.logisticsPartner[0]
        )
      );
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsloading(false);
    }
  }, []);

  useEffect(() => {
    getPendingTranspoerters();
  }, [getPendingTranspoerters]);
  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "", name: "New Requests" },
          { to: "all", name: "All DSPs" },
          { to: "active", name: "Active DSPs" },
          { to: "sales", name: "Sales" },
        ]}
      />
      <div className={classNames([styles.topbar, "c-end"])}>
        <Input
          prefix={<SearchOutlined />}
          placeholder="search"
          onChange={(e) => handleSearch(e)}
          allowClear
          size="middle"
          style={{ width: isMobile ? "100%" : "350px", paddingRight: "1rem" }}
        />
      </div>
      <Divider />
      <div className={styles["request-container"]}>
        {isLoading &&
          [1, 2, 3, 4, 5].map((i) => (
            <Col key={i} xs={24} md={12} lg={8} xxl={6}>
              <SkeletonInput
                style={{ width: 250, height: "100px" }}
                active={true}
                size={`large`}
              />
            </Col>
          ))}
        {isSearching
          ? !isLoading &&
            searchResult.map((builder: BuilderObject) => (
              <ItemCard
                key={builder.id}
                title={builder.businessName}
                subTitle={builder.businessAddress}
                bottomRight={
                  <Link to={`details/${builder.organizationId}`}>Detail</Link>
                }
              />
            ))
          : !isLoading &&
            transporters.map((builder: BuilderObject) => (
              <ItemCard
                key={builder.id}
                title={builder.businessName}
                subTitle={builder.businessAddress}
                bottomRight={
                  <Link to={`details/${builder.organizationId}`}>Detail</Link>
                }
              />
            ))}
        {!isLoading && transporters.length === 0 && (
          <Empty description="No pending DSPs" />
        )}
      </div>
    </div>
  );
}
