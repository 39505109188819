import { Input, Select, Button, Row, Col } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./brands.module.css";
import BrandBox from "components/BrandBox/BrandBox";
import { useContext, useEffect, useState } from "react";
import EditBrandModal from "components/EditBrandModal/EditBrandModal";
import CreateBrandModal from "components/CreateBrandModal/CreateBrandModal";
import axios from "config/axios";
import SkeletonInput from "antd/lib/skeleton/Input";
import DeviceWidth from "context/MobileResizeContext";
import useSearch from "hooks/useSearch";
import useModules from "hooks/useModules";
// const { Option } = Select;

export function Index(props: ReachPathProps) {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useContext(DeviceWidth);

  // calling the hook
  const { handleSearch, isSearching, searchQuery } = useSearch();

  // The filter handler
  let searchResult = brands.filter((brand: Brand) => {
    return (
      brand.brandName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  // Hooking it up to an actually Event

  async function getBrands() {
    setLoading(true);
    try {
      const {
        data: {
          data: { brands },
        },
      } = await axios.get("getbrands", { params: { searcheditem: "" } });
      setBrands(brands);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getBrands();
  }, []);
  const [modalBrand, setModalBrand] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const { allowAction } = useModules();
  return (
    <div className="p-1">
      <div className={styles.topactions}>
        <Input
          style={{ width: "auto" }}
          prefix={<SearchOutlined />}
          placeholder="Search"
          allowClear
          aria-label="Search"
          onChange={(e) => handleSearch(e)}
        />
        <Select placeholder="Filter"></Select>
        {allowAction("brands-write") && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setModalBrand(true)}
          >
            Add New Brand
          </Button>
        )}
      </div>
      <Col>
        <Row gutter={[16, 16]}>
          {loading &&
            [1, 2, 3].map((i, idx) => (
              <Col key={idx}>
                <SkeletonInput
                  style={{
                    width: `${isMobile ? "300px" : "250px"}`,
                    height: "100px",
                  }}
                  active={true}
                  size={`large`}
                />
              </Col>
            ))}
          {isSearching
            ? !loading &&
              searchResult.map((brand: Brand, index: number) => (
                <BrandBox
                  key={index}
                  {...brand}
                  setSelectedBrand={setSelectedBrand}
                  getBrands={getBrands}
                />
              ))
            : !loading &&
              brands.map((brand, index) => (
                <BrandBox
                  key={index}
                  {...brand}
                  setSelectedBrand={setSelectedBrand}
                  getBrands={getBrands}
                />
              ))}
        </Row>
      </Col>
      {allowAction("brands-write") && (
        <>
          <EditBrandModal
            setSelectedBrand={setSelectedBrand}
            selectedBrand={selectedBrand}
            getBrands={getBrands}
          />
          <CreateBrandModal
            getBrands={getBrands}
            createOpen={modalBrand}
            onCancel={() => {
              setModalBrand((s) => !s);
            }}
          />
        </>
      )}
    </div>
  );
}
