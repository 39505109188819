import { Modal, Form, Input, Button } from "antd";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import requestErrorHandler from "util/requestErrorHandler";

type Props = {
  isEditOpen: boolean;
  onCancel: () => void;
};

export default function EditProfileModal({ isEditOpen, onCancel }: Props) {
  const { user, logIn } = useAuth() as {
    user: User;
    logIn: (user: User) => void;
  };
  const [form] = Form.useForm<User>();

  async function handleSubmit() {
    const { firstName, lastName, phoneNumber } = form.getFieldsValue();
    try {
      const {
        data: { data },
      } = await axios.put("user", { firstName, lastName, phoneNumber });
      logIn(data);
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    }
  }
  return (
    <Modal
      footer={null}
      visible={isEditOpen}
      title="Edit Profile"
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={user}
      >
        <Form.Item
          rules={[{ required: true, message: "First Name cannot be blank" }]}
          name="firstName"
          label="First Name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Last Name cannot be blank" }]}
          name="lastName"
          label="Last Name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Phone Number cannot be blank" }]}
          name="phoneNumber"
          label="Phone Number"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" block type="primary" size="large">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
