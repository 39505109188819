import { Router } from "@reach/router";
import DashboardShared from "components/DashboardShared/DashboardShared";
import History from "./history";
import PaymentsView from "./payments-view";
import Recent from "./recent";
export default function Payments(props: ReachPathProps) {
  return (
    <DashboardShared title="Payments">
      <Router>
        <Recent path="/" />
        <History path=":id/history" />
        <PaymentsView path=":id" />
      </Router>
    </DashboardShared>
  );
}
