import { useState } from "react";

const useSearch = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "" || e.target.value.length < 1) {
      setIsSearching(false);
    }
    setSearchQuery(e.target.value);

    setIsSearching(true);
  };
  return {
    handleSearch,
    searchQuery,
    isSearching,
    setIsSearching,
  };
};

export default useSearch;
