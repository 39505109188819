const colors = [
  "#91f294",
  "#ffccdd",
  "#f7be7e",
  "#f799ef",
  "#b8f780",
  "#ef94eb",
  "#afffbd",
  "#79fcb4",
  "#77f98b",
  "#b3dbf9",
  "#ef77d3",
  "#d894ef",
  "#ef97e5",
  "#96f990",
  "#bef477",
  "#ffe0c1",
  "#749ced",
  "#89a5e5",
  "#7decf2",
  "#99d4ef",
  "#f49ab9",
  "#aef9a2",
  "#fce5c2",
  "#9a8cf2",
  "#fcb8c4",
  "#93f6ff",
  "#d4f497",
  "#ffa5c4",
  "#c1ed6a",
  "#89ffdf",
  "#85f0f2",
  "#82f299",
  "#ffed96",
  "#84f4ff",
  "#ceed78",
  "#d6fcab",
  "#f799d8",
  "#a7ffa5",
  "#f9bdde",
  "#ffccff",
  "#7384e2",
  "#ef7f82",
  "#fce2bf",
  "#88fcc2",
  "#f7c88a",
  "#fc88ab",
  "#a8ffda",
  "#8afcbf",
  "#f9cc7c",
  "#f779b6",
  "#8794f2",
  "#fcc9b3",
  "#fcb0a1",
  "#8af78f",
  "#a8ffda",
  "#a9e8f2",
  "#f4adda",
  "#cdf285",
  "#66cddd",
  "#ffc6d5",
  "#f4b8a4",
  "#c4f794",
  "#fcfa7e",
  "#f282a7",
  "#8de7ef",
  "#fcdec7",
  "#81ef93",
  "#ffafcf",
  "#90d4ed",
  "#fff4b7",
  "#ffa5e5",
  "#b897f4",
  "#b7ffb2",
  "#8c8bef",
  "#fcc2db",
  "#a3a9ed",
  "#c6ffff",
  "#f98beb",
  "#edf794",
  "#85c3f2",
  "#9ae7f4",
  "#fc957e",
  "#f2769d",
  "#f2ffa3",
  "#f7a0c7",
  "#f4a6c1",
  "#8ffca1",
  "#7ff498",
  "#bbd8f9",
  "#ffccd6",
  "#fca4c7",
  "#9eb0f7",
  "#81f49a",
  "#e5fc80",
  "#7ff4b8",
  "#fcc7e7",
  "#95daf9",
  "#6fed73",
  "#dca8f4",
  "#f49aa9",
];

export default colors;
