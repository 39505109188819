import { Input, message, Row, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./request.module.css";
import classNames from "classnames";
import ItemCard from "components/Cards/ItemCard";
import TopTab from "components/TopTab/TopTab";
import { useCallback, useEffect, useState } from "react";
import axiosConfig from "config/axios";
import SkeletonInput from "antd/lib/skeleton/Input";
import requestErrorHandler from "util/requestErrorHandler";
import useModules from "hooks/useModules";

const PaymentsView = ({ id, path }: { id?: string; path: string }) => {
  const [page] = useState(0);
  const [, setTotalpages] = useState(1);
  const [data, setData] = useState<[] | any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { allowAction } = useModules();

  const getPayments = useCallback(() => {
    axiosConfig
      .get(`paymentpartner/getunpaid`, { params: { page } })
      .then(({ data: { data } }) => {
        for (const [keys, values] of Object.entries(data.groupedEntries)) {
          keys.split("/")[0] === id && setData(values);
        }
        setTotalpages(data.totalpages);
        setIsLoading(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, id]);
  useEffect(() => {
    getPayments();
  }, [getPayments]);

  return (
    <>
      <TopTab
        links={[
          { to: "", name: "Pending payments" },
          { to: "history", name: "History" },
        ]}
      />
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input placeholder="search" prefix={<SearchOutlined />} />
        </div>
      </div>
      <div className={styles.container}>
        {isLoading && (
          <Row gutter={[16, 16]}>
            {[1, 2, 3].map((i) => (
              <SkeletonInput
                key={i}
                style={{
                  width: "280px",
                  height: "100px",
                  borderRadius: "5px",
                  marginRight: "1rem",
                }}
                active={true}
                size={`large`}
              />
            ))}
          </Row>
        )}
        <Row gutter={[16, 16]}>
          {!isLoading &&
            data.map((t: any, idx: number) =>
              t.supplierId !== null ? (
                <ItemCard
                  key={idx}
                  title={t.supplier.businessName}
                  // subTitle={t.transactioned.}
                  topRight={`₦${t.materialPrice.toLocaleString()}`}
                  bottomRight={
                    allowAction("payment-write") ? (
                      <Button
                        onClick={async () => {
                          try {
                            await axiosConfig.put(
                              `paymentpartner/markpaid/${t.id}`
                            );
                            message.success("Payment successful");
                            getPayments();
                          } catch (error) {
                            requestErrorHandler(error);
                          }
                        }}
                        type="link"
                        style={{ textTransform: "none" }}
                      >
                        Mark as paid
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                />
              ) : (
                <ItemCard
                  key={idx}
                  title={t.logisticsPartner.businessName}
                  // subTitle={t.transactioned.}
                  topRight={`₦${t.deliveryPrice.toLocaleString()}`}
                  bottomRight={
                    allowAction("payment-write") ? (
                      <Button
                        onClick={async () => {
                          try {
                            await axiosConfig.put(
                              `paymentpartner/markpaid/${t.id}`
                            );
                            message.success("Payment successful");
                            getPayments();
                          } catch (error) {
                            requestErrorHandler(error);
                          }
                        }}
                        style={{ textTransform: "none" }}
                        type="link"
                      >
                        Mark as paid
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                />
              )
            )}
        </Row>
      </div>
    </>
  );
};

export default PaymentsView;
