import { Router } from "@reach/router";
import DashboardShared from "components/DashboardShared/DashboardShared";
import SupplierDetails from "./supplier-details";
import { ActiveSuppliers } from "./ActiveSuppliers";
import { NewRequest } from "./NewRequest";
import { AllSuppliers } from "./AllSuppliers";
import { Sales } from "./Sales";
import ContactSalesDetails from "./sales-report-details";

export default function Builders(props: ReachPathProps) {
  const path = window.location.pathname.includes("details")
    ? "Supplier detail"
    : "Suppliers";
  return (
    <DashboardShared title={path}>
      <Router>
        <NewRequest path="/" />
        <ActiveSuppliers path="active" />
        <AllSuppliers path="all" />
        <SupplierDetails path="details/:id" />
        <Sales path="sales" />
        <ContactSalesDetails path="contact-sales-details/:id" />
      </Router>
    </DashboardShared>
  );
}
