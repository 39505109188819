import { Button, Collapse, message, Popconfirm, Space } from "antd";
import {
  PlusOutlined,
  CaretRightOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styles from "./settings.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import PageSpinner from "components/PageSpinner";
import CreateMoqClass from "components/CreateMoqClass";
import axiosConfig from "config/axios";
import CreateMoqVariant from "components/CreateMoqVariant copy";
import MoqTable from "components/MoqTable";
import useMaterials from "hooks/useMaterials";
import EditMoqClass from "components/EditMoqClass";
import DeviceWidth from "context/MobileResizeContext";
import requestErrorHandler from "util/requestErrorHandler";
import useModules from "hooks/useModules";

export async function getMoqClass(
  setMoqClass: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  setIsLoading(true);
  try {
    const {
      data: { builderClass },
    } = await axiosConfig.get("builderclass/getbuilderclass");
    setMoqClass(builderClass);
  } catch (error) {
  } finally {
    setIsLoading(false);
  }
}

export default function Moq(props: ReachPathProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [moqClass, setMoqClass] = useState<any[]>([]);
  const { materials, getMaterials } = useMaterials();

  // const [currentTruck, setCurrentTruck] = useState<TruckSetting | null>(null);

  const _getMoqClass = () => getMoqClass(setMoqClass, setIsLoading);
  useEffect(() => {
    _getMoqClass();
  }, []);
  return (
    <div className={styles["bank-container"]}>
      <div className="c-en">
        <Button
          size="large"
          type="primary"
          className=""
          icon={<PlusOutlined />}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          Create MOQ class
        </Button>
      </div>
      {isLoading ? (
        <div style={{ paddingTop: "10rem" }}>
          <PageSpinner />
        </div>
      ) : (
        moqClass &&
        moqClass.map((mclass, idx) => (
          <MoqItem
            key={idx}
            materials={materials}
            getMaterials={getMaterials}
            moqclass={mclass}
            getMoqClass={_getMoqClass}
            index={idx}
          />
        ))
      )}

      <CreateMoqClass
        isModalOpen={isModalOpen}
        callback={_getMoqClass}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      />
    </div>
  );
}

function MoqItem({
  moqclass,
  getMoqClass,
  materials,
  getMaterials,
  index,
}: {
  moqclass: MoqClass;
  getMoqClass: () => Promise<void>;
  setCurrentTruck?: (truck: TruckSetting) => void;
  materials: any;
  getMaterials: () => void;
  index: number;
}) {
  const { Panel } = Collapse;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const isMobile = useContext(DeviceWidth);
  const { allowAction } = useModules();
  // const [updatedEntity, setUpdatedEntity] = useState<number>(0);

  const moqRef = useRef<any>();

  const deleteClass = () => {
    if (isDeleting) return;
    setIsDeleting(true);
    axiosConfig
      .delete(`builderclass/deleteclass/${moqclass.id}`)
      .then((res) => {
        message.success(
          <>
            {" "}
            <b> {moqclass.title} </b> Class Deleted
          </>
        );
        getMoqClass();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setIsDeleting(false));
  };
  return (
    <div ref={moqRef} className={styles.moqlist}>
      <Collapse
        // bordered={false}

        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{ fontSize: "1.2rem", backgroundColor: "#fff" }}
            rotate={isActive ? -90 : 90}
          />
        )}
        className="site-collapse-custom-collapse"
        // style={{ width: "100%", display: "flex", alignItems: "center" }}
        accordion
      >
        <Panel
          style={{ backgroundColor: "#fff", width: "100%", textAlign: "left" }}
          header={
            <>
              <Space
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>
                  <h3 style={{ margin: 0, padding: 0, lineHeight: 1 }}>
                    {moqclass?.title}
                  </h3>
                  <small style={{ margin: 0, padding: 0 }}>
                    {moqclass?.description}
                  </small>
                </span>
                {allowAction("settings-write") && (
                  <div className="c-end">
                    <Button onClick={() => setShowEditModal(true)}>
                      <EditOutlined /> {isMobile ? "" : "Edit"}
                    </Button>
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={deleteClass}
                      okButtonProps={{
                        size: "middle",
                        danger: true,
                        loading: isDeleting,
                      }}
                      cancelButtonProps={{ size: "middle" }}
                    >
                      <Button danger>
                        <DeleteOutlined /> {isMobile ? "" : "Delete"}
                      </Button>
                    </Popconfirm>
                  </div>
                )}
              </Space>
            </>
          }
          key="1"
        >
          <div className="c-end">
            <Button
              icon={<PlusOutlined />}
              onClick={() => setShowModal(true)}
              type="primary"
            >
              Add Variant
            </Button>
          </div>

          <div>
            <MoqTable
              materials={materials}
              callback={getMoqClass}
              data={moqclass.minimumQuantity}
              builderClassId={moqclass.id}
            />
          </div>
        </Panel>
      </Collapse>
      <CreateMoqVariant
        materials={materials}
        getMaterials={getMaterials}
        builderClassId={moqclass.id}
        callback={getMoqClass}
        isModalOpen={showModal}
        onCancel={() => setShowModal(false)}
      />
      <EditMoqClass
        callback={getMoqClass}
        isModalOpen={showEditModal}
        moqclass={moqclass}
        onCancel={() => setShowEditModal(false)}
      />
    </div>
  );
}
