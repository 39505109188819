import { Router } from "@reach/router";
import DashboardShared from "components/DashboardShared/DashboardShared";
import TransporterDetails from "./transporter-details";
import { NewRequest } from "./NewRequest";
import { ActiveTransporters } from "./ActiveTransporters";
import { AllTransporters } from "./AllTransporters";
import { Sales } from "./Sales";
import ContactSalesDetails from "./sales-report-details";

export default function Transporters(props: ReachPathProps) {
  const path = window.location.pathname.includes("details")
    ? "DSP detail"
    : "DSPs";
  return (
    <DashboardShared title={path}>
      <Router>
        <NewRequest path="/" />
        <ActiveTransporters path="active" />
        <AllTransporters path="all" />
        <TransporterDetails path="details/:id" />
        <Sales path="sales" />
        <ContactSalesDetails path="contact-sales-details/:id" />
      </Router>
    </DashboardShared>
  );
}
