import { Table, Input, message, Tooltip, Statistic } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DeviceWidth from "context/MobileResizeContext";
import { useContext, useEffect, useState } from "react";
import styles from "./builders.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import axios from "config/axios";
import { navigate } from "@reach/router";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";

export function Credit(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [loading, setLoading] = useState<boolean>();
  const [builders, setBuilders] = useState<BuilderObject[]>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();

  useEffect(() => {
    setLoading(true);
    axios
      .get("builder/getallbuilders")
      .then(({ data }) => {
        setBuilders(
          data.data.allBuilders
            .filter(function (builder: any) {
              return builder.builder[0] !== undefined;
            })
            .map((builder: any) => {
              const tableObject = builder.builder[0];
              const user = builder.user[0] as User;
              tableObject.phoneNumber = user?.phoneNumber;
              tableObject.admin = `${user?.firstName} ${user?.lastName}`;
              return tableObject;
            })
        );
        setLoading(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setLoading(false));
  }, []);

  let searchResult = builders.filter((builder) => {
    return (
      builder.businessName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      builder.adminEmail.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      builder.phoneNumber.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      builder?.amountOwed?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const columns = [
    {
      title: "BUSINESS NAME",
      dataIndex: "businessName",
      key: "businessName",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },

    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "adminEmail",
      key: "adminEmail",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Amount Owed",
      dataIndex: "amountOwed",
      key: "amountOwed",
      render: (text: string) => (
        <div>
          {!text || text === null ? (
            <>Not owing</>
          ) : (
            <p>
              <Statistic
                valueStyle={{ fontSize: "0.9rem" }}
                value={`${text}`}
                prefix="₦"
              />
            </p>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "..", name: "New Requests" },
          { to: "../all", name: "All Builders" },
          { to: "../active", name: "Active Builders" },
          { to: "", name: "Builder Credit" },
        ]}
      />
      <div className={classNames([styles.topbar, "c-end"])}>
        <Input
          prefix={<SearchOutlined />}
          size="middle"
          onChange={(e) => handleSearch(e)}
          allowClear
          style={{ width: isMobile ? "100%" : "250px", paddingRight: "1rem" }}
        />
      </div>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table
          rowClassName={styles["table-row"]}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`details/${record.organizationId}`);
              },
            };
          }}
          columns={columns}
          dataSource={isSearching ? searchResult : builders}
          loading={loading}
          className={styles.table}
          size="middle"
          style={{
            fontSize: "0.2rem",
            minWidth: "600px",
            textTransform: "capitalize",
          }}
        />
      </div>
    </div>
  );
}
