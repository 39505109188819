import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Select } from "antd";
import axiosConfig from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";

type Props = {
  isModalOpen: boolean;
  callback: () => void;
  onCancel: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  builderClassId: string;
  materials: any[];
  getMaterials: () => void;
};
const { Option } = Select;
export default function CreateMoqVariant({
  isModalOpen,
  callback,
  onCancel,
  builderClassId,
  materials,
  getMaterials,
}: Props) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(values: any) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      // const truckType = form.getFieldValue("truckType");

      await axiosConfig.post("/builderclass/addvariant", { ...values });
      message.success("Variant added successfully");
      form.resetFields();
      callback();
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Modal
      visible={isModalOpen}
      centered
      title="Add variant to MOQ class"
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          initialValue={builderClassId}
          rules={[{ required: true, message: "Input the MOQ Class Id" }]}
          name="builderClassId"
          label="MOQ Class Title"
          hidden
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={`Select Variant`}
          name="variantId"
          rules={[{ required: true, message: "Please select a Variant" }]}
        >
          <Select>
            {materials.map((material: Variant["material"]) => (
              <>
                <Option
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    marginTop: " 1rem",
                  }}
                  key={material.category.name}
                  value={material.category.name}
                  disabled
                >
                  {material.category.name}
                </Option>
                {material?.variants?.map((variant: Variant) => (
                  <Option
                    style={{ textTransform: "capitalize" }}
                    key={variant.id}
                    value={variant.id}
                  >
                    {variant.description}
                  </Option>
                ))}
              </>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Input Dynamic MOQ" }]}
          label="Dynamic MOQ"
          name="minQty"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isSubmitting}
            htmlType="submit"
            block
            type="primary"
            size="large"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
