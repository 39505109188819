import { Button } from "antd";
import { useEffect, useState } from "react";
import styles from "./builders.module.css";
import useProject from "hooks/useProject";
import PageSpinner from "components/PageSpinner";
import AddTimeline from "components/AddTimeline";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { navigate } from "@reach/router";
import dayjs from "dayjs";
import useModules from "hooks/useModules";

export default function Timeline({
  id,
  ...props
}: ReachPathProps & { id?: string }) {
  const { getOneProject, loading, project } = useProject();
  const [showModal, setShowModal] = useState(false);
  const { allowAction } = useModules();

  useEffect(() => {
    getOneProject(id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <div>
      <AddTimeline
        projectId={id!}
        callback={() => getOneProject(id!)}
        isModalOpen={showModal}
        onCancel={() => setShowModal(false)}
      />
      <div className="s-between">
        <Button
          icon={<ArrowLeftOutlined />}
          type="text"
          size="small"
          onClick={() => {
            navigate(-1);
          }}
          style={{
            marginBottom: 10,
          }}
        >
          Back
        </Button>
      </div>
      {allowAction("Homeowners-write") && (
        <div className="center">
          <Button
            icon={<PlusOutlined />}
            size="large"
            type="primary"
            onClick={() => setShowModal(true)}
          >
            Add Event
          </Button>
        </div>
      )}
      <div>
        {project?.timeLineDetails?.map((event: any, idx: number) => (
          <TimelineItem key={idx} event={event} />
        ))}
      </div>
    </div>
  );
}

const TimelineItem = ({
  event,
}: {
  event: {
    description: string;
    photo: string;
    eventGenerator: "user" | "system";
    date: string;
  };
}) => {
  const { description, photo, eventGenerator, date } = event;

  return (
    <div className={styles["timeline-item"]}>
      <div className={styles["timeline-image"]}>
        <img src={photo} alt="" width={200} />
      </div>
      <div>
        <div>{dayjs(date).format("DD MMM YYYY")}</div>
        <b>Description</b>
        <p>{description}</p>
        <span
          className={
            eventGenerator.toLocaleLowerCase() === "user"
              ? styles["pill-user"]
              : styles["pill-system"]
          }
        >
          {" "}
          {eventGenerator}
        </span>
      </div>
    </div>
  );
};
