import { Button, Form, Input, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import AxiosBlogConfig from "util/axiosBlogConfig";
import requestErrorHandler from "util/requestErrorHandler";

const AddComment = ({ blogId, callback, showModal, setShowModal }: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (values: any) => {
    setLoading(true);
    AxiosBlogConfig.post("blogpost/addcomment", {
      ...values,
      blogId,
    })
      .then(() => {
        message.success("Comment created");
        callback();
        setShowModal(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      footer={false}
      visible={showModal}
      onCancel={() => setShowModal(false)}
    >
      <h3>Add Comment</h3>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="comment"
          label="Comment"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Button loading={loading} htmlType="submit" type="primary" size="large">
          Post Comment
        </Button>
      </Form>
    </Modal>
  );
};

export default AddComment;
