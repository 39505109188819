import {
  Button,
  Divider,
  Empty,
  message,
  Skeleton,
  Space,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import DeviceWidth from "context/MobileResizeContext";
import { CheckCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { useCallback, useContext } from "react";
import styles from "./transporters.module.css";
import { useEffect, useState } from "react";
import axios from "config/axios";
import { navigate, useMatch } from "@reach/router";
import FileViewer from "react-file-viewer";
import { saveAs } from "file-saver";
import Modal from "antd/lib/modal/Modal";
import EditEmailModal from "components/EditEmailModal";
import axiosConfig from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";
import useModules from "hooks/useModules";

const BusinessInfo = () => {
  const [dsp, setDSP] = useState<BuilderObject | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReqLoading, setIsReqLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>();
  const [rawData, setRawData] = useState<any>();
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [showEditEmail, setShowEditEmail] = useState<boolean>(false);
  const match = useMatch("/admin/transporters/details/:id") as {
    uri: string;
    path: string;
    id: string;
  };
  const approveBuilder = useCallback(
    async (logisticsId: string) => {
      if (isReqLoading) return;
      setIsReqLoading(true);
      try {
        const {
          data: { data },
        } = await axios.post("logistics/approve", { logisticsId });
        setDSP(data);
        message.success("Transporter successfully approved");
      } catch (error: any) {
        requestErrorHandler(error);
      } finally {
        setIsReqLoading(false);
      }
    },
    [isReqLoading]
  );

  const suspendBuilder = useCallback(
    async (logisticsId: string) => {
      if (isReqLoading) return;
      setIsReqLoading(true);
      try {
        const {
          data: { data },
        } = await axios.post("logistics/suspend", { logisticsId });
        setDSP(data);
        message.success("Transporter successfully suspended");
      } catch (error: any) {
        requestErrorHandler(error);
      } finally {
        setIsReqLoading(false);
      }
    },
    [isReqLoading]
  );

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`logistics/getonepartner/${match.id}`)
      .then(({ data }) => {
        setDSP(data.data[0].logisticsPartner[0]);
        setRawData(data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [match.id]);

  const file =
    "https://images.unsplash.com/photo-1649100902768-686052864828?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60";
  const type = "pdf";

  const SaveFile = () => {
    saveAs(file, `CACdocument.${type}`);
  };

  const logisticsId = dsp?.id;

  const editEmail = (values: any) => {
    if (values.email === dsp?.adminEmail)
      return message.error("Please enter a different email");
    setIsEditingEmail(true);
    axiosConfig
      .put("/admin/changeBuilderEmail", {
        logisticsId,
        ...values,
      })
      .then((data) => message.success("Builder Email Edited"))
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setIsEditingEmail(false));
  };

  const { allowAction } = useModules();

  const isApproved =
    !isLoading && dsp?.status === "approved" && allowAction("DSP-write");
  const isSuspended =
    !isLoading && dsp?.status === "suspended" && allowAction("DSP-write");
  const pendingApproval =
    !isLoading &&
    dsp?.status === "pending_approval" &&
    allowAction("DSP-write");

  return (
    <>
      <Space className={styles.cta}>
        {isLoading && (
          <>
            <Skeleton.Button />
            <Skeleton.Button />
          </>
        )}
        {isApproved && (
          <Button type="primary" onClick={() => suspendBuilder(dsp.id)} danger>
            Suspend account
          </Button>
        )}
        {isSuspended && (
          <Button type="primary" onClick={() => approveBuilder(dsp.id)}>
            Activate account
          </Button>
        )}
        {pendingApproval && (
          <>
            <Button
              type="primary"
              onClick={() => suspendBuilder(dsp.id)}
              danger
            >
              Reject account
            </Button>
            <Button type="primary" onClick={() => approveBuilder(dsp.id)}>
              <CheckCircleOutlined />
              Approve account
            </Button>
          </>
        )}
      </Space>
      <Divider />

      {isLoading && (
        <>
          <Skeleton.Input size="large" style={{ width: 1000, height: 350 }} />
        </>
      )}
      {!isLoading && Boolean(dsp) && (
        <>
          <div className={styles["details-card"]}>
            <span>
              Business Name
              <b>{dsp?.businessName}</b>
            </span>
            <span>
              Business Address
              <b>{dsp?.businessAddress}</b>
            </span>
            <span>
              Zip/postal code
              <b>{dsp?.zipCode}</b>
            </span>
            <span>
              State / Country
              <b>{dsp?.state}, Nigeria</b>
            </span>
            <span>
              Phone address
              <b>+234989768987</b>
            </span>
            <span>
              Email address
              <b style={{ textTransform: "lowercase" }}>
                {dsp?.adminEmail}{" "}
                {allowAction("DSP-write") && (
                  <Button onClick={() => setShowEditEmail(true)}>Edit</Button>
                )}
              </b>
            </span>
            <EditEmailModal
              showModal={showEditEmail}
              setSowModal={setShowEditEmail}
              onFinish={(e) => {
                editEmail(e);
              }}
              isEditingEmail={isEditingEmail}
              organizatino={dsp}
            />
          </div>

          <div className={styles["details-card"]}>
            <span>
              CAC Document
              {allowAction("DSP-write") && (
                <b>
                  <Button onClick={(e) => setShowModal(true)} type="default">
                    VIEW
                  </Button>
                </b>
              )}
            </span>
            <span>
              Tax ID
              <b>{dsp?.taxId ?? "Not uploaded"}</b>
            </span>
            <Modal
              footer={
                rawData?.cacDocument && allowAction("DSP-write") ? (
                  <Button onClick={SaveFile}>
                    {" "}
                    <DownloadOutlined /> Donwload Document
                  </Button>
                ) : (
                  ""
                )
              }
              visible={showModal}
              onCancel={() => setShowModal(false)}
            >
              {rawData?.cacDocument ? (
                <FileViewer fileType={type} filePath={rawData?.cacDocument} />
              ) : (
                <div>
                  <Empty description="No document uploaded yet" />
                </div>
              )}
            </Modal>
          </div>
        </>
      )}
    </>
  );
};
const Users = () => {
  // const isMobile = useContext(DeviceWidth);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  const match = useMatch("/admin/transporters/details/:id") as {
    uri: string;
    path: string;
    id: string;
  };

  const getUsers = useCallback(
    async function getUsers() {
      setLoading(true);
      try {
        const {
          data: {
            data: { organizationUsers },
          },
        } = await axios.get(`organization/${match.id}/users`);
        setUsers(organizationUsers);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [match.id]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);
  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "USER ROLE",
      dataIndex: "role",
      key: "role",
      render: (text: string) => (
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          {text}
          {/* <Button style={{ transform: "rotateZ(90deg)" }} type="text">
            <MoreOutlined />
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.table} style={{ overflowX: "auto" }}>
      <Table
        loading={loading}
        columns={columns}
        dataSource={users}
        className={styles.table}
        size="middle"
        style={{
          fontSize: "0.2rem",
          minWidth: "600px",
          textTransform: "capitalize",
        }}
        pagination={false}
      />
    </div>
  );
};
const MainView = (props: ReachPathProps) => {
  const { TabPane } = Tabs;
  const isMobile = useContext(DeviceWidth);
  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        <Button style={{ padding: 0 }} type="text" onClick={() => navigate(-1)}>
          DSP
        </Button>
      </div>
      <Tabs defaultActiveKey="1" type="card" size="large" centered={isMobile}>
        <TabPane tab="Business Info" key="1">
          <BusinessInfo />
        </TabPane>
        <TabPane tab="Users" key="2">
          <Users />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MainView;
