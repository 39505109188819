import { Col, Card, Image, Button, Row } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "config/axios";

const { Meta } = Card;

export default function BrandBox(
  props: Brand & {
    setSelectedBrand: (brand: Brand | null) => void;
    getBrands: () => Promise<void>;
  }
) {
  async function handleDelete() {
    try {
      await axios.post("deletebrand", { brandName: props.brandName });
      await props.getBrands();
    } catch (error) {}
  }
  return (
    <Col
      style={{ boxShadow: "0px 2px 5px #ddd", borderRadius: "10px" }}
      xs={24}
      md={12}
      lg={8}
      xxl={6}
    >
      <Card className="bg-white">
        <Meta
          avatar={
            <Image
              data-testid="img"
              width={60}
              // height={60}
              src={props.brandLogo}
            />
          }
          title={props.brandName}
          description={[
            <Row key={props.brandName}>
              <Button
                size="small"
                type="link"
                key="edit"
                style={{ padding: 0 }}
                onClick={() => {
                  props.setSelectedBrand(props);
                }}
              >
                <EditOutlined size={1} />
                Edit
              </Button>
              <Button
                size="small"
                type="link"
                key="delete"
                danger
                onClick={handleDelete}
              >
                <DeleteOutlined type="primary" /> Delete
              </Button>
            </Row>,
          ]}
        />
      </Card>
    </Col>
  );
}
