import dayjs from "dayjs";
import styles from "pages/admin/blog-backoffice/blog.module.css";
import ToText from "util/ToText";
import { navigate } from "@reach/router";
import BlogActions from "components/BlogActions";
import { useContext } from "react";
import DeviceWidth from "context/MobileResizeContext";
import useModules from "hooks/useModules";
// import { parse } from "rss-to-json";

export const BlogItem = ({
  post,
  path,
  getBlogPosts,
}: {
  post?: Post;
  path?: any;
  getBlogPosts: any;
}) => {
  const { allowAction } = useModules();
  const isMobile = useContext(DeviceWidth);
  // const handleConfirmClick = (e) => e.stopPropagation();
  const onMobile = isMobile && allowAction("blog-write");
  const onWeb = !isMobile && allowAction("blog-write");
  return (
    <div>
      <div className={styles["blog-item"]}>
        <section
          onClick={() => navigate(`blog-backoffice/${post?.id}`)}
          className={styles["blog-image"]}
          style={{ backgroundImage: `url(${post?.image})` }}
        ></section>
        <article className={styles["blog-text"]}>
          <div data-testid="blog-action" className={styles["blog-post-header"]}>
            <h2 data-testid="blog-title">{post?.title} </h2>
            {onWeb && <BlogActions post={post} getBlogPosts={getBlogPosts} />}
          </div>
          <span style={{ flex: 1 }} className={styles["blog-body"]}>
            {post?.content && ToText(post?.content).substring(0, 100)}...
          </span>
          <aside data-testid="blog-action" className={`${styles.footer} c-end`}>
            {onMobile && (
              <BlogActions post={post} getBlogPosts={getBlogPosts} />
            )}
            <small>
              {dayjs(post?.createdAt).format("DD MMM YYYY : HH mm ss")}
            </small>
          </aside>
        </article>
      </div>
    </div>
  );
};
