import React, { useState } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import axiosConfig from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";

type Props = {
  isModalOpen: boolean;
  callback: () => void;
  onCancel: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  moqclass: any;
};

export default function EditMoqClass({
  isModalOpen,
  callback,
  onCancel,
  moqclass,
}: Props) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  async function handleSubmit(values: any) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      // const truckType = form.getFieldValue("truckType");

      await axiosConfig.put("builderclass/editbuildclass", {
        builderClassId: values.id,
        ...values,
      });
      message.success("MOQ class Updated successfully");
      form.resetFields();
      callback();
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Modal
      visible={isModalOpen}
      centered
      title="Edit MOQ Class"
      onCancel={onCancel}
      footer={null}
    >
      <Form
        initialValues={moqclass}
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item
          rules={[{ required: true, message: "Input the MOQ Class Title" }]}
          name="id"
          label="MOQ Class Id"
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Input the MOQ Class Title" }]}
          name="title"
          label="MOQ Class Title"
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          rules={[{ required: true, message: "Input tonnage" }]}
          name="truckTonnage"
          label="Truck Tonnage"
        >
          <Select
            mode="multiple"
            onSearch={(e) => {
              if (e.match(/^\d+$/)) {
                setCurrent(e);
              } else {
                setCurrent("");
              }
            }}
            tagRender={tagRender}
            onChange={() => {
              setCurrent("");
            }}
          >
            {current && <Option value={current}>{current} tonnes</Option>}
          </Select>
        </Form.Item> */}
        <Form.Item
          rules={[{ required: true, message: "Input the description" }]}
          name="description"
          label="Description"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            loading={isSubmitting}
            htmlType="submit"
            block
            type="primary"
            size="large"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
