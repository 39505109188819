import { Col, Empty, Input, Row } from "antd";
import { SearchOutlined, CalendarFilled } from "@ant-design/icons";
import styles from "./request.module.css";
import classNames from "classnames";

import ItemCard from "components/Cards/ItemCard";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import { navigate } from "@reach/router";
import SkeletonInput from "antd/lib/skeleton/Input";
import requestErrorHandler from "util/requestErrorHandler";

export default function Recent(props: ReachPathProps) {
  const [page] = useState(0);
  const [, setTotalpages] = useState(1);

  const [months, setMonths] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getPayments = useCallback(() => {
    axios
      .get(`paymentpartner/getunpaid`, { params: { page } })
      .then(({ data: { data } }) => {
        setIsLoading(false);
        setMonths((prevMonth) =>
          Object.keys(data.groupedEntries).map((m) => m.split("/")[0])
        );
        setTotalpages(data.totalpages);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page]);
  useEffect(() => {
    getPayments();
  }, [getPayments]);

  // setMonth(Object.keys(month));

  if (!months) <>loading</>;

  const getMonth = (month: any) => {
    switch (month) {
      case "0":
        return "January";
      case "1":
        return "Februaury";
      case "2":
        return "March";
      case "3":
        return "April";
      case "4":
        return "May";
      case "5":
        return "June";
      case "6":
        return "July";
      case "7":
        return "August";
      case "8":
        return "Septemnber";
      case "9":
        return "October";
      case "10":
        return "November";
      case "11":
        return "December";
      default:
        return "";
    }
  };

  // const testObj = {
  //   x1: "val1",
  //   x2: "val2",
  //   x3: "val3",
  // };

  return (
    <>
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input placeholder="search" prefix={<SearchOutlined />} />
        </div>
      </div>
      {isLoading ? (
        <div className={styles.container}>
          <Row gutter={[16, 16]}>
            <SkeletonInput active style={{ width: "250px", height: "100px" }} />
          </Row>
        </div>
      ) : (
        <div className={styles.container}>
          <Row gutter={[16, 16]} justify="center">
            {months.length < 1 && (
              <Empty description="There is no payment yet" />
            )}
            {months.reverse().map((month, idx) => (
              <Col
                key={idx}
                xs={24}
                md={6}
                onClick={() => navigate(`payments/${month}`)}
              >
                <ItemCard
                  title={
                    <div>
                      <CalendarFilled /> {getMonth(month)}
                    </div>
                  }
                />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
}
