import { ReactNode } from "react";
import styles from "./styles.module.css";
import { DeleteFilled } from "@ant-design/icons";
import { Button } from "antd";

interface CCProps {
  cat: Category;
  onClick: () => void;
  outOfStock?: boolean;
}

interface VariantCardProps {
  onClick?: () => void;
  removeProps?: {
    onClick: () => void;
    loading: boolean;
  };
  imageUrl: string;
  line1: ReactNode;
  line2: ReactNode;
  line3: ReactNode;
  type?: "cart" | "variant";
}

export default function CategoryCardV2({ cat, onClick, outOfStock }: CCProps) {
  const { imageUrl, name } = cat;
  // const x = materials
  return (
    <div
      className={`${styles.card} ${outOfStock ? styles["out-of-stock"] : ""}`}
      onClick={onClick}
    >
      {outOfStock && <OutOfStockBadge />}
      <div className={styles.image}>
        <img src={imageUrl} alt={name} width="100%" />
      </div>
      <div className={styles.info}>
        <h2 className={styles.title}>{name}</h2>
        {/* <p>Brand & Variant: {inventory.length}</p>
        <p>Minimum Quantity: 30</p> */}
      </div>
    </div>
  );
}

// 007D25

const OutOfStockBadge = () => {
  return <div className={styles["out-of-stock-badge"]}>Coming soon</div>;
};

export function VariantCard({
  onClick,
  imageUrl,
  line1,
  line2,
  line3,
  type,
  removeProps,
}: VariantCardProps) {
  // const { imageUrl, name, materials } = cat;
  // const x = materials
  return (
    <div
      className={`${styles["variant-card"]} ${
        type === "cart" && styles["cart-card"]
      }`}
      onClick={onClick}
    >
      {type === "cart" && (
        <Button
          className={styles.delete}
          icon={<DeleteFilled />}
          type="link"
          danger
          {...removeProps}
        />
      )}
      <div className={styles.image}>
        <img src={imageUrl} alt={imageUrl} width="100%" />
      </div>
      <div className={styles.info}>
        <div className="capitalize">{line1}</div>
        <div>{line2}</div>
        <div className="primary-text">{line3}</div>
      </div>
    </div>
  );
}
