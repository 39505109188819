import {
  Input,
  Button,
  Form,
  Table,
  Menu,
  Modal,
  message,
  Tooltip,
  Checkbox,
  Row,
  Col,
} from "antd";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import styles from "../payments/request.module.css";
import classNames from "classnames";
import { Home, Package, Truck } from "react-feather";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";
import TopTab from "components/TopTab/TopTab";

const columns = [
  {
    title: "TOOLER NAME",
    dataIndex: "supplierName",
    key: "supplierName",
  },
  {
    title: "EMAIL ADDRESS",
    dataIndex: "email",
    key: "email",
    ellipsis: true,
    render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
  },
  {
    title: "PHONE NUMBER",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    render: (text: string) => <small> {text.replace("_", " ")} </small>,
  },
];

export default function SupplierTool(props: ReachPathProps) {
  const [toolers, setToolers] = useState<Tooler[]>([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { handleSearch, isSearching, searchQuery } = useSearch();
  let searchResult = toolers?.filter((user) => {
    return (
      user.supplierName?.toLowerCase().indexOf(searchQuery.toLowerCase()) ||
      user.email?.toLowerCase().indexOf(searchQuery.toLowerCase())
    );
  });

  const getToolers = useCallback(async function () {
    setLoading(true);
    try {
      const {
        data: { supplierToolAccounts },
      } = await axios.get(`suppliertoolaccount/get-supplier-tool-account`);
      setToolers(supplierToolAccounts);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getToolers();
  }, [getToolers]);

  const [form] = Form.useForm();

  return (
    <div>
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input
            onChange={(e) => handleSearch(e)}
            allowClear
            placeholder="search"
            prefix={<SearchOutlined />}
          />
        </div>

        <Button onClick={() => setShowModal(true)}>Add Tooler</Button>
      </div>
      <div className={styles.table} style={{ overflowX: "auto" }}>
        {isSearching ? (
          <Table
            size="small"
            rowClassName={() => "table-row"}
            dataSource={searchResult}
            columns={columns}
            loading={loading}
            style={{ minWidth: "600px", textTransform: "capitalize" }}
          />
        ) : (
          <Table
            size="small"
            rowClassName={() => "table-row"}
            dataSource={toolers}
            columns={columns}
            loading={loading}
            style={{ minWidth: "600px", textTransform: "capitalize" }}
          />
        )}
      </div>
      <Modal
        title="Add Admin"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={async (values) => {
            setIsSubmitting(true);
            await axios
              .post("suppliertoolaccount/register-supplier-tool", {
                ...values,
                email: values?.email?.toLowerCase(),
              })
              .then(() => {
                message.success("Supplier added successfully");
                getToolers();
                setShowModal(false);
              })
              .catch((err) => {
                requestErrorHandler(err);
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          }}
        >
          <Form.Item
            name="supplierName"
            rules={[
              {
                required: true,
                message: "Please enter Supplier's name",
              },
            ]}
            label="Supplier Name"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="address"
            rules={[
              {
                required: true,
                message: "Please enter Address",
              },
            ]}
            label="Address"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter Supplier's email address",
              },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            label="Supplier Email Address"
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please provide a valid phone number",
              },
              {
                pattern: /^(\+?234?)?0?(7|8|9)(0|1)\d{8}$/,
                message: "Please provide a valid phone number.",
              },
            ]}
            label="Admin Phone Number"
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button loading={isSubmitting} type="primary" htmlType="submit">
              Add Supplier
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
