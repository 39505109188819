import { Link } from "@reach/router";
import { Statistic } from "antd";
import styles from "./dashboard.module.css";
interface cardProps {
  statistics: number;
  color?: string;
  title?: string;
  payout?: boolean;
  route?: any;
  icon?: any;
}
const DashBoardCard = (props: cardProps) => {
  const prefix = props.payout ? "₦" : "";
  return (
    <Link to={props.route}>
      <div
        className={styles.card}
        style={{
          backgroundColor: `${props.color}22`,
          border: `1px solid ${props.color}`,
        }}
      >
        {/* <div className={styles.color} style={{ backgroundColor: props.color }} /> */}
        <div className={styles["card-details"]}>
          <div
            className={styles["card-image"]}
            style={{ backgroundColor: `${props.color}55` }}
          >
            <img src={props.icon} alt="card " height={35} />
          </div>
          <h4>{props.title}</h4>
        </div>

        <div className={styles.stat}>
          <Statistic
            valueStyle={{
              fontSize: `${props.payout ? "2rem" : "2rem"}`,
              zIndex: 111,
              fontWeight: 600,
              whiteSpace: "nowrap",
            }}
            value={props.statistics}
            prefix={prefix}
          />
        </div>
      </div>
    </Link>
  );
};

export default DashBoardCard;
