import {
  Typography,
  Button,
  Space,
  // Form,
  Modal,
  message,
  Skeleton,
  // Popconfirm,
  Statistic,
  Spin,
  Divider,
  Input,
  Collapse,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import styles from "./transactions.module.css";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import dayjs from "dayjs";
import axiosConfig from "config/axios";
import { useMatch } from "@reach/router";
import OrderTracking from "components/OrderTracking";
import { navigate } from "@reach/router";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

const { Text } = Typography;

const UnpaidDetails = (props: ReachPathProps & { id?: string }) => {
  const match = useMatch("/admin/homeowner-transaction/unpaid/:id") as {
    uri: string;
    path: string;
    id: string;
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [completingOrder, setCompletingOrder] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<Transaction | any>();
  const [amount, setAmount] = useState("0");
  const [amountShowModal, setAmountShowModal] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false);

  const getTransactionInfo = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`homeowner/home-owner-id?orderId=${match.id}`);
      setTransaction(data);
    } catch {
      message.error("Error fetching order info");
    } finally {
      setLoading(false);
    }
  }, [match.id]);

  const completeOrder = async () => {
    setCompletingOrder(true);
    try {
      await axiosConfig.post("homeowner/admin-mark-paid", {
        orderId: transaction.id,
        amount,
      });
      message.success("Payment confirmed");
      setOrderConfirmed(true);
      setAmountShowModal(false);
    } catch {
      message.error("Error confirming payment");
      setOrderConfirmed(false);
    } finally {
      setCompletingOrder(false);
    }
  };

  useEffect(() => {
    getTransactionInfo();
  }, [getTransactionInfo]);

  const addMaterial = () => {
    setShowModal(false);
  };

  if (loading || !transaction) {
    return (
      <div className={` ${styles["details-card-container"]}`}>
        <Skeleton.Input active />
        <Spin />
      </div>
    );
  }

  return (
    <div className={` ${styles["details-container"]}`}>
      <Button
        icon={<ArrowLeftOutlined />}
        type="text"
        size="small"
        onClick={() => {
          navigate(-1);
        }}
        style={{
          marginBottom: 10,
        }}
      >
        Back
      </Button>
      <div className={`${styles.topbar} ${styles["details-topbar"]}`}>
        <Space style={{ overflowX: "auto" }}>
          {/* <Button
            onClick={() => setAmountShowModal(true)}
            icon={<CheckSquareOutlined />}
            type="link"
            size="small"
            disabled={orderConfirmed}
          >
            {!orderConfirmed ? "CONFIRM PAYMENT" : "PAYMENT CONFIRMED"}
          </Button> */}

          <Modal
            className={styles.modal}
            title="Confirm Payment"
            onCancel={() => setAmountShowModal(false)}
            onOk={addMaterial}
            visible={amountShowModal}
            footer={false}
          >
            <label htmlFor="amount">
              Enter Amount ₦
              <Input id="amount" onChange={(e) => setAmount(e.target.value)} />
            </label>
            <Divider />
            <Button
              size="large"
              type="primary"
              style={{ width: "100%" }}
              onClick={completeOrder}
              loading={completingOrder}
            >
              SUBMIT
            </Button>
          </Modal>
        </Space>
        <OrderTracking
          transaction={transaction}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      </div>
      <div className={styles["details-card-container"]}>
        <div className={styles["details-card"]}>
          <span>
            Invoice ID
            <b>{transaction?.invoiceNumber}</b>
          </span>
          <span>
            Order date
            <b>{dayjs(transaction.createdAt).format("DD MMM YYYY")}</b>
          </span>
          <span>
            Homeowner
            <b>
              {transaction.homeOwner?.firstName}{" "}
              {transaction.homeOwner?.lastName}
            </b>
          </span>
        </div>
        <div className={styles["details-card"]}>
          <span>
            Order Approved
            <b>{transaction.orderStatus}</b>
          </span>

          {/* <span>
            Payment method
            <b>{transaction.paymentMethod}</b>
          </span> */}
          <span>
            Payment status
            {transaction.paymentStatus !== "notPaid" || orderConfirmed ? (
              <b style={{ color: "#00962C" }}>Paid</b>
            ) : (
              <b style={{ color: "#FF612F" }}>Unpaid</b>
            )}
          </span>
        </div>
      </div>
      {transaction.order_items.map((order: any, idx: number) => (
        <div key={idx} className={styles["main-body"]}>
          <div className={styles["profile-card"]}>
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles["profile--card-content"]}>
              <img
                src={order.variant.material.imageUrl}
                alt={`${order.variant.material.brand?.brandName} ${order.variant.material.category?.name} ${order.variant?.description}`}
                className={styles.avatar}
                height={200}
              />
              <div className={styles["profile-content"]}>
                <div className={styles.name}>
                  <h3
                    style={{ textTransform: "uppercase", margin: 0 }}
                  >{`${order.variant.material.brand?.brandName} ${order.variant.material.category?.name} `}</h3>
                  <h3
                    style={{
                      textTransform: "uppercase",
                      opacity: 0.5,
                      margin: 0,
                    }}
                  >{`  ${order.variant.description}`}</h3>
                </div>
                <Divider style={{ margin: "0.5rem 0" }} />
                <span className={styles.span}>
                  <Text>Material Price</Text>
                  <b>
                    {" "}
                    <Statistic prefix="₦" value={order.materialPrice} />{" "}
                  </b>
                </span>
                <span className={styles.span}>
                  <Text>Delivery Price</Text>
                  <b>
                    <Statistic prefix="₦" value={order.deliveryPrice} />{" "}
                  </b>
                </span>
                <span className={styles.span}>
                  <Text>Total Price</Text>
                  <b>
                    <Statistic prefix="₦" value={transaction?.totalPrice} />{" "}
                  </b>
                </span>
              </div>
            </div>
            <div>
              <Collapse>
                <CollapsePanel header={`Delivery Details`} key={0}>
                  <div className={styles["details-card-container"]}>
                    <div
                      className={styles["details-card"]}
                      style={{ backgroundColor: "#fff", width: "600px" }}
                    >
                      <span>
                        <Text>Quantity</Text>
                        <b>
                          {`${order?.amount} ${order.variant.material.unitOfMeasure}` ??
                            "--"}
                        </b>
                      </span>
                      <span>
                        <Text>Self Pick up</Text>
                        <b>{order?.isPickup.toString() ?? "--"}</b>
                      </span>
                      <span>
                        <Text>Delivery Address</Text>
                        <b>{order?.deliveryAddress ?? "--"}</b>
                      </span>
                      <span>
                        <Text>Delivery Date</Text>
                        <b>
                          {dayjs(order?.deliveryDate).format("DD MM YYYY") ??
                            "--"}
                        </b>
                      </span>
                      {/* <span>
                        <Text>Delivery distance</Text>
                        <b>{order?.distance ?? "--"}</b>
                      </span> */}
                      <span>
                        <Text>Material Price</Text>
                        <b>
                          <Statistic
                            prefix="₦"
                            value={order?.materialPrice ?? "--"}
                          />
                        </b>
                      </span>
                      <span>
                        <Text>Recipient Name</Text>
                        <b>{order?.recipientName ?? "--"}</b>
                      </span>
                      <span>
                        <Text>Phone</Text>
                        <b>{order?.phoneNumber ?? "--"}</b>
                      </span>
                    </div>
                  </div>
                </CollapsePanel>
              </Collapse>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UnpaidDetails;
