import { Modal, Form, Button, Select, message, Input } from "antd";
import axios from "config/axios";
import { useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

const { Option } = Select;
type Props = {
  isModalOpen: boolean;
  onCancel: () => void;
  id?: string;
  builder: BuilderObject;
  callback?: () => void;
};

export default function AddDiscount({
  isModalOpen,
  onCancel,
  id,
  callback,
  builder,
}: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await axios.post("go-dump/add-discount", {
        builderId: id,
        discount: values.discount,
      });
      message.success("Discount set successfully!");
      callback?.();
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      afterClose={() => {}}
      open={isModalOpen}
      centered
      title="Set Discount"
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          rules={[{ required: true, message: "Please add a discount" }]}
          name="discount"
          label="Discount"
          style={{ textTransform: "capitalize" }}
          initialValue={builder.logisticDiscount}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isLoading}
            htmlType="submit"
            block
            type="primary"
            size="large"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
