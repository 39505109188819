import { Button, message, Popconfirm, Space } from "antd";
import EditBlogPost from "components/BlogPost/EditBlogPost";
import { useState } from "react";
import AxiosBlogConfig from "util/axiosBlogConfig";
import requestErrorHandler from "util/requestErrorHandler";

// import { parse } from "rss-to-json";

const BlogActions = ({
  getBlogPosts,
  post,
}: {
  getBlogPosts: any;
  post?: Post;
}) => {
  const [deleting, setDeleting] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  // const { getBlogPosts } = useBlog();
  const handleDelete = () => {
    setDeleting(true);
    AxiosBlogConfig.delete(`blogpost/deletepost/${post?.id}`)
      .then(() => {
        message.success("Post deleted!");
        getBlogPosts();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setDeleting(false));
  };
  return (
    <div data-testid="blog-action">
      <Space>
        <Popconfirm
          okButtonProps={{
            danger: true,
            size: "large",
          }}
          cancelButtonProps={{
            type: "link",
            size: "large",
          }}
          okText="YES"
          cancelText="NO"
          title="Sure to Delete Comment?"
          onConfirm={(e) => {
            handleDelete();
            e?.stopPropagation();
          }}
          onCancel={(e) => e?.stopPropagation()}
        >
          <Button
            onClick={(e) => e.stopPropagation()}
            loading={deleting}
            type="primary"
            danger
            data-testid="delete"
          >
            Delete
          </Button>
        </Popconfirm>
        <Button
          data-testid="edit"
          onClick={(e) => {
            setShowEditModal(true);
            e.stopPropagation();
          }}
        >
          Edit
        </Button>
      </Space>
      <EditBlogPost
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        callback={() => getBlogPosts()}
        post={post}
      />
    </div>
  );
};

export default BlogActions;
