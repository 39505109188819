import { Spin } from "antd";

export default function PageSpinner({ fullScreen }: { fullScreen?: boolean }) {
  return (
    <div
      style={{
        width: "100%",
        height: fullScreen ? "100vh" : "100%",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Spin data-testid="spinner" />
    </div>
  );
}
