import { Button, Divider, Form, Input, Modal, Select, Upload } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./styles.module.css";
import axiosConfig from "config/axios";
import Spinner from "assets/icons/Spinner";
import useMaterials from "hooks/useMaterials";
import useCategories from "hooks/useCategories";
import uploadToS3 from "util/uploadToS3";
import { ArrowUpOutlined } from "@ant-design/icons";

interface modalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  callback?: any;
}
const { Option } = Select;
const AddMaterial = ({ showModal, setShowModal, callback }: modalProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [brandData, setBrandData] = useState<[] | any>([]);
  const { resetFields } = form;
  const { addMaterial } = useMaterials();
  const action = () => {
    setIsSubmitting(false);
    setShowModal(false);
    callback();
    resetFields();
  };
  const { category, getCategories } = useCategories();

  const getBrand = () => {
    axiosConfig
      .get("getbrands")
      .then(
        ({
          data: {
            data: { brands },
          },
        }) => {
          setBrandData(brands);
        }
      )
      .catch((error) => {});
  };

  useEffect(() => {
    showModal && Promise.all([getCategories(), getBrand()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleSubmit = async (values: {}) => {
    setIsSubmitting(true);
    await addMaterial(values, action);
  };

  return (
    <Modal
      className={styles.modal}
      title="Add new material"
      onCancel={() => setShowModal(false)}
      onOk={() => setShowModal(false)}
      visible={showModal}
      footer={false}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        className={styles["modal-form"]}
      >
        <Form.Item
          label="Select Category"
          name="categoryId"
          rules={[{ required: true, message: "Please choose a category" }]}
        >
          {/* <Input /> */}
          <Select style={{ width: "100%" }}>
            {category.map(({ id, name }: { id: string; name: string }) => (
              <Option
                style={{ textTransform: "capitalize" }}
                key={id}
                value={id}
              >
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Select Brand"
          name="brandId"
          rules={[{ required: true, message: "Please choose a brand" }]}
        >
          <Select style={{ width: "100%" }}>
            {brandData.map(
              ({ id, brandName }: { id: string; brandName: string }) => (
                <Option
                  style={{ textTransform: "capitalize" }}
                  key={id}
                  value={id}
                >
                  {brandName}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
        <Form.Item
          label="Unit of measurement"
          name="unitOfMeasure"
          rules={[
            {
              required: true,
              message: "Please Enter Unit of measurememt",
            },
          ]}
        >
          <Input min={0} />
        </Form.Item>
        {/* <Form.Item
          label="Image url"
          name="image"
          rules={[{ required: true, message: "Please select image" }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          rules={[{ required: true, message: "Please Upload an image" }]}
          label="Upload material image"
          name="image"
          valuePropName="file"
          getValueFromEvent={(e) => {
            const file = e.fileList[0];
            if (file?.status === "done") {
              return file.response;
            }
          }}
        >
          <Upload
            customRequest={uploadToS3}
            listType="picture"
            multiple={false}
            maxCount={1}
            accept="image/*"
          >
            <Button icon={<ArrowUpOutlined />}>
              Click Here to upload an image
            </Button>
            <br />
            <small>
              Image should not be greater than <b>20MB</b>
            </small>
          </Upload>
        </Form.Item>
        <Divider />
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          {isSubmitting ? <Spinner /> : `SUBMIT`}
        </Button>
      </Form>
    </Modal>
  );
};

export default AddMaterial;
