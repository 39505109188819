import { Button, Divider, message, Skeleton, Space, Table } from "antd";
import styles from "./builders.module.css";
import { useEffect, useState } from "react";
import axios from "config/axios";
import { navigate, useMatch } from "@reach/router";
import Spinner from "assets/icons/Spinner";
import { ArrowLeftOutlined } from "@ant-design/icons";
import requestErrorHandler from "util/requestErrorHandler";

const ProjectInfo = ({
  homeowner,
  isLoading,
}: {
  homeowner?: ProjectType;
  isLoading: Boolean;
}) => {
  return (
    <div>
      <>
        <Space className={styles.cta}>
          {isLoading && (
            <>
              <Skeleton.Button />
              <Skeleton.Button />
            </>
          )}
        </Space>
        <Divider />

        {isLoading && (
          <>
            <Skeleton.Input size="large" style={{ width: 1000, height: 350 }} />
          </>
        )}
        {!isLoading && (
          <>
            <div className={styles["details-card"]}>
              <span>
                Project Name
                <b>{homeowner?.name}</b>
              </span>
              <span>
                Contractor Name
                <b>{homeowner?.contractorName}</b>
              </span>
              <span>
                Contractor Phone
                <b>{homeowner?.contractorPhone}</b>
              </span>
              <span>
                Project Location
                <b>{homeowner?.locationName}, Nigeria</b>
              </span>
            </div>
          </>
        )}
      </>
    </div>
  );
};

const ProjectOrders = ({
  homeowner,
  isLoading,
}: {
  homeowner?: ProjectType;
  isLoading: Boolean;
}) => {
  const columns = [
    {
      title: "INVOICE ID",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      ellipsis: true,
    },
    {
      title: "TOTAL PRICE",
      dataIndex: "materialPrice",
      key: "materialPrice",
      ellipsis: true,
    },
    {
      title: "PAYMENT STATUS",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      ellipsis: true,
    },
    {
      title: "DELIVERY PRICE",
      dataIndex: "deliveryPrice",
      key: "deliveryPrice",
      ellipsis: true,
    },
  ];

  return (
    <div>
      <>
        <Space className={styles.cta}>
          {isLoading && (
            <>
              <Skeleton.Button />
              <Skeleton.Button />
            </>
          )}
        </Space>
        <Divider />

        {isLoading && (
          <>
            <Skeleton.Input size="large" style={{ width: 1000, height: 350 }} />
          </>
        )}
        {!isLoading && (
          <>
            <div style={{ width: "100%", overflowX: "auto" }}>
              <Table
                rowClassName={styles["table-row"]}
                columns={columns}
                dataSource={homeowner?.orders}
                className={styles.table}
                loading={isLoading}
                size="middle"
                style={{
                  textTransform: "capitalize",
                  fontSize: "0.2rem",
                  minWidth: "600px",
                }}
              />
            </div>
          </>
        )}
      </>
    </div>
  );
};

const ProjectDetails = (props: ReachPathProps) => {
  const [homeowner, setHomeowner] = useState<ProjectType>();
  const [isLoading, setIsLoading] = useState(false);

  const match = useMatch("/admin/homeowners/details/project-details/:id") as {
    uri: string;
    path: string;
    id: string;
  };

  useEffect(() => {
    setIsLoading(true);
    getBuilder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.id]);

  const getBuilder = () => {
    axios
      .get(`homeowner/get-project?projectId=${match.id}`)
      .then(({ data }) => {
        setHomeowner(data.project);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }
  // const { builder } = useContext(BuilderContext);
  return (
    <div className={styles.container}>
      <div className="s-between">
        <Button
          icon={<ArrowLeftOutlined />}
          type="text"
          size="small"
          onClick={() => {
            navigate(-1);
          }}
          style={{
            marginBottom: 10,
          }}
        >
          Back
        </Button>
        <Button
          type="primary"
          // size="small"
          onClick={() => {
            navigate(`timeline/${match.id}`);
          }}
          style={{
            marginBottom: 10,
          }}
        >
          View Timeline
        </Button>
      </div>

      <ProjectInfo homeowner={homeowner} isLoading={isLoading} />
      <ProjectOrders homeowner={homeowner} isLoading={isLoading} />
    </div>
  );
};

export default ProjectDetails;
