import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Col,
  Row,
} from "antd";
import axios from "config/axios";
import { useEffect, useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

const { Option } = Select;

export default function EditTruckModal({
  currentTruck,
  getTrucks,
  showModal,
  setShowModal,
}: {
  currentTruck: TruckSetting | null;
  getTrucks: () => Promise<void>;
  showModal: boolean;
  setShowModal: any;
}) {
  return (
    <Modal
      afterClose={() => {
        getTrucks();
      }}
      footer={null}
      visible={showModal}
      title={`Edit ${currentTruck?.truckType}`}
      onCancel={() => setShowModal(false)}
    >
      {!!currentTruck && (
        <EditForm
          callback={getTrucks}
          initialValues={currentTruck}
          onCancel={() => setShowModal(false)}
        />
      )}
    </Modal>
  );
}

function EditForm({
  initialValues,
  onCancel,
  callback,
}: {
  initialValues: TruckSetting;
  onCancel: () => void;
  callback?: () => void;
}) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [current, setCurrent] = useState("");
  async function handleSubmit(values: any) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const truckTonnage = form
        .getFieldValue("truckTonnage")
        .map((value: string) => +value) as number[];
      await axios.post(`truck/edit/${initialValues.id}`, {
        ...values,
        truckTonnage,
      });
      callback && callback();
      form.resetFields();
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }
  useEffect(() => {
    form.setFieldsValue({
      truckTonnage: initialValues.truckTonnage.map((t) => t.toString()),
      truckType: initialValues.truckType,
      baseprice: initialValues.baseprice,
      instantBasePrice: initialValues.instantBasePrice,
      oneWeekBasePrice: initialValues.oneWeekBasePrice,
      twoWeeksBasePrice: initialValues.twoWeeksBasePrice,
      mileage: initialValues.mileage,
      instantMileage: initialValues.instantMileage,
      oneWeekMileage: initialValues.oneWeekMileage,
      twoWeeksMileage: initialValues.twoWeeksMileage,
    });
  }, [form, initialValues]);

  return (
    <Form
      layout="vertical"
      form={form}
      // initialValues={initialValues}
      // preserve={false}
      onFinish={handleSubmit}
    >
      <Form.Item
        rules={[{ required: true, message: "Input the truck type" }]}
        name="truckType"
        label="Truck Type"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Input tonnage" }]}
        name="truckTonnage"
        label="Truck Tonnage"
      >
        <Select
          mode="multiple"
          onSearch={(e) => {
            if (e.match(/^\d+$/)) {
              setCurrent(e);
            } else {
              setCurrent("");
            }
          }}
          onChange={() => {
            setCurrent("");
          }}
        >
          {current && <Option value={current}>{current} tonnes</Option>}
        </Select>
      </Form.Item>
      <Row align="bottom">
        <Col xs={12} md={6}>
          <Form.Item
            rules={[{ required: true, message: "Input the base price" }]}
            name="baseprice"
            label="Base Price"
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            rules={[{ required: true, message: "Input the base price" }]}
            name="instantBasePrice"
            label="instant Base Price"
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            rules={[{ required: true, message: "Input the base price" }]}
            name="oneWeekBasePrice"
            label="One-week Base Price"
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            rules={[{ required: true, message: "Input the base price" }]}
            name="twoWeeksBasePrice"
            label="Two-Weeks Base Price"
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Row align="bottom">
        <Col xs={12} md={6}>
          <Form.Item
            rules={[{ required: true, message: "Input the mileage" }]}
            name="mileage"
            label="Mileage"
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            rules={[{ required: true, message: "Input the mileage" }]}
            name="instantMileage"
            label="Instant Mileage"
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            rules={[{ required: true, message: "Input the mileage" }]}
            name="oneWeekMileage"
            label="One-week Mileage"
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            rules={[{ required: true, message: "Input the mileage" }]}
            name="twoWeeksMileage"
            label="Two-weeks Mileage"
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          loading={isSubmitting}
          htmlType="submit"
          block
          type="primary"
          size="large"
          data-testid="submit"
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
