import { Button, Modal, Select } from "antd";
import { CategoriesData } from "components/EditSupplierCategory/EditSupplierCategory";

const { Option } = Select;

const AddPriority = ({
  handleModal,
  setHandleModal,
  selectedId,
  setValues,
  values,
}: {
  handleModal: boolean;
  setHandleModal: any;
  selectedId: string;
  setValues: any;
  values: CategoriesData[];
}) => {
  const closeModal = () => setHandleModal(false);

  const priorities = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <div>
      <Modal
        data-testid="modal"
        title="Select Priority level"
        footer={false}
        open={handleModal}
        onCancel={closeModal}
      >
        <div
          data-testid="select"
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
        >
          <Select
            // mode=""
            onSelect={(e) => {
              const _values = [...values];
              _values.map((value) => {
                if (value.categoryId === selectedId) {
                  value.priorityLevel = e;
                }
                return null;
              });
              setValues(_values);
            }}
          >
            {priorities.map((priority) => (
              <Option key={priority} value={priority}>
                {priority}
              </Option>
            ))}
          </Select>
          <Button
            data-testid="button"
            size="large"
            type="primary"
            htmlType="submit"
            onClick={closeModal}
          >
            SUBMIT
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AddPriority;
