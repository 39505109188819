import { Table, Input, message, Tooltip, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DeviceWidth from "context/MobileResizeContext";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./blog.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import axios from "config/axios";
import { ExportOutlined } from "@ant-design/icons";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";

export function Subscriber(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [subscribers, setSubscribers] = useState<EmailSubscriber[]>([]);
  const [page] = useState(1);
  const [paginationSize] = useState(10);

  const [loading, setLoading] = useState<boolean>();

  const { handleSearch, isSearching, searchQuery } = useSearch();

  const exportSubscribers = () => {
    setLoading(true);

    let csvContent = "data:text/csv;charset=utf-8,";

    subscribers.forEach(function (subscriber) {
      csvContent += subscriber.email + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "subscribers.csv");
    document.body.appendChild(link);

    link.click();

    setLoading(false);
  };

  const getAllSubscribers = useCallback(() => {
    setLoading(true);
    axios
      .get(`blogpost/get-all-subs`)
      .then(({ data }) => {
        setSubscribers(data.allSubs);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    getAllSubscribers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let result = subscribers.filter((builder) => {
    return builder.email.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
  });

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (_: any, record: any, index: any) =>
        (page - 1) * paginationSize + index + 1,
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_: any, record: any) => (
        <div>
          {new Date(record.createdAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "..", name: "Blog" },
          { to: "", name: "Subscribers" },
        ]}
      />
      <div className={classNames([styles.topbar, "c-end"])}>
        <Input
          prefix={<SearchOutlined />}
          size="middle"
          onChange={(e) => handleSearch(e)}
          allowClear
          style={{ width: isMobile ? "100%" : "250px", paddingRight: "1rem" }}
        />

        <Button
          icon={<ExportOutlined />}
          onClick={() => exportSubscribers()}
          style={{ marginRight: "4px" }}
          type="primary"
          size="large"
        >
          Export
        </Button>
      </div>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table
          columns={columns}
          dataSource={isSearching ? result : subscribers}
          loading={loading}
        />
      </div>
    </div>
  );
}
