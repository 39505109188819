import { Redirect, Router } from "@reach/router";
import SideNav from "components/SideNav/SideNav";
import useAuth from "hooks/useAuth";
import { useContext, useEffect, useState } from "react";
import styles from "./layout.module.css";
import { ArrowLeftCircle } from "react-feather";
import DeviceWidth from "context/MobileResizeContext";
import useModules from "hooks/useModules";

export default function Admin(props: ReachPathProps) {
  const { user } = useAuth();
  const { dashboardRoute } = useModules();
  const isMobile = useContext(DeviceWidth);
  useEffect(() => {
    if (isMobile) setCollapseNav(false);
  }, [isMobile]);
  const [collapseNav, setCollapseNav] = useState<boolean>(false);

  const toggleSideNav = () => {
    setCollapseNav(!collapseNav);
  };

  if (!user) return <Redirect from="*" to="/login" noThrow />;

  return (
    <div className={styles.layout}>
      <div>
        <span
          onClick={() => toggleSideNav()}
          className={`${styles["arrow-collapse"]} ${
            collapseNav && styles["roatateArrow"]
          }`}
        >
          <ArrowLeftCircle color="#00962c" />
        </span>

        <SideNav collapseNav={collapseNav} setCollapseNav={setCollapseNav} />
      </div>
      <main className={styles.main__container}>
        <Router>
          {dashboardRoute.map((route) => (
            <route.component path={route.path} key={route.path} />
          ))}
        </Router>
      </main>
    </div>
  );
}
