import {
  Typography,
  Button,
  Space,
  // Form,
  Modal,
  message,
  Skeleton,
  // Popconfirm,
  Collapse,
  Statistic,
  Spin,
  Divider,
  Input,
} from "antd";
import { CheckSquareOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import styles from "./transactions.module.css";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import dayjs from "dayjs";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import axiosConfig from "config/axios";
import { useMatch } from "@reach/router";
import OrderTracking from "components/OrderTracking";
import { navigate } from "@reach/router";
import useModules from "hooks/useModules";
import ReconcileQty from "components/ReconcileQty";
// import Spinner from "assets/icons/Spinner";
// import OrderTracking from "components/OrderTracking";

const { Text } = Typography;

const UnpaidDetails = (props: ReachPathProps & { id?: string }) => {
  const match = useMatch("/admin/transactions/unpaid/:id") as {
    uri: string;
    path: string;
    id: string;
  };
  // const [form] = Form.useForm();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [completingOrder, setCompletingOrder] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<Transaction>();
  const [amount, setAmount] = useState("0");
  const [amountShowModal, setAmountShowModal] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const { allowAction } = useModules();

  // const [dspInfo, setDspInfo] = useState<{} | any>();
  const getTransactionInfo = useCallback(async () => {
    try {
      const {
        data: { unpaidOrder },
      } = await axios.get(`order/getone/unpaidorder/${match.id}`);
      setTransaction(unpaidOrder);
    } catch {
      message.error("Error fetching order info");
    } finally {
      setLoading(false);
    }
  }, [match.id]);

  const completeOrder = async () => {
    setCompletingOrder(true);
    try {
      await axiosConfig.post("payment/adminmarkaspaid", {
        orderId: transaction?.id,
        amount,
      });
      message.success("Payment confirmed");
      // getTransactionInfo();
      setOrderConfirmed(true);
      setAmountShowModal(false);
    } catch {
      message.error("Error confirming payment");
      setOrderConfirmed(false);
    } finally {
      // getTransactionInfo();
      setCompletingOrder(false);
    }
  };

  useEffect(() => {
    getTransactionInfo();
  }, [getTransactionInfo]);

  const addMaterial = () => {
    setShowModal(false);
  };

  if (loading || !transaction) {
    return (
      <div className={` ${styles["details-card-container"]}`}>
        <Skeleton.Input active />
        <Spin />
      </div>
    );
  }

  return (
    <div className={` ${styles["details-container"]}`}>
      <Button
        icon={<ArrowLeftOutlined />}
        type="text"
        size="small"
        onClick={() => {
          navigate(-1);
        }}
        style={{
          marginBottom: 10,
        }}
      >
        Back
      </Button>
      <div className={`${styles.topbar} ${styles["details-topbar"]}`}>
        {/* <div>
          <b>Transaction</b>
          <span style={{ opacity: ".5" }}> | {transaction.frontendId}</span>
        </div> */}
        {/* <Space style={{ overflowX: "auto" }}> */}
        {/* <Button
          icon={<CheckSquareOutlined />}
          type="link"
          size="small"
          onClick={() => setShowModal(true)}
        >
          Track order
          <span style={{ opacity: ".5" }}> | {transaction.id}</span>
        </Button> */}
        <Space style={{ overflowX: "auto" }}>
          {allowAction("transactions-write") && (
            <>
              <Button
                onClick={() => setAmountShowModal(true)}
                icon={<CheckSquareOutlined />}
                type="link"
                size="small"
                disabled={orderConfirmed}
              >
                {!orderConfirmed ? "CONFIRM PAYMENT" : "PAYMENT CONFIRMED"}
              </Button>
              {/* <ReconcileQty transaction={transaction} /> */}
              <Button
                onClick={() =>
                  navigate(`/admin/transactions/invoice/${transaction.id}`)
                }
                icon={<CheckSquareOutlined />}
                type="link"
                size="small"
                disabled={orderConfirmed}
              >
                Invoice
              </Button>
            </>
          )}

          <Modal
            className={styles.modal}
            title="Confirm Payment"
            onCancel={() => setAmountShowModal(false)}
            onOk={addMaterial}
            visible={amountShowModal}
            footer={false}
          >
            <label htmlFor="amoun">
              Enter Amount ₦
              <Input
                // defaultValue={}
                id="amount"
                // value={+amount > 0 ? amount : transaction.totalPrice}
                onChange={(e) => setAmount(e.target.value)}
              />
            </label>
            <Divider />
            <Button
              size="large"
              type="primary"
              style={{ width: "100%" }}
              onClick={completeOrder}
              loading={completingOrder}
            >
              SUBMIT
            </Button>
          </Modal>
        </Space>
        <OrderTracking
          transaction={transaction}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      </div>
      <div className={styles["details-card-container"]}>
        <div className={styles["details-card"]}>
          <span>
            Invoice ID
            <b>{transaction.invoiceNumber}</b>
          </span>
          <span>
            Order date
            <b>{dayjs(transaction.createdAt).format("DD MMM YYYY")}</b>
          </span>
          <span>
            Builder
            <b>{transaction.builder?.businessName}</b>
          </span>

          {/* <span>
            Delivery status
            {transaction.trackingStatus === "delivered" ? (
              <b style={{ color: "#00962C" }}>Delivered</b>
            ) : (
              <b style={{ color: "#F2994A" }}>Pending</b>
            )}
          </span> */}
        </div>
        <div className={styles["details-card"]}>
          {/* <span>
            <Text>Invoice Number</Text>
            <b>{transaction.invoiceNumber}</b>
          </span> */}
          {/* <span>
            <Text>Delivery Address</Text>
            <b>
              {transaction.billingAddress}, {transaction.billingCity}{" "}
            </b>
          </span> */}
          <span>
            Order Approved
            <b>{transaction?.isApproved}</b>
          </span>

          <span>
            Payment method
            <b>{transaction.paymentMethod}</b>
          </span>
          <span>
            Payment status
            {transaction?.isPaidFor || orderConfirmed ? (
              <b style={{ color: "#00962C" }}>Paid</b>
            ) : (
              <b style={{ color: "#FF612F" }}>Unpaid</b>
            )}
          </span>
        </div>
        {/* <div className={styles["details-card"]}>
          <span>
            <Text>DSP Name</Text>
            <b>{dspInfo?.businessName}</b>
          </span>
          <span>
            <Text>State</Text>
            <b>Lagos, Nigeria</b>
          </span> 
          <span>
            <Text>DSP Address</Text>
            <b>{dspInfo?.businessAddress}</b>
          </span>
          <span>
            <Text>Order Approve status</Text>
            <b>{dspInfo?.status} </b>
          </span>
        </div> */}
      </div>
      {transaction?.order_items.map((order: any, idx: number) => (
        <div key={idx} className={styles["main-body"]}>
          <div className={styles["profile-card"]}>
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles["profile--card-content"]}>
              <img
                src={order.variant.material.imageUrl}
                alt={`${order.variant.material.brand?.brandName} ${order.variant.material.category?.name} ${order.variant?.description}`}
                className={styles.avatar}
                height={200}
              />
              <div className={styles["profile-content"]}>
                <div className={styles.name}>
                  <h3
                    style={{ textTransform: "uppercase", margin: 0 }}
                  >{`${order.variant.material.brand?.brandName} ${order.variant.material.category?.name} `}</h3>
                  <h3
                    style={{
                      textTransform: "uppercase",
                      opacity: 0.5,
                      margin: 0,
                    }}
                  >{`  ${order.variant.description}`}</h3>
                </div>
                <Divider style={{ margin: "0.5rem 0" }} />
                <span className={styles.span}>
                  <Text>Material Price</Text>
                  <b>
                    {" "}
                    <Statistic prefix="₦" value={order.detailsPrice} />{" "}
                  </b>
                </span>
                <span className={styles.span}>
                  <Text>Delivery Price</Text>
                  <b>
                    <Statistic prefix="₦" value={order.detailsDeliveryPrice} />{" "}
                  </b>
                </span>
                <span className={styles.span}>
                  <Text>Total Price</Text>
                  <b>
                    <Statistic
                      prefix="₦"
                      value={(
                        +order.detailsPrice + +order.detailsDeliveryPrice
                      ).toFixed(2)}
                    />{" "}
                  </b>
                </span>
              </div>
            </div>
            <div>
              <Collapse>
                <CollapsePanel header={`Delivery schedules`} key={0}>
                  <div className={styles["details-card-container"]}>
                    {order.order_details.map((schedule: any, index: any) => (
                      <div
                        key={index}
                        className={styles["details-card"]}
                        style={{ backgroundColor: "#fff", width: "600px" }}
                      >
                        {schedule.length > 1 && (
                          <>
                            <b>Schedule {index + 1}</b>
                            <Divider />
                          </>
                        )}
                        <span>
                          <Text>Quantity</Text>
                          <b>
                            {`${schedule.amount} ${order.variant.material.unitOfMeasure}` ??
                              "--"}
                          </b>
                        </span>
                        <span>
                          <Text>Self Pick up</Text>
                          <b>{schedule.isPickup.toString() ?? "--"}</b>
                        </span>
                        <span>
                          <Text>Delivery Address</Text>
                          <b>{schedule.deliveryAddress ?? "--"}</b>
                        </span>
                        <span>
                          <Text>Delivery Date</Text>
                          <b>
                            {dayjs(schedule.deliveryDate).format(
                              "DD MM YYYY"
                            ) ?? "--"}
                          </b>
                        </span>
                        <span>
                          <Text>Delivery distance</Text>
                          <b>{schedule.distance ?? "--"}</b>
                        </span>
                        <span>
                          <Text>Material Price</Text>
                          <b>
                            <Statistic
                              prefix="₦"
                              value={schedule.materialPrice ?? "--"}
                            />
                          </b>
                        </span>
                        <span>
                          <Text>Recipient Name</Text>
                          <b>{schedule.recipientName ?? "--"}</b>
                        </span>
                        <span>
                          <Text>Phone</Text>
                          <b>{schedule.phoneNumber ?? "--"}</b>
                        </span>
                      </div>
                    ))}
                  </div>
                </CollapsePanel>
              </Collapse>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UnpaidDetails;

// import { Typography, Button, Table, Space, Form, Modal, Input, Divider } from "antd";
// import { MoreOutlined, CheckSquareOutlined, FileTextOutlined } from "@ant-design/icons";
// import styles from "./transactions.module.css";
// import { useState } from "react";

// const { Text } = Typography;

// const UnpaidDetails = (props: ReachPathProps) => {
//   const [showModal, setShowModal] = useState<boolean>(false);

//   const [form] = Form.useForm();
//   const columns = [
//     {
//       title: "Material",
//       dataIndex: "material",
//       key: "material",
//     },
//     {
//       title: "Quantity",
//       dataIndex: "quantity",
//       key: "quantity",
//     },
//     {
//       title: "UNIT PRICE",
//       dataIndex: "unit price",
//       key: "unit price",
//     },
//     {
//       title: "TOTAL",
//       dataIndex: "total",
//       key: "total",
//       render: (text: string) => (
//         <Space style={{ display: "flex", justifyContent: "space-between" }}>
//           {text}
//           <Button style={{ transform: "rotateZ(90deg)" }} type="text">
//             <MoreOutlined />
//           </Button>
//         </Space>
//       ),
//     },
//   ];
//   const data = [
//     {
//       key: "1",
//       material: "Medium",
//       quantity: "50kg",
//       "unit price": "#95,000",
//       total: "#950,000",
//     },
//     {
//       key: "2",
//       material: "Large",
//       quantity: "150kg",
//       "unit price": "#195,000",
//       total: "#1550,000",
//     },
//     {
//       key: "3",
//       material: "Small",
//       quantity: "30kg",
//       "unit price": "#15,000",
//       total: "#50,000",
//     },
//   ];
//   const addMaterial = () => {
//     setShowModal(false);
//   };
//   return (
//     <div className={styles["details-container"]}>
//       <div className={`${styles.topbar} ${styles["details-topbar"]}`}>
//         <div>
//           <b>Transaction</b>
//           <span style={{ opacity: ".5" }}> | #342895</span>
//         </div>
//         <Space style={{ overflowX: "auto" }}>
//           <Button icon={<CheckSquareOutlined />} type="link" size="small" onClick={() => setShowModal(true)}>
//             SET SUPPLIER PAYMENT
//           </Button>
//           <Button icon={<CheckSquareOutlined />} type="link" size="small">
//             SET DELIVERY PAYMENT
//           </Button>
//           <Button icon={<FileTextOutlined />} type="link" size="small">
//             PREVIEW INVOICE
//           </Button>
//         </Space>
//         <Modal
//           className={styles.modal}
//           title="Set supplier payment"
//           onCancel={() => setShowModal(false)}
//           onOk={addMaterial}
//           visible={showModal}
//           footer={false}>
//           <Form form={form} layout="vertical" className={styles["modal-form"]}>
//             <label>Total payment due</label>
//             <Input placeholder="₦950,000" />
//             <label>Amount paid to supplier</label>
//             <Input value="₦1,500,000" />
//             <Divider />
//             <Button type="primary" style={{ width: "100%" }}>
//               SUBMIT
//             </Button>
//           </Form>
//         </Modal>
//       </div>
//       <div className={styles["details-card-container"]}>
//         <div className={styles["details-card"]}>
//           <span>
//             Order ID
//             <b>#345362</b>
//           </span>
//           <span>
//             Order date
//             <b>12 January 2020</b>
//           </span>
//           <span>
//             Builder (Requester)
//             <b>Hlobal Real Estate LTD</b>
//           </span>
//           <span>
//             Payment status
//             <b style={{ color: "#00962C" }}>Paid</b>
//           </span>
//           <span>
//             Supplier payment status
//             <b style={{ color: "#F2994A" }}>Pending</b>
//           </span>
//           <span>
//             Delivery payment status
//             <b style={{ color: "#F2994A" }}>Pending</b>
//           </span>
//           <span>
//             Delivery status
//             <b style={{ color: "#F2994A" }}>Pending</b>
//           </span>
//         </div>
//         <div className={styles["details-card"]}>
//           <span>
//             <Text>Delivery option</Text>
//             <b>Deliver to address</b>
//           </span>
//           <span>
//             <Text>Delivery address</Text>
//             <b>T10 logas stress, banaa island</b>
//           </span>
//           <span>
//             <Text>State</Text>
//             <b>Lagos, Nigeria</b>
//           </span>
//           <span>
//             <Text>Supplier</Text>
//             <b>Chisco building LTD</b>
//           </span>
//           <span>
//             <Text>Delivery partner</Text>
//             <b>Fastex delivery solution</b>
//           </span>
//           <span>
//             <Text>Expected delivery date</Text>
//             <b>12 March 2020</b>
//           </span>
//         </div>
//       </div>
//       <div className={styles["main-body"]}>
//         <div>
//           <Table
//             pagination={false}
//             columns={columns}
//             dataSource={data}
//             className={styles.table}
//             style={{ fontSize: "0.2rem", overflowX: "auto" }}
//           />
//         </div>
//       </div>
//       <div className={styles.footer}>
//         <div className={styles["details-card"]}>
//           <span>
//             <Text>Delivery option</Text>
//             <b>₦300,000</b>
//           </span>
//           <span>
//             <Text>Sun total</Text>
//             <b>₦3000,000</b>
//           </span>
//           <span style={{ color: "#00962C" }}>
//             <Text>Total</Text>
//             <b>₦3000,000</b>
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UnpaidDetails;
