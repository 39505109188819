import { Button, Divider, Modal, Space, Typography, message } from "antd";
import axiosConfig from "config/axios";
import useGodumpApi from "hooks/useGodumpApi";
import { useEffect, useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";
import styles from "../../pages/admin/transactions/invoice/transactions.module.css";
import BalanceAlertMessage from "components/BalanceAlertMessage.tsx";

interface PayNowProp {
  bankModal: boolean;
  setBankModal: any;
  order: OrderCart;
  builder: Builder;
  getOrderInfo: any;
}

export default function PayNow({
  bankModal,
  setBankModal,
  order,
  builder,
  getOrderInfo,
}: PayNowProp) {
  const { createodumpOrder } = useGodumpApi();

  const [loadingtrf, setLoadingtrf] = useState<boolean>(false);
  const [paymentView, setPaymentView] = useState<1 | 2>(1);
  const [isPaying, setIsPaying] = useState(false);

  const orderId = order?.id;

  const payWithBalance = async () => {
    setIsPaying(true);
    try {
      await axiosConfig.post(`payment/pay-balance`, {
        builderId: builder?.id,
        orderId: order?.id,
      });

      message.success("Balance Paid");
      getOrderInfo();
      setPaymentView(2);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsPaying(false);
    }
  };

  const handlePayWithTF = async () => {
    setLoadingtrf(true);
    await axiosConfig
      .post("payment/paywithtrf", { orderId: order.id })
      .then(async (res) => {
        if (order.goDumptruckOrderId !== null) {
          await createodumpOrder(orderId!);
        }
        message.success("Payment is being processed");
        getOrderInfo();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setLoadingtrf(false));
    setBankModal(false);
  };

  useEffect(() => {
    if (+builder?.balance! === 0) {
      setPaymentView(2);
    } else {
      setPaymentView(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankModal, builder]);

  return (
    <Modal
      open={bankModal}
      title={
        paymentView === 1 ? "Choose Mode of Payment" : "Pay By Bank Transfer"
      }
      onCancel={() => {
        setBankModal(false);
        setPaymentView(1);
      }}
      cancelButtonProps={{
        danger: true,
        style: { display: paymentView === 1 ? "none" : "inline" },
      }}
      okButtonProps={{
        loading: loadingtrf,
        style: { display: paymentView === 1 ? "none" : "inline" },
      }}
      // footer={true}
      onOk={handlePayWithTF}
      okText="Confirm Payment"
    >
      <BalanceAlertMessage
        builder={builder}
        order={order}
        paymentView={paymentView}
      />
      <Divider style={{ margin: 10 }} />

      {paymentView === 1 ? (
        <Space
          data-testid="main"
          className={styles.modalArea}
          direction="vertical"
        >
          <div>
            <Button
              block
              size="large"
              type="primary"
              hidden={+builder?.balance! === 0}
              loading={isPaying}
              data-testid="pay-button"
              onClick={payWithBalance}
            >
              PAY WITH BALANCE
            </Button>
          </div>
          <div>
            <Button
              disabled={isPaying}
              block
              size="large"
              data-testid="transfer-button"
              onClick={() => setPaymentView(2)}
            >
              PAY BY BANK TRANSFER
            </Button>
          </div>
        </Space>
      ) : (
        <div>
          <Typography.Title level={5}>Payable to:</Typography.Title>
          <p>Bank name: {builder!.accountBank}</p>
          <p>Account name: {builder!.accountName}</p>
          <Typography.Paragraph>
            Account number:{" "}
            <Typography.Text copyable>{builder!.accountNumber}</Typography.Text>
            <br />
            Reference number:{" "}
            <Typography.Text copyable>{order?.frontendId}</Typography.Text>
          </Typography.Paragraph>

          <p>
            <small>
              Your order will be automatically processed once payment is
              confirmed.
            </small>
          </p>
        </div>
      )}
    </Modal>
  );
}
