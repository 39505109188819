import { Button, Row, Skeleton, Statistic } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { useState } from "react";
// import useAuth from "hooks/useAuth";
import styles from "./cart.module.css";
import SummaryItem from "./SummaryItem";

export interface CartProps {
  title: string;
  footer?: JSX.Element;
  getCart: () => void;
  subTotal?: number;
  cart: [] | any;
  isQuotation?: boolean;
  isLoadingCart?: boolean;
  exceedQty?: number;
  setIsUpdatingCart?: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdatingCart?: boolean;
}

const Cart = ({
  title,
  isQuotation,
  footer,
  getCart,
  cart,
  subTotal,
  isLoadingCart,
  exceedQty,
  setIsUpdatingCart,
  isUpdatingCart,
}: CartProps) => {
  const [, setFallback] = useState<boolean>(false);

  const totalItems = cart?.length;
  const itemButton = (
    <Button type="primary" size="middle">
      {totalItems} item{totalItems <= 1 ? "" : "s"}
    </Button>
  );
  if (cart)
    return (
      <div className={styles["cart"]}>
        <Row justify="space-around">
          <Title level={5}>{title}</Title>
          {itemButton}
        </Row>
        <div className={styles.body}>
          {cart?.length <= 0 && (
            <section data-testid="empty-cart">
              {isLoadingCart ? (
                <div className={styles["empty-box"]} style={{ top: "20%" }}>
                  <Skeleton.Input active style={{ height: 220, width: 280 }} />
                </div>
              ) : (
                <div className={styles["empty-box"]}>
                  <img
                    data-testid="empty-cart-image"
                    src="/empty-cart.png"
                    alt="empty box"
                    height={200}
                  />
                  <Text
                    data-testid="empty-cart-text"
                    className={styles["desc"]}
                  >
                    No item added yet
                  </Text>
                </div>
              )}
              {/* <div className={styles.footer}>
                <div className={styles["footer-action"]}>{footer}</div>
              </div> */}
            </section>
          )}
          {cart?.length > 0 && (
            <div data-testid="active-cart" className={styles.content}>
              <div
                data-testid="active-cart-item"
                className={styles["cart-items"]}
              >
                <SummaryItem
                  exceedQty={exceedQty}
                  isQuotation={isQuotation}
                  getCart={getCart}
                  item={cart}
                  isUpdatingCart={isUpdatingCart}
                  setIsUpdatingCart={setIsUpdatingCart ?? setFallback}
                />
              </div>
              <div className={styles.footer}>
                <div
                  data-testid="active-cart-subtotal"
                  className={styles["sub-total"]}
                >
                  <Text style={{ fontSize: "0.8rem", opacity: "0.5" }}>
                    Subtotal
                  </Text>
                  {/* <Title style={{ margin: 0 }} level={3}>
                    {subTotal}
                  </Title> */}
                  <Statistic prefix="₦" value={subTotal} />
                </div>
                <div
                  data-testid="active-cart-footer"
                  className={styles["footer-action"]}
                >
                  {footer}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  return (
    <div data-testid="no-cart" className={styles["cart"]}>
      <Row justify="space-around">
        <Title level={5}>{title}</Title>
        {itemButton}
      </Row>
      <div className={styles.body}>
        <>
          {isLoadingCart ? (
            <Skeleton.Input active style={{ height: 220, width: 280 }} />
          ) : (
            <div className={styles["empty-box"]}>
              <img src="/empty-cart.png" alt="empty box" height={200} />
              <Text className={styles["desc"]}>No item added yet</Text>
            </div>
          )}
        </>
      </div>
    </div>
  );

  // THE OLD CART COMPONENT >>>

  // return (
  //   <div className={styles["cart"]}>
  //     <Row justify="space-around">
  //       <Title level={5}>{title}</Title>
  //       {ItemButton}
  //     </Row>
  //     <div className={styles.body}>
  //       {items.quotation_items.length === 0 && (
  //         <>
  //           <div className={styles["empty-box"]}>
  //             <img src="/empty-box.png" alt="empty box" height={200} />
  //             <Text className={styles["desc"]}>No item added yet</Text>
  //           </div>
  //           {/* <div className={styles.footer}>
  //             <div className={styles["footer-action"]}>{footer}</div>
  //           </div> */}
  //         </>
  //       )}
  //       {items?.quotation_items.length && (
  //         <>
  //           <div className={styles["cart-items"]}>
  //             {items.quotation_items.map((item) => (
  //               <SummaryItem getCart={getCart} item={item} />
  //             ))}
  //           </div>
  //           <div className={styles.footer}>
  //             <div className={styles["sub-total"]}>
  //               <Text style={{ fontSize: "0.8rem", opacity: "0.5" }}>
  //                 Subtotal
  //               </Text>
  //               <Title style={{ margin: 0 }} level={3}>
  //                 {subTotal}
  //               </Title>
  //             </div>
  //             <div className={styles["footer-action"]}>{footer}</div>
  //           </div>
  //         </>
  //       )}
  //     </div>
  //   </div>
  // );
};

export default Cart;
