import { Button, Form, Input, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import { EditModal } from "components/MaterialPricing";
import axiosConfig from "config/axios";
import { useCallback, useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

const EditMoq = ({
  showModal,
  setShowModal,
  variantId,
  callback,
  currentMoq,
  builderClassId,
  variants,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<EditModal>>;
  variantId: string;
  currentMoq: string;
  callback: () => void;
  builderClassId: string;
  variants: Variant[] | any;
}) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateMoq = useCallback(async (values: any) => {
    setIsLoading(true);
    try {
      await axiosConfig.put("builderclass/editvariantclass", {
        builderClassId,
        variantId,
        minQty: values.minQty,
      });
      message.success({
        content: (
          <>
            {" "}
            <b style={{ textTransform: "capitalize" }}>
              {variants &&
                variants.find((variant: any) => variant.id === variantId)
                  .description}
            </b>{" "}
            Moq updated
          </>
        ),
      });
      callback && callback();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id={currentMoq}>
      <Modal
        title="Edit Dynamic MOQ"
        footer={false}
        visible={showModal}
        onCancel={() =>
          setShowModal({
            dynamicPrce: "",
            status: false,
            variantId: "",
          })
        }
      >
        <Form form={form} layout="vertical" onFinish={updateMoq}>
          <Form.Item
            initialValue={currentMoq}
            label={"Dynamic MOQ"}
            name="minQty"
            rules={[
              {
                required: true,
                message: "Please enter Dynamic Price",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={variantId}
            label={"Variant ID"}
            name="variantId"
            hidden
          >
            <Input />
          </Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            SUBMIT
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default EditMoq;
