import { Button, Checkbox } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import styles from "./settings.module.css";
import CreateBankModal from "components/CreateBankModal/CreateBankModal";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import PageSpinner from "components/PageSpinner";
import useModules from "hooks/useModules";

export default function Bank(props: ReachPathProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<BankAccount[]>([]);
  const {
    user: { organizationId },
  } = useAuth() as { user: User };
  const { allowAction } = useModules();

  const getBankAccounts = useCallback(
    async function getBankAccounts() {
      setIsLoading(true);
      try {
        const {
          data: {
            data: { accounts },
          },
        } = await axios.get(`account/${organizationId}`);
        setAccounts(accounts);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [organizationId]
  );

  useEffect(() => {
    getBankAccounts();
  }, [getBankAccounts]);
  return (
    <div className={styles["bank-container"]}>
      {allowAction("settings-write") && (
        <div className="c-cener">
          <Button
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Add new bank account
          </Button>
        </div>
      )}
      {isLoading && !accounts ? (
        <div style={{ paddingTop: "10rem" }}>
          <PageSpinner />
        </div>
      ) : (
        accounts.map((account) => (
          <Accounts
            key={account.accountNumber}
            {...account}
            getBankAccounts={getBankAccounts}
            organizationId={organizationId}
          />
        ))
      )}

      <CreateBankModal
        isModalOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        getBankAccounts={getBankAccounts}
      />
    </div>
  );
}

function Accounts(
  props: BankAccount & {
    getBankAccounts: () => Promise<void>;
    organizationId: string;
  }
) {
  return (
    <div className={styles.table}>
      <table>
        <tbody>
          <tr>
            <th>Bank name</th>
            <td>{props.bankName}</td>
          </tr>
          <tr>
            <th>Account name</th>
            <td>{props.accountName}</td>
          </tr>
          <tr>
            <th>Account number</th>
            <td>{props.accountNumber}</td>
          </tr>
          <tr>
            <th>Set as default</th>
            <td>
              <Checkbox
                checked={props.isDefault}
                onChange={async (e) => {
                  if (e.target.checked) {
                    await axios.put(`account/${props.id}/default`, {
                      organizationId: props.organizationId,
                    });
                    props.getBankAccounts();
                  }
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
