import { Alert, Typography } from "antd";

export default function BalanceAlertMessage({
  paymentView,
  order,
  builder,
}: {
  paymentView: 1 | 2;
  order: OrderCart;
  builder: any;
}) {
  const totalPrice = +order?.totalPrice;
  const remainingPrice = +order?.amountLeftToBePaid!;

  const renderPrice =
    builder?.balance < 0
      ? `-₦${Math.abs(builder?.balance?.toLocaleString())}`
      : `₦${builder?.balance?.toLocaleString()}`;
  return (
    <Alert
      type="success"
      message={
        paymentView === 2 ? (
          <>
            <Typography.Title style={{ padding: 0, margin: 0 }} level={5}>
              Amount Payable:{" "}
              <span style={{ color: "green" }}>
                {" "}
                ₦{order.amountLeftToBePaid?.toLocaleString()}{" "}
              </span>
            </Typography.Title>
            {+totalPrice - +remainingPrice !== 0 && (
              <small>
                You have paid with a balance of{" "}
                {(totalPrice - remainingPrice).toFixed(2)}
              </small>
            )}
          </>
        ) : (
          <Typography.Title style={{ padding: 0, margin: 0 }} level={5}>
            {builder?.balance < 0
              ? "You have an outstanding balance of:"
              : "You are owing a balance of:"}
            <span style={{ color: builder?.balance < 0 ? "red" : "green" }}>
              {" "}
              {!renderPrice === undefined ? renderPrice : 0}
            </span>
          </Typography.Title>
        )
      }
    />
  );
}
