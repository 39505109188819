import { configureStore } from "@reduxjs/toolkit"
import auth from "./slices/auth"
import sidenav from "./slices/sidenav"
import builder from "./slices/builder"

const store = configureStore({
  reducer: { auth, sidenav,builder },
  devTools: process.env.NODE_ENV !== "production",
})

export default store

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>
