import { Modal, Form, Button, Select, message } from "antd";
import axios from "config/axios";
import { useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

const { Option } = Select;
export type MOQProps = {    
  isModalOpen: boolean;
  onCancel: () => void;
  id: string;
  callback: () => void;
  moqClasses: MoqClass[];
  builderClassId: string;
};

export default function AddMoqClass({
  isModalOpen,
  onCancel,
  id,
  callback,
  moqClasses,
  builderClassId,
}: MOQProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  // const [classes, setClasses] = useState<BuilderClass[]>([]);

  const handleSubmit = async (values: any) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await axios.post("builderclass/addbuilder", {
        builderClassId: values.builderClassId,
        builderId: id,
      });
      message.success(
        <>
          {" "}
          Builder successfully added to{" "}
          <b>
            {
              moqClasses.find((_moq) => _moq.id === values.builderClassId)
                ?.title
            }
          </b>{" "}
          Class{" "}
        </>
      );
      onCancel();
      callback();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      afterClose={() => {}}
      visible={isModalOpen}
      centered
      title="Add Credit Class"
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          rules={[{ required: true, message: "Pick a builder class" }]}
          name="builderClassId"
          label="Builder Class"
          style={{ textTransform: "capitalize" }}
        >
          <Select
            defaultValue={builderClassId ?? ""}
            style={{ textTransform: "capitalize" }}
          >
            {moqClasses.map(({ title, id }) => (
              <Option
                style={{ textTransform: "capitalize" }}
                key={id}
                value={id}
              >
                {title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            loading={isLoading}
            htmlType="submit"
            block
            type="primary"
            size="large"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
