import { useEffect, useState } from "react";
import { Form, Select, Button, message } from "antd";
import axios from "config/axios";
import AddPriority from "components/AddPriority";
import requestErrorHandler from "util/requestErrorHandler";

const { Option } = Select;

export interface CategoriesData {
  categoryId: string;
  priorityLevel: number;
}

export default function EditSupplierCategory({
  id,
  callback,
}: {
  id?: string;
  callback: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState<MaterialCategory[]>([]);
  const [values, setValues] = useState<CategoriesData[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();

  function handleSubmit() {
    if (isLoading) return;
    setIsLoading(true);
    axios
      .post("suppliercategory/enable/multiple", {
        supplierId: id,
        categories: [...values!],
      })
      .then(() => {
        message.success("Material(s) added to supplier inventory");
        callback();
        form.resetFields();
        setIsLoading(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    axios
      .get("category/all", { params: { page: 0, size: 100 } })
      .then(({ data }) => {
        setCategories(data.data.categories);
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }, []);
  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="categories"
          label="Select categories"
          rules={[{ required: true, message: "Select Categories" }]}
          style={{ width: "50%" }}
        >
          <Select
            onSelect={(e) => {
              let _values: CategoriesData[] = [...values];
              _values.push({
                categoryId: e,
                priorityLevel: 0,
              });
              setValues(_values);

              setSelectedId(e);
              setShowModal(true);
            }}
            onDeselect={(e) => {
              // TO REMOVE DESELECTED ITEMS FROM FINAL SUBMITTED VALUES

              const _values = values?.filter((x) => x.categoryId !== e);
              setValues(_values);
            }}
            mode="multiple"
            dropdownStyle={{ textTransform: "capitalize" }}
          >
            {categories.map((category) => (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button loading={isLoading} htmlType="submit" type="primary">
            Edit Material Category
          </Button>
        </Form.Item>
      </Form>
      <AddPriority
        handleModal={showModal}
        setHandleModal={setShowModal}
        selectedId={selectedId!}
        setValues={setValues}
        values={values!}
      />
    </>
  );
}
