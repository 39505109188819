
import CryptoJS from "crypto-js";

const REQUEST_SECRET= process.env.REACT_APP_REQUEST_SECRET_KEY
const API_URL= process.env.REACT_APP_BACKEND_URL 

export default function generateRequestSignature(
  method: string,
  path: string,
  body: string
) {
  const data = `${method}${body}`;
  const signature = CryptoJS.HmacSHA256(data, `${REQUEST_SECRET}`).toString(
    CryptoJS.enc.Hex
  );

  const payload = {
    signature,
  };

  return payload.signature;
}

export const signedConfig = (
  method: "POST" | "GET" | "PUT" | "DELETE",
  path: string,
  body: any
) => ({
  headers: {
    "X-Signature": generateRequestSignature(
      method,
      `${API_URL}${path}`,
      JSON.stringify({ ...body })
    ),
  },
});
