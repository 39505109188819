import { Button, Form, Input, message, Modal, Upload } from "antd";
import { useEffect, useState } from "react";
import QuillTextArea from "components/QuillTextArea";
import useBlog from "hooks/useBlog";
import { ArrowUpOutlined } from "@ant-design/icons";
import AxiosBlogConfig from "util/axiosBlogConfig";
import uploadToS3 from "util/uploadToS3";
import requestErrorHandler from "util/requestErrorHandler";

const EditBlogPost = ({ showModal, setShowModal, post, callback }: any) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getBlogPosts } = useBlog();

  useEffect(() => {
    getBlogPosts();
  }, [getBlogPosts]);

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    AxiosBlogConfig.put(`blogpost/editpost/${post.id}`, {
      ...values,
    })
      .then(() => {
        message.success("Post Edited successfully");
        setShowModal(false);
        callback();
        form.resetFields();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setIsLoading(false));
    // setIsLoading(true);
  };

  return (
    <Modal
      data-testid="edit-modal"
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={""}
    >
      <Form
        initialValues={post}
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
      >
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="content" label="Content" rules={[{ required: true }]}>
          {/* <DraftEditor /> */}
          <QuillTextArea />
        </Form.Item>
        <Form.Item
          name="image"
          label="Upload Business Logo"
          valuePropName="file"
          getValueFromEvent={(e) => {
            const file = e.fileList[0];
            if (file?.status === "done") {
              return file.response;
            }
          }}
        >
          <Upload
            customRequest={uploadToS3}
            listType="picture"
            multiple={false}
            maxCount={1}
            accept="image/*"
          >
            <Button icon={<ArrowUpOutlined />}>
              Click Here to upload an image
            </Button>
            <br />
            <small>
              Image should not be greater than <b>20MB</b>
            </small>
          </Upload>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          size="large"
          loading={isLoading}
        >
          Proceed
        </Button>
      </Form>
    </Modal>
  );
};

export default EditBlogPost;
