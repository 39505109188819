import { Modal, Form, Input, Button, InputNumber } from "antd";
import axios from "config/axios";
import { useEffect, useMemo, useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

export default function EditBuilderClassModal({
  current,
  setCurrent,
  getBuilderClasses,
}: {
  current: BuilderClass | null;
  setCurrent: (truck: BuilderClass | null) => void;
  getBuilderClasses: () => Promise<void>;
}) {
  function onCancel() {
    setCurrent(null);
  }
  const visible = useMemo(() => Boolean(current), [current]);
  return (
    <Modal
      afterClose={() => {
        getBuilderClasses();
      }}
      footer={null}
      open={visible}
      title={`Edit ${current?.className}`}
      onCancel={onCancel}
    >
      {visible && (
        <EditForm initialValues={current as BuilderClass} onCancel={onCancel} />
      )}
    </Modal>
  );
}

function EditForm({
  initialValues,
  onCancel,
}: {
  initialValues: BuilderClass;
  onCancel: () => void;
}) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  async function handleSubmit() {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const className = form.getFieldValue("className");
      const creditAmount = form.getFieldValue("creditAmount");
      await axios.post(`buildercredit/editclass/${initialValues.id}`, {
        creditAmount,
        className,
      });
      form.resetFields();
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }
  useEffect(() => {
    form.setFieldsValue({
      className: initialValues.className,
      creditAmount: initialValues.creditAmount,
    });
  }, [form, initialValues.className, initialValues.creditAmount]);

  return (
    <Form
      layout="vertical"
      form={form}
      preserve={false}
      onFinish={handleSubmit}
    >
      <Form.Item
        rules={[{ required: true, message: "Cannot be empty." }]}
        name="className"
        label="Account class name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Enter a number" }]}
        name="creditAmount"
        label="Eligible credit amount"
      >
        <InputNumber className="w-100" min={1} />
      </Form.Item>
      <Form.Item>
        <Button
          loading={isSubmitting}
          htmlType="submit"
          block
          type="primary"
          size="large"
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
