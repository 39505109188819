// import { message } from "antd";
import { message } from "antd";
import React, { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import uploadToS3 from "util/uploadToS3";
// import uploadToS3 from "util/uploadToS3";

// let quillObj: any;

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "code",
  "image",
];

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: OnChangeHandler;
};

const QuillTextArea: React.FC<Props> = ({ value, onChange, placeholder }) => {
  const quillRef = useRef<any>(null);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
        table: true,
      },
    }),
    []
  );

  // function boy() {}

  function imageHandler() {
    const editor: any = quillRef?.current?.getEditor();

    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      var file: any = input?.files?.[0];
      if (/^image\//.test(file.type)) {
        var formData = new FormData();
        formData.append("image", file);

        // var fileName = file.name;
        // const res = await uploadFiles(file, fileName, quillObj);

        const range = editor.getSelection(true);

        // Insert temporary loading placeholder image
        editor.insertEmbed(
          range.index,
          "image",
          `${window.location.origin}/images/loaders/placeholder.gif`
        );

        // Move cursor to right side of image (easier to continue typing)
        editor.setSelection(range.index + 1);

        // Remove placeholder image
        editor.deleteText(range.index, 1);

        const res = await uploadToS3({
          file,
          onSuccess: (a: any, b: any) => {},
          onError: (error: any) => {},
          action: "",
          method: "POST",
        });

        editor.insertEmbed(editor.getSelection(), "image", res);
      } else {
        message.error("Could not upload Image");
      }
    };
  }

  return (
    <>
      <ReactQuill
        data-testid="rich-text"
        ref={quillRef}
        theme="snow"
        value={value || ""}
        modules={modules}
        formats={formats}
        onChange={onChange}
        placeholder={placeholder}
      />
    </>
  );
};

export default QuillTextArea;
