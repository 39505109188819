import { navigate, Router } from "@reach/router";
import { Table, Typography, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DashboardShared from "components/DashboardShared/DashboardShared";
import TopTab from "components/TopTab/TopTab";
import DeviceWidth from "context/MobileResizeContext";
import { useContext, useEffect, useState } from "react";
import History from "./history";
import TransactionDetail from "./transaction-details";
import styles from "./transactions.module.css";
import axios from "config/axios";
import dayjs from "dayjs";
import useSearch from "hooks/useSearch";
import Unpaid from "./Unpaid";
import UnpaidDetails from "./unpaid-details";

const { Text } = Typography;
const Pending = () => {
  const isMobile = useContext(DeviceWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get("homeowner/not-delivered-transaction");
        setTransactions(res.data.transactions);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  let searchResult = transactions.filter((transaction) => {
    return (
      transaction.frontendId.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      transaction.builderName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      transaction.supplier.businessName
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.logisticsPartner.businessName
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.trackingStatus
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(transaction.createdAt)
        .format("MMM DD, YYYY")
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const webTableColumns = [
    {
      title: "INVOICE ID",
      dataIndex: "order",
      key: "order",
      render: (text: any) => <Text>{text?.invoiceNumber}</Text>,
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) =>
        dayjs(text).format("MMM DD, YYYY") as unknown as string,
    },
    {
      title: "NAME",
      dataIndex: "homeOwner",
      key: "homeOwner",
      ellipsis: true,
      render: (text: any) => (
        <Tooltip title={text?.firstName + " " + text?.lastName}>
          {text?.firstName + " " + text?.lastName}
        </Tooltip>
      ),
    },
    {
      title: "SUPPLIER",
      dataIndex: "supplier",
      ellipsis: true,
      render: (text: BuilderObject) => (
        <>
          <Tooltip title={text.businessName}>
            {text?.businessName ?? "--"}
          </Tooltip>
        </>
      ),
    },
    {
      title: "TRACKING STATUS",
      dataIndex: "trackingStatus",
      key: "trackingStatus",
      render: (text: string) => <Text>{text.replace(/_/g, " ")}</Text>,
    },
  ];

  const mobileTableData: any = [];
  transactions.map((t: any, i) => {
    return mobileTableData.push({
      id: t.id,
      key: i,
      "column-one": [t.id, "order date", t.createdAt],
      "column-two": [t.builderName, "payment ststua", t.paymentStatus],
      "column-three": [t.totalPrice, "delivery status", t.deliveryStatus],
    });
  });

  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        <Input
          className={styles.search}
          prefix={<SearchOutlined />}
          placeholder="search"
          onChange={(e) => handleSearch(e)}
          allowClear
          size="middle"
          style={{ width: isMobile ? "100%" : "auto", marginRight: "1rem" }}
        />
      </div>
      <div className={styles.table} style={{ overflowX: "auto" }}>
        {isSearching ? (
          <Table
            loading={isLoading}
            dataSource={searchResult}
            columns={webTableColumns}
            rowClassName={styles["table-row"]}
            onRow={(row) => {
              return {
                onClick: (event) => {
                  navigate(`${row.id}`);
                },
              };
            }}
            style={{
              minWidth: "600px",
            }}
            size="middle"
          />
        ) : (
          <Table
            loading={isLoading}
            dataSource={transactions}
            columns={webTableColumns}
            rowClassName={styles["table-row"]}
            onRow={(row) => {
              return {
                onClick: (event) => {
                  navigate(`${row.id}`);
                },
              };
            }}
            style={{
              minWidth: "600px",
            }}
            size="middle"
          />
        )}
      </div>
    </div>
  );
};

const MainView = (props: ReachPathProps) => {
  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "../", name: "Unpaid" },
          { to: "", name: "Pending" },
          { to: "../history", name: "History" },
        ]}
      />
      <Pending />
    </div>
  );
};

export default function HomeownerTransactions(props: ReachPathProps) {
  return (
    <DashboardShared title="Homeowner Transactions">
      <Router>
        <Unpaid path="/" />
        <MainView path="pending" />
        <TransactionDetail path=":id" />
        <UnpaidDetails path="unpaid/:id" />
        <History path="history" />
      </Router>
    </DashboardShared>
  );
}
