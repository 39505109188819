import { useCallback, useState } from "react";
import AxiosBlogConfig from "util/axiosBlogConfig";

export type BlogPost = {
  id: string;
  title: string;
  content: string;
  image: string;
  comments: string;
  likes: number;
  createdAt: string;
};

const useBlog = () => {
  const [blogPosts, setBlogPosts] = useState<Post[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const getBlogPosts = useCallback(() => {
    setIsFetching(true);
    AxiosBlogConfig.get(`/blogpost/allposts`)
      .then((res) => {
        setBlogPosts(res.data.blogPosts);
      })
      .catch((error) => {})
      .finally(() => setIsFetching(false));
  }, []);

  const createBlogPost = (values: any) =>
    AxiosBlogConfig.post(`/blogpost/create`, values);

  return {
    getBlogPosts,
    createBlogPost,
    blogPosts,
    isFetching,
  };
};

export default useBlog;
