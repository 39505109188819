import { Router } from "@reach/router";
import DashboardShared from "components/DashboardShared/DashboardShared";
import NotFound from "pages/404";
import { Index } from "./home";

export default function Brands(props: ReachPathProps) {
  return (
    <DashboardShared title="Brands">
      <Router>
        <Index path="/" />
        <NotFound path="*" />
      </Router>
    </DashboardShared>
  );
}
