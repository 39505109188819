import BackOffice from "pages/admin/blog-backoffice";
import Brands from "pages/admin/brands";
import Builders from "pages/admin/builders";
import DashBoard from "pages/admin/dashboard";
import HomeownerTransactions from "pages/admin/homeowner-transactions";
import Homeowners from "pages/admin/homeowners";
import Materials from "pages/admin/materials";
import Payments from "pages/admin/payments";
import Profile from "pages/admin/profile";
import Settings from "pages/admin/settings";
import Suppliers from "pages/admin/suppliers";
import Transactions from "pages/admin/transactions";
import Transporters from "pages/admin/transporters";
import UserManagement from "pages/admin/user-management";
import PendingSignups from "pages/admin/pending-signups";
import {
  Archive,
  Box,
  CreditCard,
  Grid,
  Home,
  List,
  Package,
  Truck,
  Users,
  Settings as Setti,
  User,
  Paperclip,
  Loader,
  Headphones,
} from "react-feather";
import { Sales } from "pages/admin/sales";
export type DashboardRoute = {
  path: `${string}/*`;
  title: string;
  component: (props: ReachPathProps) => JSX.Element;
  icon: React.ReactNode;
};

const _dashboardRoute: DashboardRoute[] = [
  {
    path: "dashboard/*",
    component: DashBoard,
    icon: <Grid />,
    title: "Dashboard",
  },
  {
    path: "transactions/*",
    component: Transactions,
    icon: <List />,
    title: "Transactions",
  },
  {
    path: "homeowner-transaction/*",
    component: HomeownerTransactions,
    icon: <List />,
    title: "HO Transactions",
  },
  {
    path: "homeowners/*",
    component: Homeowners,
    icon: <Home />,
    title: "Homeowners",
  },
  {
    path: "brands/*",
    component: Brands,
    icon: <Archive />,
    title: "Brands",
  },
  {
    path: "materials/*",
    component: Materials,
    icon: <Box />,
    title: "Materials",
  },
  {
    path: "builders/*",
    component: Builders,
    icon: <Home />,
    title: "Builders",
  },
  {
    path: "suppliers/*",
    component: Suppliers,
    icon: <Package />,
    title: "Suppliers",
  },
  {
    path: "transporters/*",
    component: Transporters,
    icon: <Truck />,
    title: "DSP",
  },
  {
    path: "payments/*",
    component: Payments,
    icon: <CreditCard />,
    title: "Payments",
  },
  {
    path: "user-management/*",
    component: UserManagement,
    icon: <Users />,
    title: "User Management",
  },
  {
    path: "settings/*",
    component: Settings,
    icon: <Setti />,
    title: "Settings",
  },
  { path: "profile/*", component: Profile, icon: <User />, title: "Profile" },
  {
    path: "blog-backoffice/*",
    component: BackOffice,
    icon: <Paperclip />,
    title: "Blog",
  },
  {
    path: "pending-signups/*",
    component: PendingSignups,
    icon: <Loader />,
    title: "Pending Signups",
  },
  {
    path: "contact-sales/*",
    component: Sales,
    icon: <Headphones />,
    title: "Contact Sales",
  },
];

export default _dashboardRoute;
