import axios from "axios";
import axiosConfig from "config/axios";
import { UploadRequestOption } from "rc-upload/lib/interface";

export default async function uploadToS3({
  file,
  onSuccess,
  onError,
  filename,
}: UploadRequestOption) {
  try {
    const formData: any = new FormData();

    // // Update the formData object
    formData.append("file", file!, filename);

    const { data } = await axiosConfig.post("upload", formData);
    onSuccess?.(
      data.location,
      //@ts-ignore
      file
    );
    return data.location;
  } catch (error) {
    //@ts-ignore
    onError?.(error);
  }
}
