import _dashboardRoute, { DashboardRoute } from "config/dashboardRoute";
import React from "react";
import useAuth from "./useAuth";

export default function useModules() {
  const { user } = useAuth();
  const dashboardRoute = _dashboardRoute.filter((v: DashboardRoute) => {
    if (
      user?.email === "webmaster@materialspro.ng" ||
      user?.selectedModules.includes(v.title.toLowerCase().replace(" ", "-"))
    ) {
      return v;
    }
  });

  const allowAction = (action: WriteAccess) => {
    return (
      user?.email === "webmaster@materialspro.ng" ||
      user?.selectedModules?.includes(action)
    );
  };

  return { dashboardRoute, allowAction };
}
