import axiosConfig from "config/axios";
import { useState } from "react";

const useMoqClass = () => {
  let builder: Builder | any = {};
  const [moqClass, setMoqClass] = useState<MoqClass>();
  const builderClassId = builder?.builderClassId;

  const getMoqclass = () => {
    if (!builderClassId || builderClassId === undefined) return;
    try {
      axiosConfig
        .get(`builderclass/onebuilder/${builderClassId}`)
        .then((res) => setMoqClass(res.data.builderClass))
        .catch((error) => {});
    } catch (error) {}
  };

  const mainVariant = (variantId: string, minQty: any) =>
    moqClass?.minimumQuantity.find((q) => q.variantId === variantId)?.minQty ??
    minQty;
  return { getMoqclass, moqClass, mainVariant };
};

export default useMoqClass;
