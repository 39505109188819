import AuthFormShared, {
  AuthButton,
} from "components/AuthFormShared/AuthFormShared";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import useAuth from "hooks/useAuth";
import { Redirect } from "@reach/router";
import requestErrorHandler from "util/requestErrorHandler";
import axiosConfig from "config/axios";

export default function LogIn(props: ReachPathProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { logIn, user } = useAuth();

  async function handleSubmit({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await axiosConfig.post("/admin/login/", { email, password });
      const { user, token } = data;
      logIn(user, token);
    } catch (error: any) {
      // form.setFields([{ name: "password", errors: [error] }])
      requestErrorHandler(error);
      setIsLoading(false);
    }
  }

  if (user) return <Redirect from="/" to="/admin/dashboard" noThrow />;

  return (
    <AuthFormShared title="Sign In As an Admin" {...props}>
      <Form
        className="w-100"
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="Email Address"
          rules={[{ required: true }, { type: "email" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
          style={{ margin: 0 }}
        >
          <Input.Password />
        </Form.Item>
        <div style={forgot_password}>
          <Button type="link">Forgot password?</Button>
        </div>
        <AuthButton isLoading={isLoading}>Sign In</AuthButton>
      </Form>
      {/* <p className="text-center">
        Don’t have an account? <Link to="/signup">Register</Link>
      </p> */}
      {/* <p className="text-center">
        <Link to="/login">Sign up as Supplier</Link> | <Link to="/login">Sign up as Logistic Partner</Link>
      </p> */}
    </AuthFormShared>
  );
}

const forgot_password = {
  marginBottom: "1rem",
  wiidth: "100%",
  display: "flex",
  justifyContent: "flex-end",
};
