import {
  message,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
  Row,
  Col,
} from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./styles.module.css";
import axiosConfig from "config/axios";
import Spinner from "assets/icons/Spinner";
import uploadToS3 from "util/uploadToS3";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;
interface modalProps {
  material: any;
  materialId: any;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  callback?: () => void;
  initialValues?: any;
}

const EditVariant = ({
  material,
  materialId,
  showModal,
  setShowModal,
  callback,
  initialValues,
}: modalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [truck, setTruck] = useState<TruckSetting | null>(null);
  useEffect(() => {
    if (material?.category?.truckId) {
      axiosConfig
        .get(`truck/${material?.category?.truckId}`)
        .then((res) => {
          if (Array.isArray(res.data.data)) {
            const tmp = res.data.data.find(
              (data: TruckSetting) => data.id === material.category.truckId
            );
            setTruck(tmp);
          } else setTruck(res.data.data);
        })
        .catch((err) => {});
    }
  }, [material?.category?.truckId]);

  const [quantities, setQuantities] = useState<any>(initialValues.availableQty);
  const removeQuantity = (val: number) => {
    setQuantities((prev: any) => prev.filter((item: any) => val !== item));
  };
  const handleSubmit = async (values: {}) => {
    setIsSubmitting(true);
    setFieldsValue({
      materialId: materialId,
    });

    if (quantities?.length < 1) {
      message.error({
        content: "Please enter at least one available quantity",
      });
      setIsSubmitting(false);

      return;
    }
    const payload = {
      ...values,
      availableQty: quantities,
    };

    axiosConfig.put(`variant/${initialValues.id}`, { ...payload }).then(
      () => {
        message.success({
          content: "Variant Updated",
        });
        callback?.();
        setShowModal(false);
        resetFields();
      },
      (error) => {
        message.error({
          content: "Error: could not update variant",
        });
      }
    );
  };
  const { resetFields, setFieldsValue } = form;

  return (
    <Modal
      className={styles.modal}
      title="Edit Variant"
      onCancel={() => setShowModal(false)}
      onOk={() => setShowModal(false)}
      open={showModal}
      footer={false}
    >
      <Form
        initialValues={initialValues}
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Variant description"
          name="description"
          rules={[
            { required: true, message: "Please enter variant description" },
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={[10, 10]}>
          <Col md={12} xs={24}>
            <Form.Item
              rules={[{ required: true, message: "Please Upload an image" }]}
              label="Upload material image"
              name="variantImage"
              valuePropName="file"
              getValueFromEvent={(e) => {
                const file = e.fileList[0];
                if (file?.status === "done") {
                  return file.response;
                }
              }}
            >
              <Upload
                customRequest={uploadToS3}
                listType="picture"
                multiple={false}
                maxCount={1}
                accept="image/*"
              >
                <Button icon={<UploadOutlined />}>Upload variant image</Button>
                <br />
                <small>
                  Image should not be greater than <b>20MB</b>
                </small>
              </Upload>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label="SKU"
              name="sku"
              rules={[{ required: false, message: "Please enter sku" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col md={12} xs={24}>
            <Form.Item
              label="Unit size"
              name="unitSize"
              rules={[{ required: true, message: "Please enter unit size" }]}
            >
              <InputNumber className="w-100" min={0} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label="Unit price"
              name="unitPrice"
              rules={[{ required: true, message: "Please enter unit price" }]}
            >
              <InputNumber className="w-100" placeholder="₦5000" min={0} />
            </Form.Item>
          </Col>
        </Row>

        {truck && (
          <Row gutter={[10, 10]}>
            <Col md={12} xs={24}>
              <Form.Item
                label={`Truck ${truck.truckType} Tonnage`}
                name="variantTonnage"
                rules={[
                  { required: true, message: "Please select truck tonnage" },
                ]}
              >
                <Select>
                  {truck.truckTonnage.map((number) => (
                    <Option key={number} value={number}>
                      {number.toLocaleString()} tonnes
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Truck capacity"
                name="maxAmount"
                rules={[
                  { required: true, message: "Please enter truck capacity" },
                ]}
              >
                <InputNumber className="w-100" placeholder="50" min={1} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[10, 10]}>
          <Col md={12} xs={24}>
            <Form.Item
              label="Active Schedule Days"
              name="daysBeforeScheduleExpire"
              rules={[
                {
                  required: false,
                  message: "Please enter Active schedule days",
                },
              ]}
            >
              <InputNumber className="w-100" />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label="BM Active Schedule Days"
              name="BMdaysBeforeScheduleExpire"
              rules={[
                {
                  required: false,
                  message: "Please enter BM Active schedule days",
                },
              ]}
            >
              <InputNumber className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col md={12} xs={24}>
            <Form.Item
              label="Minimum sales quantity"
              name="minSalesQty"
              rules={[
                {
                  required: true,
                  message: "Please enter min sales quantity",
                },
              ]}
            >
              <InputNumber
                className="w-100"
                placeholder="1000"
                min={0}
                onChange={(value) => {
                  setQuantities((prev: any) =>
                    prev.filter((qty: any) => qty >= +value!)
                  );
                  form.setFieldsValue({ minSalesQty: value });
                }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label="BM Minimum sales quantity"
              name="BMminSalesQty"
              rules={[
                {
                  required: true,
                  message: "Please enter BM min sales quantity",
                },
              ]}
            >
              <InputNumber
                className="w-100"
                placeholder="1000"
                min={0}
                onChange={(value) => {
                  // setQuantities((prev: any) =>
                  //   prev.filter((qty: any) => qty >= +value!)
                  // );
                  form.setFieldsValue({ BMminSalesQty: value });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          style={{ margin: "0", padding: 0, opacity: 0 }}
          name="materialId"
          rules={[{ required: true, message: "Please select Material ID" }]}
          initialValue={materialId}
          hidden
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Available quantites"
          name="availableQty"
          rules={[
            {
              required: false,
              message: "Please enter an available quantity",
            },
          ]}
        >
          <InputNumber
            className="w-100"
            value={form.getFieldValue("availableQty")}
            placeholder="1000"
            min={1}
            onChange={(value) => {
              form.setFieldsValue({
                availableQty: value,
              });
            }}
          />
          <Button
            size="small"
            htmlType="button"
            style={{
              marginTop: "10px",
              padding: 15,
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              if (form.getFieldValue("availableQty")) {
                if (!form.getFieldValue("minSalesQty")) {
                  message.error("Please enter a minimum sales quantity first");
                } else if (
                  +form.getFieldValue("minSalesQty") >
                  +form.getFieldValue("availableQty")
                ) {
                  message.error(
                    "Available quantities can not be less than minimum sales quantity"
                  );
                } else {
                  setQuantities([
                    ...quantities,
                    form.getFieldValue("availableQty"),
                  ]);
                  setFieldsValue({
                    availableQty: null,
                  });
                }
              }
            }}
          >
            ADD
          </Button>
        </Form.Item>
        {quantities?.length > 0 && (
          <div>
            Selected quantities:
            {quantities.map((qty: any, idx: number) => {
              // @ts-ignore
              return (
                <div key={idx} className={styles.quantityWrapper}>
                  <div>{qty}</div>
                  <div
                    className={styles.removeQuantity}
                    onClick={() => removeQuantity(qty)}
                  >
                    remove
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <Divider />
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          style={{ marginTop: "-300px", width: "100%" }}
        >
          {isSubmitting ? <Spinner /> : `SUBMIT`}
        </Button>
      </Form>
    </Modal>
  );
};

export default EditVariant;
